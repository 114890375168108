<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs d-lg-flex align-items-center justify-content-between">
		<a [routerLink]="['../']" class="btn btn-back p-0 gap-2 d-none d-lg-flex" role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
					<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
						stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Back to reports
		</a>
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item"><a [routerLink]="['../']">Reports</a></li>
				<li class="breadcrumb-item active" aria-current="page">
					Stale Product Report
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<div>
				<h1 class="title mb-0">Stale Product Report</h1>
				<p class="title-desc">
					List all products that have not been purchased for 6 months or more.
					Report can be exported to CSV.
				</p>
			</div>
			<div class="edit-btns w-sm-100">
				<button class="btn btn-secondary w-sm-100" type="button" (click)="downloadFile()" *ngIf="reportData.length > 0">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M13.75 7L10 3.25L6.25 7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 3.25V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
					Export CSV
				</button>
			</div>
		</div>

		<div class="manage-user-list">
			<div class="no-content-ship ship-h" *ngIf="reportData.length < 1 && isDataLoaded">
				<div class="no-content-icon">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M13.7503 2.50003H15.7528C16.1772 2.49252 16.5897 2.64113 16.9118 2.91764C17.234 3.19415 17.4434 3.57932 17.5003 4.00003V9.25003C17.4434 9.67073 17.234 10.0559 16.9118 10.3324C16.5897 10.6089 16.1772 10.7575 15.7528 10.75H13.7503M8.50027 12.25V15.25C8.50027 15.8468 8.73732 16.4191 9.15928 16.841C9.58123 17.263 10.1535 17.5 10.7503 17.5L13.7503 10.75V2.50003H5.29027C4.92852 2.49594 4.57748 2.62272 4.30184 2.85702C4.02619 3.09132 3.84451 3.41735 3.79027 3.77503L2.75527 10.525C2.72264 10.74 2.73714 10.9595 2.79776 11.1683C2.85839 11.3772 2.96369 11.5703 3.10637 11.7344C3.24906 11.8985 3.42571 12.0296 3.62409 12.1186C3.82247 12.2076 4.03784 12.2525 4.25527 12.25H8.50027Z"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
					</span>
				</div>
				<h4 class="no-content-title">No Data Reports</h4>
			</div>

			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table" *ngIf="isDataLoaded">
					<thead>
						<tr>
							<th>Product Name</th>
							<th>Brand</th>
							<th>Code</th>
							<th>Recent Sale</th>
							<th>Sold</th>
							<th>type</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of reportData">
							<td>
								<p>{{ item.name }}</p>
							</td>
							<td>
								<p>{{ item.brand_name }}</p>
							</td>
							<td>
								<p>{{ item.code }}</p>
							</td>
							<td>
								<p>{{ item.most_recent_sale }}</p>
							</td>
							<td>
								<p>{{ item.total_sold }}</p>
							</td>
							<td>
								<p>{{ item.type }}</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of reportData">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Product Name </span>
									<div class="user-name">
										<h5>
											{{ item.name }}
										</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Brand </span>
									<p>
										{{ item.brand_name }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Code </span>
									<p>
										{{ item.code }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Recent Sale </span>
									<p>
										{{ item.most_recent_sale }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Sold </span>
									<p>
										{{ item.total_sold }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Type </span>
									<p>
										{{ item.type }}
									</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
</div>