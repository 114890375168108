<section class="inhousecus">
	<div class="max-width-container">
		<div class="container">
			<h1 class="u-select-none">Product Customisation</h1>
			<p class="u-select-none">
				At Reali, we're dedicated to ensuring your brand leaves a lasting
				impact. We specialise in customising uniforms including hard hats, to
				perfectly match your brand identity. With our in-house decoration team
				we meticulously craft and refine your branding, guaranteeing accuracy
				and consistency.
			</p>
		</div>
	</div>
</section>

<section class="inhcusinner">
	<div class="max-width-container">
		<div class="container">
			<div class="inner sec-a">
				<div class="top-box">
					<ul>
						<li>
							<div class="img-box">
								<img src="../../../../assets/images/workPac.png" alt="" />
							</div>
						</li>
						<li class="d-none">
							<div class="img-box">
								<img src="../../../../assets/images/logo.svg" alt="" />
							</div>
						</li>
						<li class="d-none">
							<div class="img-box">
								<img src="../../../../assets/images/clients-2.svg" alt="" />
							</div>
						</li>
					</ul>
					<div class="rgbox">
						<div class="rigis">
							<img src="../../../../assets/images/housecus1.webp" alt="" />
						</div>
					</div>
				</div>
				<h3>Embroidery</h3>
			</div>
			<div class="inner sec-b">
				<div class="brdbox">
					<div class="inboximgs">
						<div class="brdicon">
							<img src="../../../../assets/images/jackicon1.svg" alt="" />
						</div>
						<div class="main-img">
							<img src="../../../../assets/images/grpmaimgz.png" alt="" />
						</div>

						<div class="bgdot"></div>
					</div>
					<div class="lftrht-box">
						<div class="lft-icon">
							<img src="../../../../assets/images/showicon1.svg" alt="" />
						</div>
						<div class="rht-icon">
							<img src="../../../../assets/images/shirticon1.svg" alt="" />
						</div>
					</div>
				</div>
				<h3>DTF Printing</h3>
			</div>
			<div class="inner sec-c">
				<div class="uvbox">
					<div class="top-box">
						<div class="lftimg">
							<img src="../../../../assets/images/housecus11.webp" alt="" />
						</div>
						<div class="rgtimg">
							<img src="../../../../assets/images/UV Printing.webp" alt="" />
						</div>
					</div>
					<div class="btmbxs">
						<img src="../../../../assets/images/btmbximg1.svg" alt="" />
					</div>
				</div>
				<h3>UV Printing</h3>
			</div>
			<div class="inner sec-d">
				<div class="img-box">
					<img src="../../../../assets/images/artworkimg1.svg" alt="" />
					<div class="uploadbtn pe-none">
						<label for="artWorkimg" class="form-label">Upload image</label>
						<input class="form-control" type="file" id="artWorkimg" />
					</div>
				</div>
				<h3>Artwork design</h3>
			</div>
		</div>
	</div>
</section>

<section class="inhcbottom">
	<div class="max-width-container">
		<div class="container">
			<h3>
				To further enhance our service efficiency and maintain stringent quality
				standards, all customisation processes are carried out in-house at any
				of our state-of-the-art facilities spread across Australia.
			</h3>
			<p>
				This streamlined approach not only expedites the delivery of your
				customised products but also allows us to closely monitor and maintain
				the quality of the final results. Whether it's garments, headwear, hard
				hats, bags, or promotional products, our customisation capabilities span
				across both soft and hard surface items. At Reali, we take pride in
				offering a comprehensive suite of customisation services that empower
				you to make a lasting impact with personalised and brand-aligned
				products.
			</p>
		</div>
	</div>
</section>
