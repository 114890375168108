import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { combineLatest, switchMap } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IShippingDetails, ShippingDetailsClass } from 'src/app/main/model/shippingDetail.model';
import { ShippingDetailService } from 'src/app/main/services/shippingDetail.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: []
})
export class EditAddressComponent implements OnInit {
  public address: Partial<IShippingDetails> = new ShippingDetailsClass();
  public editLink: string;
  public activeTab: number;
  /**
   * customerId that owns the address being modified
   */
  private customerId: number | null;

  private readonly subscriptionGroup = new SubscriptionGroup();
  public isAdmin: boolean = false;
  shippingDetailsId: number;

  constructor(
    private readonly session: SessionApi,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shippingDetailsService: ShippingDetailService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.loadExistingDetails();
    const currentUrl = this.activatedRoute.snapshot.url.join('/');
    if (currentUrl.includes('view')) {
      this.editLink = `/manage/customer/view/${this.customerId}`;
      this.activeTab = 3;
    } else {
      this.editLink = `/manage/customer/edit/${this.customerId}`;
      this.activeTab = 4;
    }
  }

  loadExistingDetails() {
    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        this.customerId = null;

        if (userData?.isAdmin) {
          this.isAdmin = true;
          if (has(params, "customerId")) {
            this.customerId = Number(params["customerId"]);
          }
        } else if (customerData) {
          this.customerId = customerData.id;
        }

        if (this.customerId && has(params, 'id')) {
          this.shippingDetailsId = Number(params["id"]);
          this.shippingDetailsService.read(this.shippingDetailsId).subscribe(shippingDetails => {
            this.address = shippingDetails;
          });
        }
      })
    );
  }

  saveAddress = () => {
    this.createOrUpdate().pipe(
      switchMap(() => this.session.$userData)
    ).subscribe({
      next: data => {
        if (data?.isAdmin) {
          this.router.navigate([this.editLink], {
            queryParams: { activeTab: this.activeTab }
          });
        } else {
          if (!this.address.id) {
            this.router.navigate(['/account/addresses']);
          }
        }
      },
      error: err => {

      }
    });
  }

  createOrUpdate = () => {
    if (!this.address.id) {
      return this.shippingDetailsService.create(this.customerId, this.address);
    }

    return this.shippingDetailsService.update(this.customerId, this.address.id, this.address);
  };

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }
}
