import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ICustomerUser } from '../model/customer.user.model';
import { createUrl } from '../api/api.util';
import { IBusinessUnit } from '../model/businessUnit.model';
import { INewUser } from '../model/user.model';
// Convenience Type
type T = ICustomerUser;

export type ICustomerUserOptionalUser = ICustomerUser & { user?: INewUser };
export type IBusinessUnitOptionalContact = IBusinessUnit & { contact?: ICustomerUserOptionalUser };
export type IGetBusinessUnitReport = IBusinessUnitOptionalContact[];

@Injectable()
export class ReportApi extends GenericApi<T> {
	public path = "report";

	constructor(
		public httpClient: HttpClient
	) {
		super(httpClient);
	}

	getUserAllocationReport = (params?: { customerId?: number | string }) => {
		const reportParams: Record<string, any> = {};
		if (!!params) {
			if (params.customerId) {
				reportParams.customerId = params.customerId;
			}
		};
		return this.httpClient.get<any>(
			createUrl('report', 'userAllocationReport'),
			{ params: params as HttpParams }
		);
	}

	/**
	 * @description Fetches a list of all users for the account. Returns exactly 1 row for each user
	 * @returns {Observable}
	 */
	getUserListReport = (params?: { customerId?: number | string }) => {
		const reportParams: Record<string, any> = {};
		if (!!params) {
			if (params.customerId) {
				reportParams.customerId = params.customerId;
			}
		};
		return this.httpClient.get<any>(
			createUrl('report', 'getUserListReport'),
			{ params: params as HttpParams }
		);
	}

	getOrderListReport = (params?: { status?: string | null, customerId?: number | string | null, approverId?: number, consumedAllocation?: number | null }) => {
		return this.httpClient.get<any>(
			createUrl('report', 'getOrderListReport'),
			{ params: (params || {}) as HttpParams }
		);
	}

	getBusinessUnitReport = (params?: { customerId?: number | string }) => {
		const reportParams: Record<string, any> = {};
		if (!!params) {
			if (params.customerId) {
				reportParams.customerId = params.customerId;
			}
		};
		return this.httpClient.get<IGetBusinessUnitReport>(
			createUrl(this.path, 'getBusinessUnitReport'),
			{ params: params as HttpParams }
		);
	}

	getSalesReport = (params?: { customerId?: number | string }) => {
		const reportParams: Record<string, any> = {};
		if (!!params) {
			if (params.customerId) {
				reportParams.customerId = params.customerId;
			}
		};
		return this.httpClient.get<any>(
			createUrl(this.path, 'getSalesReport'),
			{ params: params as HttpParams }
		);
	}

	getOverspendReport = (params?: { status?: string | null, customerId?: number | string | null | undefined, approverId?: number, consumedAllocation?: number | null }) => {
		return this.httpClient.get<any>(
			createUrl(this.path, 'getOverspendReport'),
			{ params: (params || {}) as HttpParams }
		);
	}

	readonly getStaleProductAdminReport = () => {
		return this.httpClient.get<any>(
			createUrl(this.path, 'getStaleProductAdminReport')
		);
	}

	readonly getAbnormalCartAdminReport = () => {
		return this.httpClient.get<any>(
			createUrl(this.path, 'getAbnormalCartAdminReport')
		);
	}

	readonly createCSV = (opts: { data: any, type: string }) => {
		return this.httpClient.post<any>(
			createUrl('report', 'postCreateCSV'),
			opts
		);
	}

}
