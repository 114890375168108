import { NgModule, inject } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./view/home/home.component";
import { AuthTemplateComponent } from "./template/auth-template/auth-template.component";
import { UnAuthGuard } from "./guard/unauth.guard";
import { AuthGuard } from "./guard/auth.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ResetComponent } from "./auth/reset/reset.component";
import { AppTemplateComponent } from "./template/app-template/app-template.component";
import { UsersComponent } from "./users/users.component";
import { UserEditComponent } from './users/customer-users/user-edit/user-edit.component';
import { AllocationLogsComponent } from "./users/customer-users/user-edit/user-allocation-edit/allocation-logs/allocation-logs.component";
import { ViewCustomerComponent } from "./customers/customer-action/tabs/customer-action-tab.component";
import { CustomerEditComponent } from "./customers/customer-action/customer-edit/customer-edit.component";
import { GroupTabComponent } from "./groups/group-tab/group-tab.component";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { UserInformationEditComponent } from "./users/customer-users/user-edit/user-information-edit/user-information-edit.component";
import { EditAddressComponent } from "./customers/customer-action/address/edit-address/edit-address.component";
import { BusinessEditComponent } from "./customers/customer-action/business-unit/business-edit/business-edit.component";
import { AdminEditComponent } from "./admin/edit/edit.component";
import { DocumentEditComponent } from "./customers/customer-action/document/document-edit/document-edit.component";
import { OverviewEditComponent } from "./customers/customer-action/overview-edit/overview-edit.component";
import { OrderTabComponent } from "./manage-orders/order-tab/order-tab.component";
import { OrderDetailComponent } from "./manage-orders/order-detail/order-detail.component";
import { OrderShipmentComponent } from "./manage-orders/order-shipment/order-shipment.component";
import { ProductListComponent } from "./manage-products/product-list/product-list.component";
import { ProductEditComponent } from "./manage-products/product-edit/product-edit.component";
import { GroupEditComponent } from "./groups/group-edit/group-edit.component";
import { DecorationListComponent } from "./manage-decorations/decoration-list/decoration-list.component";
import { DecorationEditComponent } from "./manage-decorations/decoration-edit/decoration-edit.component";
import { CollectionListComponent } from "./collections/list/list.component";
import { CollectionEditComponent } from "./collections/collection-edit/collection-edit.component";
import { SizechartListComponent } from "./manage-sizechart/sizechart-list/sizechart-list.component";
import { SizechartEditComponent } from "./manage-sizechart/sizechart-edit/sizechart-edit.component";
import { EnumComponent } from "./enum/enum.component";
import { UserRoleListComponent } from "./manage-roles/user-role-list/user-role-list.component";
import { UserRoleEditComponent } from "./manage-roles/user-role-edit/user-role-edit.component";
import { AdminRoleEditComponent } from "./manage-roles/admin-role-edit/admin-role-edit.component";
import { AdminRoleListComponent } from "./manage-roles/admin-role-list/admin-role-list.component";
import { EmailTestComponent } from "./email-test/email-test.component";
import { ManageSystemComponent } from "./manage-system/manage-system.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ReportsComponent } from "./reports/reports.component";
import { UserAllocationComponent } from "./reports/user-allocation/user-allocation.component";
import { UserlistReportComponent } from "./reports/userlist-report/userlist-report.component";
import { OrderlistReportComponent } from "./reports/orderlist-report/orderlist-report.component";
import { BusinessUnitReportComponent } from "./reports/business-unit-report/business-unit-report.component";
import { SalesReportComponent } from "./reports/sales-report/sales-report.component";
import { OverspendComponent } from "./reports/overspend/overspend.component";
import { RoleGuard } from "./guard/role.guard";
import { AdminReportsComponent } from "./admin-reports/admin-reports.component";
import { StaleProductReportComponent } from "./admin-reports/stale-product-report/stale-product-report.component";
import { AbnormalCartReportComponent } from "./admin-reports/abnormal-cart-report/abnormal-cart-report.component";
import { ProductViewComponent } from "./products/product-list/product-view.component";
import { SumsComponent } from "./view/solutions/sums/sums.component";
import { ProductCustomizationComponent } from "./view/solutions/product-customization/product-customization.component";
import { EurekaSafetyComponent } from "./view/solutions/eureka-safety/eureka-safety.component";
import { SupplyChainComponent } from "./view/solutions/supply-chain/supply-chain.component";
import { ContactUsComponent } from "./view/contactus/contact-us/contact-us.component";
import { AboutUsComponent } from "./view/about/about-us/about-us.component";
import { IndustriesComponent } from "./view/industries/industries/industries.component";
import { DocumentComponent } from "./customers/customer-action/document/document.component";
import { ListComponent } from "./customers/customer-action/business-unit/list/list.component";
import { AddressComponent } from "./customers/customer-action/address/address.component";
import { ManageEmployesComponent } from "./manage-employes/manage-employes.component";
import { FavoritesDetailComponent } from "./favorites/favorites-detail/favorites-detail.component";
import { CartListComponent } from "./manage-cart/cart-list/cart-list.component";
import { CategoriesComponent } from "./products/categories/categories.component";
import { ProductBreadcrumbsComponent } from "./products/product-breadcrumbs/product-breadcrumbs.component";
import { ProductDetailComponent } from "./products/product-detail/product-detail.component";
import { FavoritesListComponent } from "./favorites/favorites-list/favorites-list.component";
import { CartCheckoutComponent } from "./manage-cart/cart-checkout/cart-checkout.component";
import { CartCompleteComponent } from "./manage-cart/cart-complete/cart-complete.component";
import { ViewEmployeComponent } from "./manage-employes/view-employe/view-employe.component";
import { QuickOrderListComponent } from "./products/quick-order-list/quick-order-list.component";
import { UserRoleGuard } from "./guard/user_role.guard";
import { UserProfileEditComponent } from "./users/customer-users/user-edit/user-profile-edit/user-profile-edit.component";
import { CustomersComponent } from "./customers/customers-list.component";
import { BusinessDetailComponent } from "./customers/customer-action/business-unit/business-detail/business-detail.component";
import { ShippingDeliveryComponent } from "./view/shipping-delivery/shipping-delivery.component";
import { ReturnsExchangesComponent } from "./view/returns-exchanges/returns-exchanges.component";
import { UnauthProductPageComponent } from "./view/unauth-product-page/unauth-product-page.component";
import { BalanceDetailComponent } from "./users/balance-detail/balance-detail.component";
import { AcceptInviteComponent } from "./users/accept-invite/accept-invite.component";
import { DenyInviteComponent } from "./users/deny-invite/deny-invite.component";
import { CartApproveComponent } from "./manage-cart/cart-approve/cart-approve.component";
import { CartCancelComponent } from "./manage-cart/cart-cancel/cart-cancel.component";
import { routeSecurity } from "./routing-security";
import { environment } from "../../environments/environment";

const routes: Routes = [
  {
    path: '',
    component: MainTemplateComponent,
    children: [
      ...(environment.appName === 'GAWN'
        ? [
          {
            path: '',
            loadChildren: () =>
              import('../gawun-home/gawun-home.module').then(m => m.GawunHomeModule),
          },
        ]
        : [
          { path: 'manage/products/preview', component: ProductDetailComponent, data: { preview: true } },
          {
            path: '', canActivate: [UnAuthGuard], component: AuthTemplateComponent, children: [
              { path: 'login', component: ResetComponent, data: { animation: "Reset" } },
              { path: '', component: HomeComponent, data: { animation: "Home" } }
            ]
          },
          {
            path: 'manage', canActivate: [AuthGuard], component: AppTemplateComponent, children: [
              { path: 'dashboard', component: DashboardComponent, data: { animation: "Dashboard" } },
              {
                path: 'users', component: UsersComponent,
                canActivate: routeSecurity['/manage/users']
              },
              { path: 'orders', component: OrderTabComponent, canActivate: routeSecurity['/manage/orders'], data: { animation: "Orders" } },
              { path: 'orders/:id', component: OrderDetailComponent, canActivate: routeSecurity['/manage/orders'] },
              { path: 'orders/:id/shipments', component: OrderShipmentComponent, canActivate: routeSecurity['/manage/orders'] },
              { path: 'users/edit/:customerId/user', component: UserInformationEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'users/edit/:customerId/user/:id', component: UserEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'users/edit/:customerId/user/:id/logs/:userAllocationId', component: AllocationLogsComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              // { path: 'users/customers/view/:customerId', component: ViewCustomerComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/view/:customerId', component: ViewCustomerComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              // { path: 'users/customers/edit/:customerId', component: CustomerEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit/:customerId', component: CustomerEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit', component: OverviewEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              { path: 'customer/view/:customerId/address', component: EditAddressComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/view/:customerId/address/:id', component: EditAddressComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              // { path: 'users/customers/edit', component: OverviewEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit/:customerId/address/:id', component: EditAddressComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit/:customerId/address', component: EditAddressComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              { path: 'customer/edit/:customerId/business-unit/:id', component: BusinessEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit/:customerId/business-unit', component: BusinessEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              // customer path
              { path: 'customer', component: CustomersComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              { path: 'users/groups/view/:groupId', component: GroupTabComponent, canActivate: [RoleGuard], data: { permissions: ['manage_groups'] } },
              { path: 'users/admin/edit/:id', component: AdminEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_users'] } },
              { path: 'users/admin/edit', component: AdminEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_users'] } },

              { path: 'customer/edit/:customerId/document/:id', component: DocumentEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/edit/:customerId/document', component: DocumentEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              { path: 'customer/view/:customerId/document/:id', component: DocumentEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },
              { path: 'customer/view/:customerId/document', component: DocumentEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_customers'] } },

              { path: 'users/groups/view/:groupId', component: GroupTabComponent, canActivate: [RoleGuard], data: { permissions: ['manage_groups'] } },
              { path: 'products', component: ProductListComponent, canActivate: [RoleGuard], data: { permissions: ['manage_products'] } },
              { path: 'products/edit/:id', component: ProductEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_products'] } },
              { path: 'products/edit', component: ProductEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_products'] } },
              { path: 'users/groups/:groupId', component: GroupEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_groups'] } },
              { path: 'users/groups', component: GroupEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_groups'] } },
              { path: 'customer/groups/:customerId', component: GroupEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_groups'] } },

              { path: 'decorations', component: DecorationListComponent, canActivate: [RoleGuard], data: { permissions: ['manage_decorations'] } },
              { path: 'decorations/edit/:id', component: DecorationEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_decorations'] } },
              { path: 'decorations/edit', component: DecorationEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_decorations'] } },
              { path: 'collections', component: CollectionListComponent, canActivate: [RoleGuard], data: { permissions: ['manage_collections'] } },
              { path: 'collections/edit/:id', component: CollectionEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_collections'] } },
              { path: 'collections/edit', component: CollectionEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_collections'] } },
              { path: 'sizeChart', component: SizechartListComponent, canActivate: [RoleGuard], data: { permissions: ['manage_sizecharts'] } },
              { path: 'sizeChart/edit', component: SizechartEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_sizecharts'] } },
              { path: 'sizeChart/edit/:id', component: SizechartEditComponent, canActivate: [RoleGuard], data: { permissions: ['manage_sizecharts'] } },
              {
                path: 'categories', component: EnumComponent, canActivate: [RoleGuard],
                data: {
                  key: 'categories',
                  singular: 'category',
                  multiple: 'categories',
                  canCascade: true,
                  title: 'Manage Categories',
                  permissions: ['ADMIN', 'manage_categories']
                },
              },
              {
                path: 'brands', component: EnumComponent, canActivate: [RoleGuard],
                data: {
                  key: 'brands',
                  singular: 'brand',
                  multiple: 'brands',
                  canCascade: false,
                  title: 'Manage Brands',
                  permissions: ['ADMIN', 'manage_brands']
                },
              },
              {
                path: 'labels', component: EnumComponent, canActivate: [RoleGuard],
                data: {
                  key: 'labels',
                  singular: 'label',
                  multiple: 'labels',
                  canCascade: false,
                  title: 'Manage Labels',
                  permissions: ['ADMIN', 'manage_brands']
                },
              },
              { path: 'userRoles', component: UserRoleListComponent },
              { path: 'userRoles/edit', component: UserRoleEditComponent },
              { path: 'userRoles/edit/:id', component: UserRoleEditComponent },
              { path: 'adminRoles', component: AdminRoleListComponent },
              { path: 'adminRoles/edit', component: AdminRoleEditComponent },
              { path: 'adminRoles/edit/:id', component: AdminRoleEditComponent },
              { path: 'email-test', component: EmailTestComponent },
              { path: 'system', component: ManageSystemComponent },
              { path: 'reports', component: ReportsComponent, canActivate: [RoleGuard], data: { Permissions: ['ADMIN'] } },
              {
                path: 'reports/userAllocation', component: UserAllocationComponent
              },
              { path: 'reports/userList', component: UserlistReportComponent },
              { path: 'reports/orders', component: OrderlistReportComponent },
              { path: 'reports/businessUnit', component: BusinessUnitReportComponent },
              { path: 'reports/salesReport', component: SalesReportComponent },
              { path: 'reports/overspend', component: OverspendComponent },
              { path: 'admin-reports', component: AdminReportsComponent, canActivate: routeSecurity['/admin-reports'] },
              { path: 'admin-reports/staleProduct', component: StaleProductReportComponent, canActivate: routeSecurity['/admin-reports'] },
              { path: 'admin-reports/abnormalCart', component: AbnormalCartReportComponent, canActivate: routeSecurity['/admin-reports'] }
            ]
          },
          {
            path: '', component: AuthTemplateComponent, children: [
              { path: 'home', component: HomeComponent, data: { animation: "Home" } },
              { path: 'register/:RegisterSecret', component: RegisterComponent },
              { path: 'guest/accept', component: AcceptInviteComponent },
              { path: 'guest/deny', component: DenyInviteComponent },
              { path: 'products', component: CategoriesComponent, children: [{ path: '**', component: ProductViewComponent },] },
              { path: "product", component: ProductBreadcrumbsComponent, children: [{ path: '**', component: ProductDetailComponent },] },
              {
                path: 'cart', canActivate: [UserRoleGuard], data: {
                  permissions: ['cart_access']
                }, component: CartListComponent
              },
              { path: 'cart/complete/:orderId', component: CartCompleteComponent },
              {
                path: 'cart/pending_approval/:orderId', component: CartCompleteComponent, data: {
                  pendingApproval: true
                }
              },
              { path: 'cart/approve/:cartId', component: CartApproveComponent },
              { path: 'cart/cancel/:cartId', component: CartCancelComponent },
              {
                path: 'quick-order', canActivate: [UserRoleGuard], data: {
                  permissions: ['cart_access']
                }, component: QuickOrderListComponent
              },
              { path: 'safety-uniform-management-system', component: SumsComponent },
              { path: 'product-customisation', component: ProductCustomizationComponent },
              { path: 'eurekaSafety', component: EurekaSafetyComponent },
              { path: 'diverseSupplyChain', component: SupplyChainComponent },
              { path: 'industries', component: IndustriesComponent },
              { path: 'aboutUs', component: AboutUsComponent },
              { path: 'contactus', component: ContactUsComponent },
              { path: 'shipping-delivery', component: ShippingDeliveryComponent },
              { path: 'returns-exchanges', component: ReturnsExchangesComponent },
              { path: 'connect', component: UnauthProductPageComponent }
            ]
          },
          {
            path: 'cart/checkout',
            component: CartCheckoutComponent,
            canActivate: routeSecurity['/cart/checkout']
          },
          {
            path: 'account', canActivate: [AuthGuard], component: AppTemplateComponent, children: [
              {
                path: '', canActivate: [UserRoleGuard], data: {
                  permissions: [
                    'update_own_detail',
                    'cart_access',
                    'always_edit_addresses',
                    'user_allocation',
                    'edit_account_users',
                    'edit_business_units',
                    'order_approver'
                  ]
                }, component: OrderTabComponent
              },
              {
                path: 'details/:id/customer/:customerId', component: UserProfileEditComponent,
                canActivate: [UserRoleGuard], data: {
                  permissions: ['update_own_detail']
                }
              },
              { path: 'favorites', component: FavoritesListComponent },
              { path: 'favorites/detail/:id', component: FavoritesDetailComponent },
              { path: 'reports', component: ReportsComponent },
              { path: 'reports/userAllocation', canActivate: [UserRoleGuard], data: { permissions: ['report_allocation'] }, component: UserAllocationComponent },
              { path: 'reports/userList', canActivate: [UserRoleGuard], data: { permissions: ['report_user_list'] }, component: UserlistReportComponent },
              { path: 'reports/orders', canActivate: [UserRoleGuard], data: { permissions: ['report_order'] }, component: OrderlistReportComponent },
              { path: 'reports/businessUnit', canActivate: [UserRoleGuard], data: { permissions: ['report_business_unit'] }, component: BusinessUnitReportComponent },
              { path: 'reports/salesReport', component: SalesReportComponent },
              { path: 'reports/overspend', canActivate: [UserRoleGuard], data: { permissions: ['report_overspend'] }, component: OverspendComponent },
              { path: 'collections', component: CollectionListComponent },
              { path: 'documents', canActivate: [UserRoleGuard], data: { permissions: ['list_document_access'] }, component: DocumentComponent },
              { path: 'documents/edit/:id', canActivate: [UserRoleGuard], data: { permissions: ['edit_document_access'] }, component: DocumentEditComponent },
              { path: 'documents/edit', canActivate: [UserRoleGuard], data: { permissions: ['edit_document_access'] }, component: DocumentEditComponent },
              { path: 'business-unit', canActivate: [UserRoleGuard], data: { permissions: ['edit_business_units'] }, component: ListComponent },
              { path: 'business-unit/edit/:id', canActivate: [UserRoleGuard], data: { permissions: ['edit_business_units'] }, component: BusinessEditComponent },
              { path: 'business-unit/edit', canActivate: [UserRoleGuard], data: { permissions: ['edit_business_units'] }, component: BusinessEditComponent },
              { path: 'business-unit/detail/:id', canActivate: [UserRoleGuard], data: { permissions: ['edit_business_units'] }, component: BusinessDetailComponent },
              { path: 'addresses', canActivate: [UserRoleGuard], data: { permissions: ['always_edit_addresses'] }, component: AddressComponent },
              { path: 'addresses/edit', canActivate: [UserRoleGuard], data: { permissions: ['always_edit_addresses'] }, component: EditAddressComponent },
              { path: 'addresses/edit/:id', canActivate: [UserRoleGuard], data: { permissions: ['always_edit_addresses'] }, component: EditAddressComponent },
              { path: 'balance', component: BalanceDetailComponent },
              { path: 'users', canActivate: [UserRoleGuard], data: { permissions: ['list_account_users'] }, component: ManageEmployesComponent },
              { path: 'employe/:customerId', component: UserInformationEditComponent },
              { path: 'employe/view/:id', component: ViewEmployeComponent },
              { path: 'user/:id/customer/:customerId', canActivate: [UserRoleGuard], data: { permissions: ['list_account_users'] }, component: UserEditComponent },
              { path: 'orders', component: OrderTabComponent },
              { path: 'orders/:id', component: OrderDetailComponent },
              { path: 'orders/:id/shipments', component: OrderShipmentComponent }
            ]
          },
          { path: '**', redirectTo: 'home' }
        ]),
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
