<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/customer']">Customers</a>
			</li>
			<li class="breadcrumb-item" *ngIf="customer">
				<a [routerLink]="['/manage/customer']">{{ customer.CustomerName }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Edit Customer</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser" *ngIf="customer">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<h1 class="title mb-0">Edit Customer</h1>
			<div class="edit-btns w-sm-100">
				<button *ngIf="activeTabIdx == 1 || activeTabIdx == 2" class="btn btn-secondary w-sm-100" type="button"
					(click)="downloadImportTemplate()">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M6.25 8.5L10 12.25L13.75 8.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 12.25V3.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
					Export Template
				</button>
				<button *ngIf="activeTabIdx == 1 || activeTabIdx == 2" class="btn btn-secondary w-sm-100" type="button"
					(click)="importUser.click()">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M6.25 8.5L10 12.25L13.75 8.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 12.25V3.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
					Import {{ activeTabIdx == 1 ? "User" : "Guest" }}
				</button>
				<div class="d-none">
					<input type="file" #importUser (change)="fileUploadChange($event)" />
				</div>
				<a *ngIf="showAddButton()" class="btn btn-primary w-sm-100" role="button" (click)="addRoutes()">
					Add new {{ getAddButtonLabel() }}
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
				</a>
			</div>
		</div>

		<app-tab-set #tabSet [activeTabIdx]="activeTabIdx" [tabs]="getTabs()"
			(click)="onTabChange(tabSet.activeTabIdx)"></app-tab-set>
		<div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
				<app-customer-overview-edit *ngIf="activeTabIdx == 0"></app-customer-overview-edit>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
				<app-user-list *ngIf="activeTabIdx == 1" [tab]="'user'"></app-user-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }">
				<app-user-list *ngIf="activeTabIdx == 2" [tab]="'guest'" [isGuest]="true"></app-user-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 3 }">
				<app-collection-list *ngIf="activeTabIdx == 3" [customer]="customer"></app-collection-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 4 }">
				<app-customer-address *ngIf="activeTabIdx == 4"></app-customer-address>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 5 }">
				<app-group-list *ngIf="activeTabIdx == 5" [customer]="customer"></app-group-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 6 }">
				<app-business-list *ngIf="activeTabIdx == 6"></app-business-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 7 }">
				<app-customer-products *ngIf="activeTabIdx == 7"></app-customer-products>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 8 }">
				<app-customer-domain *ngIf="activeTabIdx == 8"></app-customer-domain>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 9 }">
				<app-customer-document *ngIf="activeTabIdx == 9"></app-customer-document>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 10 }">
				<app-customer-decoration *ngIf="activeTabIdx == 10"
					[customerDecoration]="customer.decorations"></app-customer-decoration>
			</div>
		</div>
	</div>
</div>