import { Component, ViewChild } from '@angular/core';
import { ICollection, ICollectionCustomer } from '../../model/collection.model';
import { NewProduct } from '../../model/ddb.model';
import { IQueryFilter } from '../../model/query.filter.class';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { CollectionService } from '../../services/collection.service';
import { has } from 'lodash';
import { ProductService } from '../../services/product.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { SelectOption } from 'src/app/types';
import { CustomSelectComponent } from '../../template/custom-select/custom-select.component';

export enum LifecycleStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

@Component({
  selector: 'app-collection-edit',
  templateUrl: './collection-edit.component.html',
  styleUrls: []
})
export class CollectionEditComponent {
  model: ICollection = {
    name: '',
    customers: [],
    products: [],
    accessMode: 1
  };

  customerSelectOptions: SelectOption[];

  /** Temporary storage for products before they are added to the model */
  selectedProducts: { name: string, id: string, text: string, code: string }[] = [];

  /** Temporarily store the last page of decoration data */
  productResult: { name: string, id: string, text: string, code: string }[] = [];
  activeClassId: number = 1;
  @ViewChild('customer') selectCustomer: CustomSelectComponent;

  selectedCustomer: SelectOption[] = [];

  public customerQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });

  @ViewChild('product')
  selectProduct: CustomSelectComponent;

  public productQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });

  customerId: string | null;
  isCustomer: boolean;
  private _currentCustomerPage: number = 1;
  private _currentProductPage: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private collectionService: CollectionService,
    private productService: ProductService,
    private notification: NotificationsService
  ) {
  }

  ngOnInit() {
    this.initProductSelect();
    this.route.params.subscribe(params => {
      if (has(params, 'id')) {
        this.model.id = params.id;

        this.loadModel(this.model.id);
      }
    });

    this.customerId = this.route.snapshot.queryParamMap.get('customerId');
    if (this.customerId) {
      this.isCustomer = true;
      this.model.customers.push({
        customerId: +this.customerId,
        name: ''
      });
    } else {
      this.handleCustomerListGet();
    }
  }

  dumpModel = () => {
    console.log(JSON.stringify(this.model, null, 2));
  };

  /**
   * @description Takes the temporarily stored selected customer ID and adds it to the product customers
   *              which will grant explicit access to the product to the customer in question, and if
   *              entered, the custom price for this product for the customer in question
   */
  addSelectedCustomer = () => {
    if (!this.selectedCustomer.length)
      return;

    if (!this.model.customers)
      this.model.customers = [];

    this.selectedCustomer.forEach(selectedCustomer => {
      if (!this.model.customers.find(productCustomer => productCustomer.customerId === +selectedCustomer.id)) {
        this.model.customers.push({
          customerId: (+selectedCustomer.id * 1),
          name: selectedCustomer.text
        });
      }
    });
    this.selectedCustomer = [];
    this.selectCustomer.customeSelect.clearModel();
    this.updateQuery('customer');
  };

  /**
   * @description Removes the selected productCustomer from the list productCustomers and re-indexes the
   *              productCustomer array to maintain the existing order
   *
   * @param modelCustomer
   */
  removeCustomer(modelCustomer: ICollectionCustomer) {
    for (let i = 0; i < this.model.customers.length; i++) {
      if (this.model.customers[i].customerId === modelCustomer.customerId) {
        this.model.customers.splice(i, 1);
        break;
      }
    }
  }

  /**
   * @description Validate and persist the product in the server, ignoring validating for Draft Products
   */
  saveModel() {
    if (this.isCustomer ? this.activeClassId == 2 : this.activeClassId == 3) {
      if (!this.model.name || !this.model.name.trim().length) {
        this.activeClassId = 1;
        this.notification.warn('Add Collection', 'Collection name is Required');
        return;
      }
      let handler = this.model.id ?
        this.collectionService.update(this.model.id, this.model) :
        this.collectionService.create(this.model);

      handler.subscribe(() => {
        if (!this.model.id) {
          this.getRoute();
        }
      },
        err => {
          let errMsg = "Unknown Error Saving Collection";
          if (err.status === 400 || err.status === 406) {
            console.log(err);
            if (has(err, 'error')) {
              if (has(err.error, 'errors'))
                errMsg = err.error.errors;
              if (has(err.error, 'message'))
                errMsg = err.error.message;
            }
          }
        });
    } else {
      this.activeClassId = this.activeClassId + 1;
    }
  }

  getRoute() {
    if (this.customerId) {
      this.router.navigate(['manage/customer/edit', this.customerId], { queryParams: { activeTab: 3 } });
    } else {
      this.router.navigate(['/manage/collections']);
    }
  }

  /**
   * @description Loads the existing product data from the database
   */
  loadModel(id: number | undefined) {
    if (id) {
      this.collectionService.get(id)
        .subscribe((collection: ICollection) => {
          this.model = collection;

          this.model.products = (this.model.products || []).filter(collectionProduct => collectionProduct.product !== null);
        });
    }
  }

  /**
   * @description Appends all the decorations in the decoration buffer (selectedProducts) to the model
   */
  addProduct() {
    this.selectedProducts.forEach(product => {
      let existing = this.model.products.find(existing => existing.productId === +product.id);
      if (existing) {
        return;
      }
      const newProduct = new NewProduct();
      newProduct.name = product.name;
      newProduct.code = product.code;
      if (product.id) {
        this.model.products.push({
          productId: +product.id,
          product: newProduct
        });
      }
    });
    this.selectedProducts = [];
    this.selectProduct.customeSelect.clearModel();
    this.updateQuery('product');
  }

  removeProduct(idString: string) {
    const productId = Number(idString);
    for (let i = 0; i < this.model.products.length; i++) {
      if (this.model.products[i].productId === productId) {
        this.model.products.splice(i, 1);
        break;
      }
    }
  }

  setActive(id: number) {
    this.activeClassId = id;
  }

  /**
 * @description Turns response from Customer Get into options for select2
 *
 * @param {QueryResult<HasId & NewCustomer>} result
 */
  handleCustomerListGet(isScroll: boolean = false) {
    if (isScroll) {
      this._currentCustomerPage = isScroll ? this._currentCustomerPage + 1 : 1;
      this.customerQuery.skip = this.customerQuery.limit * (this._currentCustomerPage - 1);
    }

    this.customerService.list(this.customerQuery)
      .subscribe((result) => {
        const newOptions = result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }));

        if (isScroll) {
          this.customerSelectOptions.push(...newOptions);
        } else {
          this.customerSelectOptions = newOptions;
        }
        this.selectCustomer.lifecycleStatusSubject.next(LifecycleStatus.LOADED);
      });
  }

  initProductSelect = (isScroll: boolean = false) => {
    if (isScroll) {
      this._currentProductPage = isScroll ? this._currentProductPage + 1 : 1;
      this.productQuery.skip = this.productQuery.limit * (this._currentProductPage - 1);
    }
    this.productService.list(this.productQuery)
      .subscribe(queryResult => {
        const newOptions = queryResult.rows.map(product => ({ id: product.id.toString(), text: product.code + ' - ' + product.name, name: product.name, code: product.code }));
        if (isScroll) {
          this.productResult.push(...newOptions);
        } else {
          this.productResult = newOptions;
        }
        this.selectProduct.lifecycleStatusSubject.next(LifecycleStatus.LOADED);
      });
  }

  loadProductData(data: { searchTerm?: string, isScroll?: boolean }) {
    if (data.searchTerm) {
      this.productQuery.filter['$or'] = {
        name: { $like: '%' + data.searchTerm + '%' },
        code: { $like: '%' + data.searchTerm + '%' },
        uri: { $like: '%' + data.searchTerm + '%' },
        description: { $like: '%' + data.searchTerm + '%' }
      }
    } else {
      delete this.productQuery.filter['$or'];
    }

    this.initProductSelect(data.isScroll);
  }

  public updateProduct(data: SelectOption[]) {
    if (!data.length) {
      return;
    }

    for (let product of data) {
      const findData = this.productResult.find(data => data.id == product.id);
      if (findData) {
        this.selectedProducts.push(findData);
      }
    }
  }

  loadCustomerData(data: { searchTerm?: string, isScroll?: boolean }) {
    if (data.searchTerm) {
      this.customerQuery.filter.name = { $like: '%' + data.searchTerm + '%' };
    } else {
      delete this.customerQuery.filter.name;
    }
    this.handleCustomerListGet(data.isScroll);
  }

  public updateCustomer(data: SelectOption[]) {
    if (!data.length) {
      return;
    }

    this.selectedCustomer = data;
  }

  updateQuery(type: string) {
    if (type == 'product') {
      delete this.productQuery.filter.name;
      this.initProductSelect();
    }
    if (type == 'customer') {
      delete this.customerQuery.filter.name;
      this.handleCustomerListGet();
    }
  }
}
