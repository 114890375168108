import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { CustomerProductApi } from '../api/customerproduct.api';
import { ProductCustomer } from '../model/ddb.model';

@Injectable()
export class CustomerProductService {
  constructor(
    private notifications: NotificationsService,
    private customerProductApi: CustomerProductApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<ProductCustomer & HasId<string>>> {
    return apiCallWrapper(
      this.customerProductApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching customer product"
      }
    )
  }

  public update(id: string, data: any): Observable<any> {
    return apiCallWrapper(
      this.customerProductApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Updating Customer Product"
      }
    )
  }

  public create(data: ProductCustomer) {
    return apiCallWrapper(
      this.customerProductApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create customer user"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.customerProductApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Customer Product"
      }
    )
  }

  public getAllProductCustomersByProductId(id: number | string): Observable<ProductCustomer[]> {
    return apiCallWrapper(
      this.customerProductApi.getAllProductCustomersByProductId(id),
      {
        notificationsService: this.notifications,
        action: "Fetching customer product"
      }
    )
  }
}
