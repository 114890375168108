import {
	AuthApi
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, filter, map, of, switchMap, tap, throwError } from 'rxjs';
import { IAuthPayload, IForgotPayload, IJWTPayload, IResetPayload } from '../model/auth.model';
import { logger } from '../util/Logger';
import { SessionApi } from '../api/session.api';
import { has } from '../util/object.util';
import { ConversionService } from './conversion.service';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';
import { SSOSession } from './sso.service';

interface auth {
	email: string,
	password: string
}

const className = "AuthService";
@Injectable()
export class AuthService {
	public $userPassAuthRequested = new BehaviorSubject<boolean>(false);
	private stickyMenuSubject = new BehaviorSubject<string>('');
	private accountMenuSubject = new BehaviorSubject<string>('');
	constructor(
		private authApi: AuthApi,
		private jwtService: JwtService,
		public notifications: NotificationsService,
		private readonly session: SessionApi,
		private readonly conversionService: ConversionService,
		public router: Router,
		private securityService: SecurityService
	) {
		this.moniterAuthType();
	}

	/**
 * @description Guarantees that every time the user data is cleared, the authentication type is also cleared
 */
	moniterAuthType() {
		this.session.$userData
			.pipe(
				filter(data => !data)
			)
			.subscribe({
				next: () => {
					this.$userPassAuthRequested.next(false);
				}
			});
	}


	/**
	 * @description Refreshes the authentication token by making a request to the server using the provided JWT strings.
	 * @param {string} jwtString - The access token JWT string.
	 * @param {string} jwtRefreshString - The refresh token JWT string.
	 * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
	 * @public
	 * @example
	 * ```
	 * const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Access token JWT string
	 * const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Refresh token JWT string
	 *
	 * auth.refreshToken(accessToken, refreshToken)
	 *   .subscribe(jwtPayload => {
	 *     console.log(jwtPayload); // The refreshed JWT payload
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */
	public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
		return apiCallWrapper(
			this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
			{
				notificationsService: this.notifications,
				action: "Obtaining new access keys"
			}
		)
	}

	/**
	 * @description Resets the user's password by making a request to the server using the provided reset payload.
	 * @param {IResetPayload} opts - The payload object for the password reset request.
	 * @returns {Observable<any>} - An observable that emits the response from the password reset request.
	 * @public
	 * @example
	 * ```
	 * const resetPayload = {
	 *   email: 'john@example.com',
	 *   newPassword: 'newPassword123'
	 * };
	 *
	 * auth.reset(resetPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the password reset request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */
	public reset = (opts: IResetPayload) => {
		return apiCallWrapper(
			this.authApi.reset(opts),
			{
				notificationsService: this.notifications,
				action: "Changing password"
			}
		);
	}

	/**
	 * @description Authenticates the user by making a request to the server using the provided authentication payload.
	 * @param {IAuthPayload} opts - The payload object for the authentication request.
	 * @returns {Observable<any>} - An observable that emits the response from the authentication request.
	 * @public
	 * @example
	 * ```
	 * const authPayload = {
	 *   username: 'john@example.com',
	 *   password: 'password123'
	 * };
	 *
	 * auth.authenticate(authPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the authentication request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */

	public authenticate = (opts: IAuthPayload) => {
		return this.authApi.authenticate(opts)
			.pipe(
				tap(() => this.$userPassAuthRequested.next(true))
			)
	}

	public sso = (opts: SSOSession) => {
		return this.authApi.sso(opts)
	}

	/**
	 * @description Sends a forgot password request by making a request to the server using the provided forgot payload.
	 * @param {IForgotPayload} opts - The payload object for the forgot password request.
	 * @returns {Observable<any>} - An observable that emits the response from the forgot password request.
	 * @public
	 * @example
	 * ```
	 * const forgotPayload = {
	 *   email: 'john@example.com'
	 * };
	 *
	 * auth.forgot(forgotPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the forgot password request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */

	public forgot = (opts: IForgotPayload) => {
		return apiCallWrapper(
			this.authApi.forgot(opts),
			{
				notificationsService: this.notifications,
				action: "Reset Password",
				failTitle: "Reset Failed",
				successTitle: "Reset Complete",
				successMessage: "Instructions were sent to your email address"
			}
		)
	}

	/**
	 * @description Checks if the user is authenticated by evaluating the current JWT payload.
	 * @returns {boolean} - A boolean value indicating whether the user is authenticated or not.
	 * @public
	 * @example
	 * ```
	 * const authenticated = auth.isAuthenticated();
	 * console.log(authenticated); // true or false
	 * ```
	 */
	public isAuthenticated = () => this.session.$userData.getValue() !== null;

	public logOut = () => {
		return this.authApi.logOut();
	}

	/**
 * Uses business rules to determine where the user should be sent in a "default" scenario
 */
	readonly navigateToDefaultUrl = async (): Promise<any> => {
		const signature = "UserLoginService.navigateToCustomerUrl: ";

		let route: string | undefined = '/manage/dashboard';

		const customer = this.session.$customerData.getValue();

		if( customer && customer.defaultUri ) {
			route = customer.defaultUri;
		}

		this.securityService.hasProductAccess().subscribe(hasProductAccess => {
			if (!hasProductAccess && route && route.match(/^\/products/i)) {
				route = '/manage/dashboard';
			}

			logger.debug(signature + `Navigating to ${route}`);

			return this.router.navigate([route]);
		});
	}

	switchUser(customerId: number, userId: number) {
		const signature = "AuthService" + '.switchUser: ';
		return this.performAuthenticationRequest(this.authApi.switch(customerId, userId))
			.pipe(
				tap(() => logger.silly(signature + `Switched into Customer[${customerId}] and User[${userId}]`)),
				map(() => true)
			);
	}

	/** Common authentication and post authentication behaviours */
	public performAuthenticationRequest(observable: Observable<IJWTPayload>) {
		return observable
			.pipe(
				tap(payload => this.persistJwtPayload(payload)),
			)
	}

	private persistJwtPayload(payload: IJWTPayload): Observable<void> {
		const signature = className + ".persistJwtPayload: ";
		if (!(this.jwtService.saveJWTData(payload))) {
			logger.info(signature + `Clearing JWT Data`);
			this.jwtService.removeJWTData();

			return throwError("Error Saving JWT Payload");
		}

		logger.silly(signature + `Persisted JWT Data`);
		return of();
	}

	/** Remove user emulation and switch back to self */
	unswitchUser() {
		return this.performAuthenticationRequest(
			this.authApi.unswitch());
	}

	public register = (firstName: string, lastName: string, email: string, secret: string) => {
		return apiCallWrapper(
			this.authApi.register(firstName, lastName, email, secret),
			{
				notificationsService: this.notifications,
				action: "Authenticating"
			}
		)
	}

	public accountCreate = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string }) => {
		return this.authApi.accountCreate(opts);
	}

	public contactUs = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string, generalDesc: string }) => {
		return this.authApi.contactUs(opts);
	}

	setStickyMenu(menuType: string): void {
		this.stickyMenuSubject.next(menuType);
	}

	getStickyMenu(): Observable<string> {
		return this.stickyMenuSubject.asObservable();
	}

	setAccountMenu(menuType: string): void {
		this.accountMenuSubject.next(menuType);
	}

	getAccountMenu(): Observable<string> {
		return this.accountMenuSubject.asObservable();
	}

	readonly acceptGuestInvite = (email: string, token: string) => {
		return this.authApi.acceptGuestInvite(email, token);
	};

	readonly denyGuestInvite = (email: string, token: string) => {
		return this.authApi.denyGuestInvite(email, token);
	};
}
