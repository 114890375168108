import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { CustomerService } from '../../services/customer.service';
import { Brand, EnumCreateParams, ICategory, Label, NewDecoration, NewLabel, NewProduct, NewProductVariation, ProductCustomer, ProductVariationCustomer } from '../../model/ddb.model';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { BrandService } from '../../services/brand.service';
import { LabelService } from '../../services/label.service';
import { ProductService } from '../../services/product.service';
import { SizeChartService } from '../../services/sizeChart.service';
import { SizeChartEntry } from '../../model/sizeChart.model';
import { NotificationsService } from 'angular2-notifications';
import { CustomerProductService } from '../../services/customerProduct.service';
import { FileUploadService } from '../../services/fileUpload.service';
import { Editor, Toolbar, toHTML } from 'ngx-editor';
import { UnleashedProduct, UnleashedProductListResult } from '../../model/productListResult.model';
import { UnleashedService } from '../../services/unleashed.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DecorationService } from '../../services/decoration.service';
import { legacyFeatures } from "../../util/string.util";
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { Promise as promise } from '../../util/object.util';
import { logger } from '../../util/Logger';

/**
 * Begin Code Refactor
 */
const prettyUrlCheckCodes = {
	NOT_CHECKED: {
		code: 0,
		message: "",
		buttonClass: ""
	},
	IS_CHECKING: {
		code: 1,
		message: "",
		buttonClass: ""
	},
	IS_OK: {
		code: 2,
		message: "",
		buttonClass: ""
	},
	IS_ERROR: {
		code: 3,
		message: "",
		buttonClass: ""
	},
};

@Component({
	selector: 'app-product-edit',
	templateUrl: './product-edit.component.html',
	styleUrls: []
})
export class ProductEditComponent implements OnInit, OnDestroy {
	private subscriptionGroup = new SubscriptionGroup();

	categories: ICategory[];
	labels: Label[] = [];
	customerSelectOptions: { id: string, text: string }[];
	brandSelectOptions: { id: string, text: string, selected?: boolean }[] = [];
	labelSelectOptions: { id: string, text: string, selected?: boolean }[] = [];
	product: NewProduct = new NewProduct();
	usePackSizes: boolean = false;
	subcategoryOptions: EnumCreateParams[][] = [];
	useTextSizeChart: boolean = false;
	sizeChartSelectOptions: { id: string | undefined, text: string }[];
	sizeChart: SizeChartEntry = new SizeChartEntry();
	customerProducts: ProductCustomer[] = [];
	labelSelectOptionsTemp: { id: string, text: string, selected?: boolean }[] = [];
	selectedLabels: Array<NewLabel> = [];
	prettyUrlCheck = prettyUrlCheckCodes.NOT_CHECKED;
	selectedCustomerOption: any[] = [];
	public selectedBrand: string;
	/**
	 * @description contains complete objects representing the selected inventory items. The list must contain
	 *              the whole object as an update to the option list after the selection has been made will remove
	 *              data which would be required for retrieval if the inventory items are added to the product.
	 */
	selectedInventoryItems: UnleashedProduct[] = [];
	/**
	 * @description Necessary as a lookup for selected inventory items
	 */
	lastUnleashedProductListResult: UnleashedProductListResult = {
		Items: [],
		Pagination: undefined
	};
	public allUnleashedResult: Array<UnleashedProduct>;
	page: number = 0;
	urlCheckState: number = 0;
	urlCheckMessage: String = '';
	decorationResult: QueryResult<NewDecoration> = new QueryResult();
	selectedDecorations: Array<NewDecoration> = [];
	selectDescriptions: string[] = [];
	inputDescription: any = '';

	selectFeatures: string[] = [];
	inputFeature: string = '';
	@ViewChild('inventory') selectInventory: NgSelectComponent;
	activeClassId: number = 1;

	@ViewChild('decoration') selectDecoration: NgSelectComponent;
	public decorationQuery: IQueryFilter = new IQueryFilter({
		sortBy: 'name',
		limit: 10
	});
	private searchTerms: Subject<string> = new Subject<string>();

	@ViewChild('customer') selectCustomer: NgSelectComponent;
	public customerQuery: IQueryFilter = new IQueryFilter({
		sortBy: 'name',
		limit: 10
	});
	private productTerms: Subject<string> = new Subject<string>();
	private unsubscribe$ = new Subject<void>();
	private searchTermsInit = new Subject<string>();
	public term: string;
	isLoading: boolean = false;
	noItemsFoundText: string;
	disableSelectChange: boolean;
	constructor(
		private categoryService: CategoryService,
		private customerService: CustomerService,
		private brandService: BrandService,
		private labelService: LabelService,
		private productService: ProductService,
		private route: ActivatedRoute,
		private sizeChartsService: SizeChartService,
		public notifications: NotificationsService,
		private productCustomerService: CustomerProductService,
		private fileUploadService: FileUploadService,
		private unleashedService: UnleashedService,
		private decorationService: DecorationService,
		public router: Router
	) {
		this.searchTermsInit.pipe(
			debounceTime(1000),
			distinctUntilChanged(),
		).subscribe(term => {
			this.term = term;
			this.initInventorySelect();
		}, err => {
			console.error(err);
		});
	}

	editor: Editor;
	editor1: Editor;

	toolbar: Toolbar = [
		['bold', 'italic'],
		['underline', 'strike'],
		['blockquote'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
		['link', 'image'],
		['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
	];

	ngOnInit() {
		this.editor = new Editor();
		this.editor1 = new Editor();
		// this.initInventorySelect();
		this.searchTermsInit.next('');
		this.handleCustomerListGet();

		this.handleBrandsGet();
		this.search();
		this.searchCustomer();
		this.handleLabelsGet();
		this.initDecorationSelect();

		this.route.params.pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(params => {
			if (has(params, 'id')) {

				this.product.id = params.id;

				if (this.product.id) {
					this.loadProduct(this.product.id)
						.then(() => {
							this.loadProductDraft(this.product.id);
						});
				}
			} else {
				this.loadProductDraft();
			}
		});

		if (this.product.id) {
			this.productCustomerService.getAllProductCustomersByProductId(this.product.id)
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(result => {
					if (result.length > 0) {
						this.customerProducts = result;
					}
				});
		}

		this.subscriptionGroup.add(this.categoryService.allCategories$.pipe(
			takeUntil(this.unsubscribe$)
		).subscribe({ next: res => this.categories = res!.rows }));
	}

	ngOnDestroy() {
		if (this.subscriptionGroup) {
			if (this.subscriptionGroup) {
				this.subscriptionGroup.unsubscribe();
			}
		}
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	search() {
		this.searchTerms.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			takeUntil(this.unsubscribe$)
		).subscribe(searchTerm => {
			this.decorationQuery.filter.name = { $like: '%' + searchTerm + '%' };
			this.initDecorationSelect();
		});
	}

	searchCustomer() {
		this.productTerms.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			takeUntil(this.unsubscribe$)
		).subscribe(searchTerm => {
			this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
			this.handleCustomerListGet();
		});
	}

	initDecorationSelect = (isScroll: boolean = false) => {
		if (isScroll) {
			this.decorationQuery.limit = this.decorationQuery.limit + 10;
		}
		this.decorationService.list(this.decorationQuery)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe((unleashedProductListResult: QueryResult<NewDecoration>) => {
				this.decorationResult = unleashedProductListResult;
			});
	}

	onDecorationSearch(searchTerm: { term: string; items: any[]; }) {
		this.searchTerms.next(searchTerm.term);
	}

	onCustomerSearch(searchTerm: { term: string; items: any[]; }) {
		this.productTerms.next(searchTerm.term);
	}

	customerChange(i: number, selectedValues: any[]) {
		this.selectedCustomerOption[i] = selectedValues.map(value => value.id);
	}

	/**
 * @description Done due to search results changing the available selection options rendering it impossible to find
 * the decorations details
 *
 *
 */
	selectDecorations(select) {
		if (this.disableSelectChange) {
			return;
		}
		setTimeout(() => {
			select.open();
		});

		const selectedValues = this.selectDecoration.selectedValues;
		const selectedIds: number[] = selectedValues.map(item => Number(item.id));
		this.selectedDecorations = this.selectedDecorations.filter(decoration => {
			return decoration.id !== undefined && selectedIds.includes(decoration.id);
		});
		selectedIds.forEach(id => {
			const existing = this.selectedDecorations.find(d => d.id === id);
			if (!existing) {
				const decoration = this.decorationResult.rows.find(d => d.id === id);
				if (decoration) {
					this.selectedDecorations.push(decoration);
				}
			}
		});
	}

	/**
 * @description Appends all the decorations in the decoration buffer (selectedDecorations) to the product model
 */
	addDecoration() {
		this.selectedDecorations.forEach(decoration => {
			let existing = this.product.decorations.find(existing => existing.id === decoration.id);

			if (existing)
				return;

			this.product.decorations.push(decoration);
		});
		this.disableSelectChange = true;

		this.selectDecoration.clearModel();
		this.selectDecoration.searchTerm = '';
		this.selectDecoration.close();

		setTimeout(() => {
			this.disableSelectChange = false;
		}, 0);
	}

	removeDecoration(idString: number | undefined) {
		const id = Number(idString);
		if (id && this.product.decorations) {
			this.product.decorations =
				this.product.decorations.filter(val => val.id !== id);
		}
	}

	/**
 * @description Appends all the decorations in the decoration buffer (selectedDecorations) to the product model
 */
	addDescription() {
		var oParser = new DOMParser();
		var oDOM = oParser.parseFromString(this.inputDescription, "text/html");
		var text = oDOM.body.innerText;
		if (text.trim().length > 0) {
			this.selectDescriptions.push(this.inputDescription);
			this.product.description = JSON.stringify(this.selectDescriptions);
			this.inputDescription = ''; // Clear the input field after adding to the array
		}
	}

	removeDescription(idString: number) {
		if (idString >= 0 && idString < this.selectDescriptions.length) {
			this.selectDescriptions.splice(idString, 1);
		}
		this.product.description = JSON.stringify(this.selectDescriptions);
	}

	descriptionIndexSwap(idString: number, change: string) {
		// Convert idString to a number
		let index1 = idString;
		let index2 = 0;

		// Determine the index to swap with based on the change direction
		if (change == 'up') {
			index2 = index1 - 1;
		} else {
			index2 = index1 + 1;
		}

		// Check if the indices are within bounds
		if (index1 < 0 || index1 >= this.selectDescriptions.length ||
			index2 < 0 || index2 >= this.selectDescriptions.length) {
			return;
		}

		// Swap the values at the given indices
		let temp = this.selectDescriptions[index1];
		this.selectDescriptions[index1] = this.selectDescriptions[index2];
		this.selectDescriptions[index2] = temp;
	}






	/**
 * @description UI helper method for retrieving the name of the category at the specified index
 */

	/**
* @features Appends all the decorations in the decoration buffer (selectedDecorations) to the product model
*/
	addFeatures() {
		var oParser = new DOMParser();
		var oDOM = oParser.parseFromString(this.inputFeature, "text/html");
		var text = oDOM.body.innerText;
		if (text.trim().length > 0) {
			this.selectFeatures.push(this.inputFeature);
			this.product.features = JSON.stringify(this.selectFeatures);
			this.inputFeature = ''; // Clear the input field after adding to the array
		}
	}

	removeFeatures(idString: number) {

		if (idString >= 0 && idString < this.selectFeatures.length) {
			// Remove the element at the specified index
			this.selectFeatures.splice(idString, 1);
		}
		this.product.features = JSON.stringify(this.selectFeatures);
	}

	featureIndexSwap(idString: number, change: string) {
		// Convert idString to a number
		let index1 = idString;
		let index2 = 0;

		// Determine the index to swap with based on the change direction
		if (change == 'up') {
			index2 = index1 - 1;
		} else {
			index2 = index1 + 1;
		}

		// Check if the indices are within bounds
		if (index1 < 0 || index1 >= this.selectFeatures.length ||
			index2 < 0 || index2 >= this.selectFeatures.length) {
			return;
		}

		// Swap the values at the given indices
		let temp = this.selectFeatures[index1];
		this.selectFeatures[index1] = this.selectFeatures[index2];
		this.selectFeatures[index2] = temp;
	}

	/**
 * @features UI helper method for retrieving the name of the category at the specified index
 */


	getCategoryName = (index: number): string | null => {
		if (!this.product.categories || this.product.categories.length <= index)
			return null;

		return this.product.categories[index].name;
	};

	/** Handle updates from the standard size chart selector */
	public handleStandardChart(event: string) {
		this.product.sizeChartId = +event == 0 ? null : +event;
	}

	/**
 * @description Loads any drafts of the target product from the database, if applicable
 *
 * @param id
 */
	loadProductDraft(id?: number) {
		this.productService.getProductDraft(id)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(draft => {
				if (!draft?.rows.length)
					return;

				const successNotification = this.notifications.success(
					'A Draft Was Found',
					'Click here to use.',
					{
						timeOut: 50000
					}
				);

				if (successNotification && successNotification.click) {
					successNotification.click.subscribe(() => {
						this.product = JSON.parse(draft.rows[0].productJSON);
						this.usePackSizes = !!this.product.packSizes.length;

						this.updateSubcategoryOptions();
						this.setUseTextSizeChart();
						this.loadSizeChartData();

						this.notifications.success('Draft was loaded', 'Draft Details Applied.');
					});
				} else {
					console.error('successNotification or successNotification.click is invalid.');
				}
			}, error => {
				console.error('Error loading product draft:', error);
			});
	}


	/**
 * @description handles a change in the brand value and retrieves enough details to identify the brand in the db on save
 *
 * @param brandId The browser will always return a string in select2
 */
	setBrand(brandId: string) {
		// When the user select a brand with the id of 0, it is a system brand
		if (!brandId || parseInt(brandId) === 0) {
			this.product.brand = null;
			return;
		}

		let brandData = this.brandSelectOptions.find(brand => brand.id === brandId);
		if (brandData) {
			let { id, text } = brandData;
			this.product.brand = { id: parseInt(id), name: text };
		}
	}

	/**
 * @description Done due to search results changing the available selection options rendering it impossible to find
 * the labels details
 *
 *
 */
	selectLabels(items: any | any[]) {
		let selectedIds: string[] = [];
		selectedIds.push(items);
		for (let i = this.selectedLabels.length - 1; i >= 0; i--) {
			if (!selectedIds.find(text => text === this.selectedLabels[i].text))
				this.selectedLabels.splice(i, 1);
		}

		selectedIds.forEach(text => {
			let existing = this.selectedLabels.find(d => d.text === text);
			if (!existing) {
				this.selectedLabels.push({ text: text });
			}
		});
	}

	/**
 * @description Removes a productImage from the product
 */
	deleteImage(colour: string, event) {
		for (let i = 0; i < this.product.images.length; i++) {
			if (this.product.images[i].name === colour) {
				const image = this.product.images[i];
				this.product.images.splice(i, 1);

				const isDefault = image.url === this.product.imageUrl;
				// If the removed image was the default look for an alternative
				if (isDefault) {
					const anotherDefaultExists = !!this.product.images.find(candidate => candidate.url === this.product.imageUrl);
					if (!anotherDefaultExists) {
						const replacementDefault = this.product.images.find(candidate => candidate.url && candidate.url.length > 0);
						this.product.imageUrl = replacementDefault ? replacementDefault.url : '';
					}
				}
				break;
			}
		}
		event.preventDefault();
		event.stopPropagation();
	}

	handleCustomerListGet = (isScroll: boolean = false) => {
		if (isScroll) {
			this.customerQuery.limit = this.customerQuery.limit + 10;
		}
		this.customerService.list(this.customerQuery)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe((result) => {
				this.customerSelectOptions = [
					...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name + ' (' + customer.code + ')' }))
				];
			});
	}

	/**
 * @description Handles the response from a brand list get
 */
	handleBrandsGet = () => {
		this.brandService.list(new IQueryFilter({
			limit: 1000,
			sortBy: 'name'
		}))
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe((brands) => {
				const sortedBrands = brands.sort((a, b) => a.name.localeCompare(b.name));
				this.brandSelectOptions = [
					...sortedBrands.map(brand => {
						return (this.product && this.product.brand && this.product.brand.id === brand.id) ?
							{ id: brand.id.toString(), text: brand.name, selected: true } :
							{ id: brand.id.toString(), text: brand.name };
					})
				];
			});
	}

	/**
 * @description Handles the response from a label list get
 */
	handleLabelsGet = () => {
		this.labelService.list(new IQueryFilter({
			limit: 10000,
			sortBy: 'name'
		}))
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe((items) => {
				this.labelSelectOptions = [
					...items.map(label => {
						return (this.product && this.product.brand && this.product.labels.id === label.id) ?
							{ id: label.id.toString(), text: label.name, selected: true } :
							{ id: label.id.toString(), text: label.name };
					})
				];
			});
	};

	/**
 * @description Get a list of categories which have the the parentCategoryId or a null parentCategoryId
 * @param parentCategoryId
 *
 * @returns EnumCreateParams[] A list of categories
 */
	filterCategories = (parentCategoryId?: string): EnumCreateParams[] => {
		if (!this.categories) return [];
		return this.categories.filter(category => parentCategoryId ? category.parentId === parentCategoryId : category.parentId === null);
	};

	/**
 * @description Populates the subcategory options object for UI output
 */
	updateSubcategoryOptions = () => {
		if (!this.product.categories || this.product.categories.length === 0)
			this.subcategoryOptions = [];
		else {
			let categoryOptions = this.product.categories.map(category => this.filterCategories(category.id));

			if (categoryOptions[categoryOptions.length - 1].length === 0)
				categoryOptions.pop();

			this.subcategoryOptions = categoryOptions;
		}
	};

	public newPackSize() {
		if (!this.product.packSizes) {
			this.product.packSizes = [];
		}

		this.product.packSizes.push({
			name: '',
			itemCount: 0
		});
	}

	/**
 * @description Get a list of unique colours in addition to the default (front/back) for product images
 *
 * @returns string[]
 */
	getImageOptions = () => [
		'Front',
		'Back',
		...(this.product.variations || []).map(variation => (variation.colour || '')),
		// When a inventory item is removed but the image definition remains, display it so that the administrator can deal with it
		...(this.product.images || []).map(image => (image.name || ''))
	].filter((val, idx, self) => val.length && self.indexOf(val) === idx);

	/** Helper method determines if the size chart option to show is the image or the text graphs */
	setUseTextSizeChart = (): void => {
		this.useTextSizeChart = false;

		if (this.product && this.product.sizeChartId)
			this.useTextSizeChart = true;
	};

	/**
 * @description Gets the url of an image for any given colour
 *
 * @param colour
 */
	getImageUrl(colour: string): string | null {
		let existingProductImage = (this.product.images || []).find(image => image.name === colour);

		if (existingProductImage)
			return existingProductImage.url;

		return null;
	}

	/**
 * @description Populates the subcategory options object for UI output
 */
	updateLabelSelectOptions = () => {
		if (!this.product.labels || this.product.labels.length === 0)
			this.labelSelectOptions = this.labelSelectOptionsTemp;
		else {
			let labelOptions = this.product.labels;
			if (labelOptions[labelOptions.length - 1].length === 0)
				labelOptions.pop();
			labelOptions = labelOptions.map(label => {
				let index: number | null = 0;
				let found = this.labelSelectOptionsTemp.some(function (el, i) {
					if (el.id === label.ProductLabel.labelId.toString()) {
						index = i;
						return true;
					}
				});
				if (found) {
					this.labelSelectOptionsTemp[index].selected = true;
					this.labelSelectOptionsTemp[index].id = this.labelSelectOptionsTemp[index].id.toString();
					// this.selectedLabels.push(<any>this.labelSelectOptionsTemp[index].text);
					this.selectedLabels.push({ text: this.labelSelectOptionsTemp[index].text });
				}
				return { id: label.ProductLabel.labelId, text: label.name, selected: true };
			}
			);

			this.labelSelectOptions = [];
			this.labelSelectOptions = this.labelSelectOptionsTemp;
		}
	};


	/**
 * @description Loads the existing product data from the database
 */
	loadProduct(id: number) {
		return new Promise((resolve, reject) => {
			this.productService.getProductById(id, true)
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe((product: NewProduct) => {

					this.product = product;

					this.usePackSizes = !!this.product.packSizes.length;
					if (this.product.brand) {
						this.selectedBrand = this.product.brand.id.toString();
					}
					this.updateSubcategoryOptions();
					this.setUseTextSizeChart();
					this.loadSizeChartData();
					if (this.product.description) {
						try {
							this.selectDescriptions = JSON.parse(this.product.description);
						} catch (e) {
							this.selectDescriptions = [this.product.description];
						}
					} else {
						this.selectDescriptions = [];
					}

					if (this.product.features) {
						this.selectFeatures = legacyFeatures(this.product.features);
					} else {
						this.selectFeatures = [];
					}
					resolve(null);
				});
		});
	}

	/**
 * @description Sets the default display image to be equal to any given productImage
 *
 * @param colour
 */
	setDefaultImage(colour: string) {
		const signature = 'ProductEdit' + ".setDefaultImage: ";
		const oldDefaultImageUrl = this.product.imageUrl;
		const newDefaultImageUrl = this.getImageUrl(colour);
		if (oldDefaultImageUrl !== newDefaultImageUrl) {
			logger.silly(signature + "Default image url Changed[" + newDefaultImageUrl + "]");
			this.product.imageUrl = newDefaultImageUrl;
		} else {
			logger.silly(signature + "Default image url did not Change[" + oldDefaultImageUrl + "]");
		}
	}

	previewProduct() {
		this.appendSizeChart();
		localStorage.setItem('PreviewProduct', JSON.stringify(this.product));
		let currentAbsoluteUrl = window.location.href;
		let currentRelativeUrl = this.router.url;
		let index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
		let baseUrl = currentAbsoluteUrl.substring(0, index);
		let previewUrl = this.router.createUrlTree(['/manage/products/preview']);
		window.open(baseUrl + previewUrl, '_blank');
	}

	/**
 * Sync custom size chart rows against the sizes of the variations when a custom size chart is being shown
 */
	syncSizeChartRows = () => {
		// Remove any size chart rows that are no longer applicable
		this.sizeChart.rows.forEach(row => {
			if (!this.product.variations.find(variation => row.name === variation.size)) {
				this.sizeChart.removeRow(row);
			}
		});

		// Add any new rows (This is protected against duplicates)
		this.product.variations
			.map(variation => {
				if (!variation.size)
					variation.size = "";

				return variation.size.toString();
			})
			.forEach(size => this.sizeChart.addRowByName(size));
	};

	/**
 * Loads a list of all size charts for display within the size chart selector
 *
 * This also prepares the sizeChart property if required as the product or draft should now be loaded
 */
	loadSizeChartData = () => {
		this.sizeChartsService.list(new IQueryFilter({
			limit: 10,
			sortBy: 'name'
		}))
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				this.handleSizeChartListGet,
				error => {
					console.error('Error loading size chart list', error);
				}
			);

		this.syncSizeChartRows();

		if (this.product.sizeChartId) {
			this.sizeChartsService.getById(this.product.sizeChartId)
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(
					sizeChart => this.sizeChart = sizeChart,
					err => {
						console.error('Error loading chart size', err);
					}
				);
		}
	};

	/**
 * @description Sets the product category at the specified index to the specified value, and trims the categories
 *              array to a length equal to level+1. This guarantees that each object in the product categories
 *              have a parent/child relationship.
 *
 * @param category
 * @param level
 */
	setCategory = (categoryId: string, index: number) => {
		if (this.product.categories.length > 0)
			this.product.categories.splice(index);

		let categoryData = this.categories.find(data => data.id == categoryId);
		if (categoryData) {
			this.product.categories.push(categoryData);
		}

		this.updateSubcategoryOptions();
	};

	/**
 * @description Turns response from SizeChart Get into options for select2
 *
 * @param {QueryResult<HasId & NewCustomer>} result
 */
	handleSizeChartListGet = (result: QueryResult<SizeChartEntry>) => {
		this.sizeChartSelectOptions = [
			{ id: "", text: "No Standard Size Chart" },
			...result.rows.map(chart => ({
				id: chart.id?.toString(),
				text: chart.name,
				selected: this.product.sizeChartId === chart.id
			}))
		];
	}

	/**
 * @description Persists an image to s3 via file upload then attaches it to the product
 *
 * @param event the HTML event
 * @param colour
 */
	persistS3Image(event: Event, colour: string) {
		let fileInput = event.srcElement;
		// @ts-ignore
		let theFile = fileInput.files[0];
		if (theFile) {
			this.fileUploadService.uploadProductImage(theFile, (err, data) => {
				let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
				let cfDistribution = 'https://static.reali.supply/';
				let url = data.Location.replace(s3Prefix, cfDistribution);

				let existingProductImage = this.product.images.find(image => image.name === colour);
				if (existingProductImage)
					existingProductImage.url = url;
				else
					this.product.images.push({
						name: colour,
						url: url
					});
			});
		}
	}

	public removePackSize(idx: number) {
		this.product.packSizes.splice(idx, 1);
	}

	/**
 * @description Resets the url checks, typically triggered by a change that would impact the front end url
 */
	public resetUrlCheckState = () => this.prettyUrlCheck = prettyUrlCheckCodes.NOT_CHECKED

	/** Helper function determines if there are sizes configured */
	showSizeChart = (): boolean => !!this.product.variations.find(variation =>
		variation.size
		&& (
			((typeof variation.size === 'string') && variation.size && !!variation.size.length)
			|| (typeof variation.size === "number")
		)
	);

	/**
 * Persist a size chart file against the product
 *
 * @param event
 */
	persistSizeChart(event: any) {
		let fileInput = event.srcElement;
		let theFile = fileInput.files[0];

		let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
		let cfDistribution = 'https://static.reali.supply/';
		if (theFile) {
			this.fileUploadService.uploadSizeChart(theFile, (err, data) => {
				const url = data.Location.replace(s3Prefix, cfDistribution);
				this.product.sizeChartUrl = url;
			});
		}
	}

	/**
 * Persist a data sheet file against the product
 *
 * @param event
 */
	persistDataSheetChart(event: any) {
		let fileInput = event.srcElement;
		let theFile = fileInput.files[0];

		if (theFile) {
			let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
			let cfDistribution = 'https://static.reali.supply/';

			this.fileUploadService.uploadDataSheet(theFile, (err, data) => {
				const url = data.Location.replace(s3Prefix, cfDistribution);
				this.product.dataSheetUrl = url;
			});
		}
	}

	/** Clear the data sheet file on the product details */
	removeDataSheet() {
		this.product.dataSheetUrl = null;
	}

	/**
 * @description sets the selectedInventoryItems to an array of items with IDs equal to the array of strings passed
 *              to this function each time the select2 input changes
 *
 * @param items
 */
	selectInventoryItems(select) {
		if (this.disableSelectChange) {
			return;
		}
		setTimeout(() => {
			select.open();
		});

		for (let i = (this.selectedInventoryItems.length - 1); i >= 0; i--) {
			if (!this.selectInventory.selectedValues.find(item => item === this.selectedInventoryItems[i])) {
				this.selectedInventoryItems.splice(i, 1);
			}
		}

		for (let i = 0; i < this.selectInventory.selectedValues.length; i++) {
			if (!this.selectedInventoryItems.find(selectedInventoryItem => this.selectInventory.selectedValues[i] == selectedInventoryItem.Guid)) {
				let selectedInventoryItem = this.lastUnleashedProductListResult.Items.find(unleashedProduct => unleashedProduct.Guid == this.selectInventory.selectedValues[i]);

				if (selectedInventoryItem)
					this.selectedInventoryItems.push(selectedInventoryItem);
				else
					console.error(`Error Finding Inventory Item[${this.selectInventory.selectedValues[i]}] in lastUnleashedProductListResult`);
			}
		}
	}

	initInventorySelect = () => {
		this.noItemsFoundText = 'Fetching...'
		this.isLoading = true;
		let $promise = new promise();
		let args: { criteria: string | null | undefined, page: number } = { criteria: null, page: ++this.page };

		if (this.term) {
			args.criteria = this.term;
		} else {
			delete args.criteria;
		}
		this.unleashedService.getUnleashedProducts(args)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(unleashedProductListResult => {
				this.isLoading = false;
				if (unleashedProductListResult) {
					if (!args.criteria) {
						unleashedProductListResult.Items.forEach((inventory: UnleashedProduct) => {
							this.lastUnleashedProductListResult.Items = [...this.lastUnleashedProductListResult.Items, inventory];
						});
					} else {
						this.lastUnleashedProductListResult.Items = [...unleashedProductListResult.Items];
						delete args.criteria;
					}
				}
				if (unleashedProductListResult.Items.length == 0) {
					this.noItemsFoundText = 'No Items Found';
				}
				$promise.success(unleashedProductListResult);
			},
				err => {
					this.noItemsFoundText = 'No Items Found';
					this.isLoading = false;
					$promise.failure(err);
				})
	}

	onSearch(event: { term: string }) {
		this.searchTermsInit.next(event.term);
	}

	/**
 * @description Transforms the selectedInventoryItems (UnleashedItem[]) array into the correct format as a variation
 *              of any given product. A variation contains each version of any given product such as colour and size
 *              and represents a single physical inventory item.
 */
	addSelectedInventoryItems() {
		this.product.variations.push(
			...this.selectedInventoryItems
				.map(selectedInventoryItem => {
					let result: NewProductVariation = {
						guid: selectedInventoryItem.Guid,
						colour: "",
						name: selectedInventoryItem.ProductDescription,
						productCode: selectedInventoryItem.ProductCode,
						size: "",
						accessMode: 0,
						// This value will be set when the product is saved
						displayOrder: 0,
						customers: []
					};

					// Attempt to polyfill the prices from the selectedInventoryItems before we discard them
					// Unleashed has a total of 10 "SellPriceTierN"s, starting from 1

					if (selectedInventoryItem.DefaultSellPrice !== null)
						this.product.basePrice = Number(selectedInventoryItem.DefaultSellPrice);

					// Attempt to polyfill the colour and size
					if (result.productCode) {
						let sizeMatches = result.productCode.match(/([^-]+$)/);
						let colourMatches = result.productCode.match(/([^-]+)(?=-[^-]+$)/);
						if (sizeMatches?.length) {
							result.size = sizeMatches[0];
						}

						if (colourMatches && colourMatches.length) {
							result.colour = colourMatches[0];
						}
					}
					return result;
				})
				.filter(newVariation =>
					!this.product.variations.some(existingVariation =>
						existingVariation.guid === newVariation.guid
					)
				)
		);
		this.selectedInventoryItems = [];
		this.disableSelectChange = true;

		this.selectInventory.clearModel();
		this.selectInventory.searchTerm = '';
		this.selectInventory.close();

		setTimeout(() => {
			this.disableSelectChange = false;
		}, 0);
	}

	onClear(type: string) {
		if (type == 'inventory' && this.term) {
			this.term = '';
			this.page = 0;
			this.initInventorySelect();
		} else if (this.decorationQuery.filter.name) {
			delete this.decorationQuery.filter.name;
			this.initDecorationSelect();
		}
	}

	/**
 * @description Removes a customer from a product variation.
 * @param {number} vIdx - The index of the variation to update.
 * @param {number} cIdx - The index of the customer to remove.
 * @throws {Error} An error if the variation is not found or the customer index is invalid.
 * @returns {void} Nothing.
 */
	removeVariationCustomer(vIdx: number, cIdx: number): void {
		const variation = this.product.variations[vIdx];

		if (cIdx < 0 || cIdx >= variation.customers.length) {
			throw new Error(
				`Invalid Customer Index[${cIdx}] for Variation[${variation.id}]`
			);
		}

		variation.customers.splice(cIdx, 1);
	}

	/**
 * @description Given an index `idx`, this function adds the selected customers to the corresponding
 * product variation. It filters the list of customer select options to match the selected IDs and maps
 * them to an array of objects with `customerId` and `customer` properties. It then filters out any new
 * variation customers that already exist in the `variation.customers` array, and pushes the remaining
 * customers to that array.
 *
 * @param {number} idx - The index of the variation to update.
 * @throws {Error} If the variation is not found.
 * @returns {void}
 *
 */
	addVariationCustomer(idx: number) {
		const selectedCustomerIds = this.selectedCustomerOption[idx] || [];
		const selectedCustomers = this.customerSelectOptions.filter(opt => selectedCustomerIds.includes(opt.id));
		const variation = this.product.variations[idx];

		if (!variation) {
			throw new Error(`Unknown Variation at Idx[${idx}]`);
		}

		if (!selectedCustomers.length) {
			return;
		}

		variation.customers = variation.customers || [];

		const newVariationCustomers: any = selectedCustomers
			.map(selectedCustomer => ({
				customerId: parseInt(selectedCustomer.id),
				customer: {
					id: parseInt(selectedCustomer.id),
					name: selectedCustomer.text,
					code: ''
				}
			}))
			.filter(selectedCustomer => !variation.customers.find(existing => existing.customerId === selectedCustomer.customerId));

		variation.customers.push(...newVariationCustomers);
	}

	removeProductCustomer(id: number, i: number) {
		this.productCustomerService.delete(id)
			.subscribe(() => {
				this.customerProducts.splice(i, 1);
			},
				error => {
					console.error(error);
					this.notifications.error('Error', 'Error while deleting customer product.');
				});
	}

	updateCustomerProduct(id: number | undefined, item: ProductCustomer) {
		if (id) {
			this.productCustomerService.update(id.toString(), item)
				.subscribe(() => {
				},
					error => {
						console.error(error);
						this.notifications.error('Error', 'Error while updating customer product.');
					});
		}
	}

	async checkUniqueUrl(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.urlCheckState = 1;
			this.urlCheckMessage = '';

			this.productService.validateProductUri({
				id: this.product.id || undefined,
				productName: this.product.name,
				categories: this.product.categories
			})
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(result => {
					if (result.hasOwnProperty('unique')) {
						if (result.unique === true) {
							this.urlCheckState = 2;
							this.urlCheckMessage = "No Clash Detected"
							return resolve(true);
						} else {
							this.urlCheckState = 3;
							this.urlCheckMessage = "Product Already Exists at this URL";
							return resolve(false);
						}
					} else {
						this.urlCheckState = 3;
						this.urlCheckMessage = "Error Checking URL";
						return resolve(false);
					}
				},
					error => {
						this.urlCheckState = 3;
						this.urlCheckMessage = "Error Checking URL";
						return resolve(false);
					});
		})
	};

	/** UI Helper to determine if the custom size chart should be shown */
	public hasCustomSizeChart(): boolean {
		if (this.useTextSizeChart && this.product.sizeChartId === null)
			return true;
		return false;
	}

	public appendSizeChart() {
		if (this.hasCustomSizeChart()) {
			this.product.sizeChart = this.sizeChartsService.convertFromSizeChart(this.sizeChart);

			if (this.sizeChart.id) {
				this.product.sizeChart.id = this.sizeChart.id;
			}
		} else {
			this.product.sizeChart = null;
		}
	}

	/**
 * @description Validate and persist the product in the server, ignoring validating for Draft Products
 *
 * @param isDraft
 */
	async saveProduct(isDraft: boolean = false) {

		if (!isDraft) {
			this.activeClassId = this.activeClassId + 1;
		}
		if (this.activeClassId == 6 || isDraft) {
			this.activeClassId = this.activeClassId - 1;
			this.notifications.warn(`Saving ${isDraft ? 'Draft' : 'Product'}`, `Saving product ${isDraft ? 'draft ' : ''}to database.`);
			this.product.isDraft = isDraft;

			if (!isDraft) {
				// Perform Validation
				const validUrl = await this.checkUniqueUrl();
				if (!validUrl) {
					this.notifications.error(`Error Saving Product`, `Another product already exists with this name and category combination`);
					return;
				}
			}

			this.appendSizeChart();
			if (!this.product.sizeChartId) {
				this.product.sizeChartId = null;
			}

			if (!isDraft && (!this.product.name || !this.product.name.length)) {
				this.activeClassId = 1;
				return this.notifications.error(
					'Invalid Product',
					'Product must have a name'
				);
			}

			if (!isDraft && (!this.product.code || !this.product.code.length)) {
				this.activeClassId = 1;
				return this.notifications.error(
					'Invalid Product',
					'Product must have code'
				);
			}

			if (!isDraft && (!this.product.categories || this.product.categories.length === 0)) {
				this.activeClassId = 1;
				return this.notifications.error(
					'Invalid Product',
					'Product must have at least one category'
				);
			}

			if (!isDraft && this.product.basePrice === undefined) {
				this.activeClassId = 1;
				return this.notifications.error(
					'Invalid Product',
					'Product must have Base Price'
				);
			}

			let order = 0;
			this.product.variations.forEach(variation => {
				order++;
				variation.displayOrder = order;
			});

			let hasError = false;
			if (!this.usePackSizes) {
				this.product.packSizes = [];
			} else {
				hasError = this.product.packSizes.some((packSize, index) => {
					if (!packSize.name.trim() || packSize.itemCount === 0) {
						this.activeClassId = 1;
						return true;
					}
					return false;
				});

				if (hasError) {
					this.notifications.error(
						'Invalid Pack Size',
						'All pack sizes must have a valid name and item count'
					);
				}
			}
			if (hasError) {
				return;
			}

			this.product.temporarySelectedLabels = this.selectedLabels;
			if (!this.product.imageUrl) {
				this.product.imageUrl = this.product.images.length ? this.product.images[0].url : "";
			}
			this.product.brandId = this.product.brand ? this.product.brand.id : null;
			this.product.features = JSON.stringify(this.selectFeatures);

			// this.product.sizeChartId = null;
			this.productService.create(this.product)
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(() => {
					if (!this.product.id) {
						this.router.navigate(['/manage/products'])
					}
				},
					error => {
						console.error(error);
					});
		}
	}

	removeSizeChart() {
		this.product.sizeChartUrl = null;
	}

	/**
 * @description Removes a variation from the list of variations on the product
 *
 * @param variation
 */
	removeInventoryItem(variation: NewProductVariation) {
		for (let i = 0; i < this.product.variations.length; i++) {
			if (this.product.variations[i].guid === variation.guid) {
				this.product.variations.splice(i, 1);
				break;
			}
		}
	}

	setActive(id: number) {
		this.activeClassId = id;
	}

}
