import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';
import { QueryResult } from '../model/query.filter.class';
import { IBusinessUnit } from '../model/businessUnit.model';
import { BusinessUnit } from '../model/unleashed.model';

// Convenience Type
type T = IBusinessUnit;

export enum ModelsIncludeEnum {
  ShippingDetails = 'ShippingDetails'
}

const getIncludeParameters = (...includes: ModelsIncludeEnum[]) => {
  return {
    params: {
      include: includes.join(',')
    }
  };
};

@Injectable()
export class BusinessUnitApi extends GenericApi<T> {
  public path = "business_unit";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly getBusinessUnitsList = (customerId: number | string): Observable<BusinessUnit[]> => {
    return this.httpClient.get<BusinessUnit[]>(createUrl('customer', customerId, 'business_unit'), {
      ...getIncludeParameters(ModelsIncludeEnum.ShippingDetails)
    });
  };
}
