<section class="supchainsec">
	<div class="max-width-container">
		<div class="container">
			<h1 class="u-select-none">Supply Chain </h1>
			<p class="u-select-none">
				We understand the importance of a strong supply chain, we leverage
				efficient supply chain management practices to minimise costs while
				maintaining high quality standards.
			</p>
		</div>
	</div>
</section>

<section class="supbanner">
	<div class="max-width-container">
		<div class="container">
			<div class="img-box">
				<img src="../../../../assets/images/supplychainbg.webp" alt="" />
			</div>
		</div>
	</div>
</section>

<section class="supmid">
	<div class="max-width-container">
		<div class="container">
			<div class="item sec-a">
				<h2>Software</h2>
				<p>
					Cutting-edge industry software that assists with predictive stock
					management based on historical sales and the growth of Reali
				</p>
				<div class="img-box">
					<img src="../../../../assets/images/supbtmimga.webp" alt="" />
				</div>
			</div>
			<div class="item sec-b">
				<h2>Partners</h2>
				<p>
					We partner with industry-leading logistic services to deliver your
					goods.
				</p>
				<div class="img-box">
					<img src="../../../../assets/images/supbtmimgb.webp" alt="" />
				</div>
			</div>
		</div>
	</div>
</section>

<section class="supbottomsec">
	<div class="max-width-container">
		<div class="container">
			<div class="itembox-a">
				<h2>Get Information on Demand</h2>
				<p>
					We conduct comprehensive supply chain mapping with our suppliers. Our
					continuous growth showcases our adeptness in efficiently managing
					stock for our clients.
				</p>
			</div>
			<div class="itembox-b">
				<div class="consetbox">
					<div class="topmdse">
						<div class="manageTab mb-0 mx-auto">
							<ul class="nav nav-pills" id="pills-tab" role="tablist">
								<li class="nav-item" role="presentation">
									<button
										class="nav-link active"
										id="pills-Shoes-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-Shoes"
										type="button"
										role="tab"
										aria-controls="pills-Shoes"
										aria-selected="true"
									>
										Shoes
									</button>
								</li>
								<li class="nav-item" role="presentation">
									<button
										class="nav-link"
										id="pills-Jackets-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-Jackets"
										type="button"
										role="tab"
										aria-controls="pills-Jackets"
										aria-selected="false"
										tabindex="-1"
									>
										Jackets
									</button>
								</li>
								<li class="nav-item" role="presentation">
									<button
										class="nav-link"
										id="pills-Pants-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-Pants"
										type="button"
										role="tab"
										aria-controls="pills-Pants"
										aria-selected="false"
										tabindex="-1"
									>
										Pants
									</button>
								</li>
								<li class="nav-item" role="presentation">
									<button
										class="nav-link"
										id="pills-Shirts-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-Shirts"
										type="button"
										role="tab"
										aria-controls="pills-Shirts"
										aria-selected="false"
										tabindex="-1"
									>
										Shirts
									</button>
								</li>
							</ul>
						</div>
						<div class="brndname">
							<div class="topbgimg">
								<img src="../../../../assets/images/bnameimg.png" alt="" />
							</div>
							<div class="brandimage">
								<img src="../../../../assets/images/logo.svg" alt="" />
							</div>
						</div>
					</div>
					<div class="midscbg">
						<div class="img">
							<img src="../../../../assets/images/midscbg.png" alt="" />
						</div>
					</div>
					<div class="supplymaincard">
						<div class="tab-content" id="pills-tabContent">
							<div
								class="tab-pane fade active show"
								id="pills-Shoes"
								role="tabpanel"
								aria-labelledby="pills-Shoes-tab"
								tabindex="0"
							>
								<div class="supcard">
									<div class="img-box">
										<img src="../../../../assets/images/boot.png" alt="" />
									</div>
									<div class="supcdetail">
										<div class="sda">
											<span class="badge bg-green-25">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
												>
													<circle
														cx="8.71584"
														cy="9.29983"
														r="1.68655"
														fill="transparent"
													/>
												</svg>
												In stock
											</span>
											<span class="text-loader"></span>
										</div>
										<p>Argyle Derby Style Lace up Ankle Safety Boot</p>
										<div class="spacat">Steel Blue</div>
										<div class="pricesec">
											<span class="price">Price:</span>
											<span class="qty"></span>
										</div>
									</div>
									<div class="dumcard">
										<div class="supcard">
											<div class="img-box">
												<img src="../../../../assets/images/boot.png" alt="" />
											</div>
											<div class="supcdetail">
												<div class="sda">
													<span class="badge bg-green-25">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
														>
															<circle
																cx="8.71584"
																cy="9.29983"
																r="1.68655"
																fill="transparent"
															/>
														</svg>
														In stock
													</span>
													<span class="text-loader"></span>
												</div>
												<p>Argyle Derby Style Lace up Ankle Safety Boot</p>
												<div class="spacat">Steel Blue</div>
												<div class="pricesec">
													<span class="price">Price:</span>
													<span class="qty"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="tab-pane fade"
								id="pills-Jackets"
								role="tabpanel"
								aria-labelledby="pills-Jackets-tab"
								tabindex="0"
							>
								<div class="supcard">
									<div class="img-box">
										<img
											src="../../../../assets/images/jacket.png"
											alt=""
										/>
									</div>
									<div class="supcdetail">
										<div class="sda">
											<span class="badge bg-green-25">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
												>
													<circle
														cx="8.71584"
														cy="9.29983"
														r="1.68655"
														fill="transparent"
													/>
												</svg>
												In stock
											</span>
											<span class="text-loader"></span>
										</div>
										<p>Hi Vis Wet Weather 5 in 1 Jacket</p>
										<div class="spacat">Bisley Workwear</div>
										<div class="pricesec">
											<span class="price">Price:</span>
											<span class="qty"></span>
										</div>
									</div>
									<div class="dumcard">
										<div class="supcard">
											<div class="img-box">
												<img
													src="../../../../assets/images/jacket.png"
													alt=""
												/>
											</div>
											<div class="supcdetail">
												<div class="sda">
													<span class="badge bg-green-25">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
														>
															<circle
																cx="8.71584"
																cy="9.29983"
																r="1.68655"
																fill="transparent"
															/>
														</svg>
														In stock
													</span>
													<span class="text-loader"></span>
												</div>
												<p>Hi Vis Wet Weather 5 in 1 Jacket</p>
												<div class="spacat">Bisley Workwear</div>
												<div class="pricesec">
													<span class="price">Price:</span>
													<span class="qty"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="tab-pane fade"
								id="pills-Pants"
								role="tabpanel"
								aria-labelledby="pills-Pants-tab"
								tabindex="0"
							>
								<div class="supcard">
									<div class="img-box">
										<img
											src="../../../../assets/images/pant.png"
											alt=""
										/>
									</div>
									<div class="supcdetail">
										<div class="sda">
											<span class="badge bg-green-25">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
												>
													<circle
														cx="8.71584"
														cy="9.29983"
														r="1.68655"
														fill="transparent"
													/>
												</svg>
												In stock
											</span>
											<span class="text-loader"></span>
										</div>
										<p>Cotton Drill Cargo Pants - Navy</p>
										<div class="spacat">Eureka</div>
										<div class="pricesec">
											<span class="price">Price:</span>
											<span class="qty"></span>
										</div>
									</div>
									<div class="dumcard">
										<div class="supcard">
											<div class="img-box">
												<img
													src="../../../../assets/images/pant.png"
													alt=""
												/>
											</div>
											<div class="supcdetail">
												<div class="sda">
													<span class="badge bg-green-25">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
														>
															<circle
																cx="8.71584"
																cy="9.29983"
																r="1.68655"
																fill="transparent"
															/>
														</svg>
														In stock
													</span>
													<span class="text-loader"></span>
												</div>
												<p>Cotton Drill Cargo Pants - Navy</p>
												<div class="spacat">Eureka</div>
												<div class="pricesec">
													<span class="price">Price:</span>
													<span class="qty"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="tab-pane fade"
								id="pills-Shirts"
								role="tabpanel"
								aria-labelledby="pills-Shirts-tab"
								tabindex="0"
							>
								<div class="supcard">
									<div class="img-box">
										<img src="../../../../assets/images/shirt.png" alt="" />
									</div>
									<div class="supcdetail">
										<div class="sda">
											<span class="badge bg-green-25">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
												>
													<circle
														cx="8.71584"
														cy="9.29983"
														r="1.68655"
														fill="transparent"
													/>
												</svg>
												In stock
											</span>
											<span class="text-loader"></span>
										</div>
										<p>Men's Lightweight Taped Two Tone Long Sleeve Shirt</p>
										<div class="spacat">Eureka</div>
										<div class="pricesec">
											<span class="price">Price:</span>
											<span class="qty"></span>
										</div>
									</div>
									<div class="dumcard">
										<div class="supcard">
											<div class="img-box">
												<img src="../../../../assets/images/shirt.png" alt="" />
											</div>
											<div class="supcdetail">
												<div class="sda">
													<span class="badge bg-green-25">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
														>
															<circle
																cx="8.71584"
																cy="9.29983"
																r="1.68655"
																fill="transparent"
															/>
														</svg>
														In stock
													</span>
													<span class="text-loader"></span>
												</div>
												<p>
													Men's Lightweight Taped Two Tone Long Sleeve Shirt
												</p>
												<div class="spacat">Eureka</div>
												<div class="pricesec">
													<span class="price">Price:</span>
													<span class="qty"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btmscbg">
						<img src="../../../../assets/images/btmscbg.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
