<ng-template #cancelModel let-modal let-c="close" let-d="dismiss" class="deleteModal">
  <div class="modal-header">
    <h1 class="modal-title">Reject Order</h1>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-4">
          <label for="reason" class="form-label">Reason:</label>
          <input type="text" name="reason" class="form-control" id="reason" [(ngModel)]="cancelReason" />
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
          <a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
            Cancel
          </a>
          <button (click)="save('reject')" class="btn btn-primary text-nowrap w-100">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modifyModel let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h1 class="modal-title">ORDER DETAILS</h1>
    <button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
      aria-label="Close">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="orderNum" class="form-label">Purchase Order Number</label>
          <input type="text" id="orderNum" class="form-control" placeholder="Order Number"
            [(ngModel)]="detailEditForm.orderReference" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="Notes" class="form-label">Shipping Note </label>
          <textarea type="text" class="form-control" rows="5" id="Notes" [(ngModel)]="detailEditForm.notes"
            placeholder="Shipping Notes..."></textarea>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
          <a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
            Cancel
          </a>
          <button (click)="saveCartAttrs()" class="btn btn-primary text-nowrap w-100">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            Approve Order
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>