import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { AllocationLogApi } from '../api/allocationLog.api';
import { IAllocationLog } from '../model/allocationLog.model';
import { GroupApi } from '../api/group.api';
import { IGroup } from '../model/group.model';
import { DocumentApi } from '../api/document.api';
import { IDocument } from '../model/document.model';

@Injectable()
export class DocumentService {
  constructor(
    private notifications: NotificationsService,
    private documentApi: DocumentApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<IDocument & HasId>> {
    return apiCallWrapper(
      this.documentApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Documents"
      }
    )
  }

  public get(id: number): Observable<IDocument> {
    return apiCallWrapper(
      this.documentApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Document By Id"
      }
    )
  }

  public update(id: number, data: IDocument) {
    return apiCallWrapper(
      this.documentApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Update Document"
      }
    )
  }

  public create(data: IDocument) {
    return apiCallWrapper(
      this.documentApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create Document"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.documentApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Document"
      }
    )
  }
}
