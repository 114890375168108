import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAllocationLog } from '../model/allocationLog.model';

// Convenience Type
type T = IAllocationLog;

@Injectable()
export class AllocationLogApi extends GenericApi<T> {
  public path = "allocationLogs";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
