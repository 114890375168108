import { DecorationOrProduct, NewDecoration } from "./ddb.model";

export interface DecorationDraft {
  id: number;
  decorationId: number | null;
  decorationJSON: string;
}

export class DecorationOption {
  id: number;
  name: string = '';
  required: boolean = false;
  value: string;
}

export interface DecorationCustomer {
	customerId: number;
	customPrice: number | null;
	decoration: DecorationOrProduct,
	decorationId?: number,
	id?: number,
	customer?: { name: string }
}

export type IHasDecoartion = {
	decoration: NewDecoration;
}

