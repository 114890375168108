import { Injectable } from '@angular/core';

export enum StorageServiceKey {
  jwt = 'jwt',
  jwt_refresh = 'jwt_refresh',
  jwt_token_type = 'jwt_token_type',
	app_version_before_upgrade = 'app_version_before_upgrade',
	app_version_skipped = 'app_version_skipped'
}

@Injectable()
export class StorageService {
  constructor() {
  }

  getItem(key: StorageServiceKey, defaultValue?: unknown): unknown {
    const storageItem = localStorage.getItem(key as string);

    return !!storageItem ? JSON.parse(storageItem) : defaultValue;
  }

  setItem(key: StorageServiceKey, value: unknown): void {
    localStorage.setItem(key as string, JSON.stringify(value));
  }

  removeItem(key: StorageServiceKey): void{
    localStorage.removeItem(key as string);
  }
}
