import { Component } from '@angular/core';

@Component({
  selector: 'app-unauth-product-page',
  templateUrl: './unauth-product-page.component.html',
  styleUrls: []
})
export class UnauthProductPageComponent {

}
