import { Component } from '@angular/core';
import { IUserAllocation } from '../../model/userAllocation.model';
import { QueryResult } from '../../model/query.filter.class';
import { ICollection } from '../../model/collection.model';
import { SessionApi } from '../../api/session.api';

@Component({
  selector: 'app-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: []
})
export class BalanceDetailComponent {
  allocations: IUserAllocation[] = [];

  queryResult: QueryResult<ICollection> = new QueryResult();

  constructor(
		public session: SessionApi
  ) { }

  ngOnInit() {
		this.session.$customerUser.subscribe({
			next: data => {
				if( !data || !data.userAllocations ) {
					this.allocations = [];
					return;
				}

				this.allocations = data.userAllocations;
			}
		});
  }

}
