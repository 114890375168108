<div class="main-right-content">
  <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4 gap-4">
    <div class="backs mb-0">
      <a [routerLink]="['/account/business-unit']" class="btn btn-back p-0 gap-2" role="button">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </span>
        Back to business units
      </a>
    </div>
    <nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item">
          <a [routerLink]="['/account/business-unit']">Business unit</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{businessUnit.name}}
        </li>
      </ol>
    </nav>
  </div>

  <div class="manageUser">
    <div class="d-flex flex-column flex-md-row align-items-start justify-content-between">
      <h1 class="title" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Click to change the name">
        {{businessUnit.name}}
      </h1>

      <div class="edit-btns w-sm-100 w-auto">
        <a (click)="deleteItem(businessUnit.id)" class="btn btn-danger text-nowrap w-100"><span><svg
              xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M3.25 5.5H4.75H16.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></span>Remove list</a>
        <a [routerLink]="['/account/business-unit/edit', businessUnit.id]"
          class="btn btn-primary text-nowrap w-100"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
              viewBox="0 0 20 20" fill="none">
              <path
                d="M10 16.0002H16.75M13.375 3.62517C13.6734 3.3268 14.078 3.15918 14.5 3.15918C14.7089 3.15918 14.9158 3.20033 15.1088 3.28029C15.3019 3.36024 15.4773 3.47743 15.625 3.62517C15.7727 3.77291 15.8899 3.9483 15.9699 4.14132C16.0498 4.33435 16.091 4.54124 16.091 4.75017C16.091 4.9591 16.0498 5.16599 15.9699 5.35902C15.8899 5.55204 15.7727 5.72743 15.625 5.87517L6.25 15.2502L3.25 16.0002L4 13.0002L13.375 3.62517Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></span>Edit</a>
      </div>
    </div>

    <div class="manage-user-list">
      <div class="edit-users edit-groups">
        <div class="edit-card card mb-5">
          <div class="card-body">
            <h5 class="details-card-heading">Details</h5>
            <ul class="details-wrapper">
              <li>
                <span>Code</span>
                <p>{{businessUnit.code}}</p>
              </li>
              <li>
                <span>Contact</span>
                <p *ngIf="businessUnit.contact && businessUnit.contact.user">{{businessUnit.contact.user.firstName}}
                  {{businessUnit.contact.user.lastName}}</p>
              </li>
              <li>
                <span>name</span>
                <p>{{businessUnit.name}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-lg-row align-items-start justify-content-between">
      <h1 class="title">Orders</h1>
      <app-tab-set #tabSet [activeTabIdx]="activeTabIdx" [tabs]="getTabs()"
        (click)="onTabChange(tabSet.activeTabIdx)"></app-tab-set>
    </div>
    <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
      <app-order-list></app-order-list>
    </div>
    <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
      <app-order-list *ngIf="activeTabIdx == 1" [orderStatus]="'Processing'"></app-order-list>
    </div>
    <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }">
      <app-order-list *ngIf="activeTabIdx == 2" [orderStatus]="'COMPLETED'"></app-order-list>
    </div>
    <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 3 }">
      <app-order-list *ngIf="activeTabIdx == 3" [orderStatus]="'BACKORDERED'"></app-order-list>
    </div>
    <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 4 }">
      <app-order-list *ngIf="activeTabIdx == 4" [orderStatus]="'PENDING'"></app-order-list>
    </div>
  </div>
</div>