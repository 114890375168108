<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/decorations']">Decorations </a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ decoration.id ? "Edit" : "Add new" }} decoration
			</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div class="manage-user-list">
			<div class="customer-wizard">
				<div class="wizard-tabs">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(1)" type="button" [class.active]="activeClassId == 1"
								[class.complete]="activeClassId >= 2">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 1</h6>
									<h5>Overview</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(2)" type="button" [class.active]="activeClassId == 2"
								[class.complete]="activeClassId >= 3">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M15.25 3.25H4.75C3.92157 3.25 3.25 3.92157 3.25 4.75V15.25C3.25 16.0784 3.92157 16.75 4.75 16.75H15.25C16.0784 16.75 16.75 16.0784 16.75 15.25V4.75C16.75 3.92157 16.0784 3.25 15.25 3.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M16.75 12.25L13 8.5L4.75 16.75" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
										<path
											d="M7.375 8.5C7.99632 8.5 8.5 7.99632 8.5 7.375C8.5 6.75368 7.99632 6.25 7.375 6.25C6.75368 6.25 6.25 6.75368 6.25 7.375C6.25 7.99632 6.75368 8.5 7.375 8.5Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 2</h6>
									<h5>Images</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(3)" type="button" [class.active]="activeClassId == 3"
								[class.complete]="activeClassId >= 4">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 3</h6>
									<h5>Customers</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(4)" type="button" [class.active]="activeClassId == 4"
								[class.complete]="activeClassId >= 5">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16.75 13V7C16.7497 6.73695 16.6803 6.4786 16.5487 6.25087C16.417 6.02313 16.2278 5.83402 16 5.7025L10.75 2.7025C10.522 2.57084 10.2633 2.50154 10 2.50154C9.7367 2.50154 9.47803 2.57084 9.25 2.7025L4 5.7025C3.7722 5.83402 3.58299 6.02313 3.45135 6.25087C3.31971 6.4786 3.25027 6.73695 3.25 7V13C3.25027 13.263 3.31971 13.5214 3.45135 13.7491C3.58299 13.9769 3.7722 14.166 4 14.2975L9.25 17.2975C9.47803 17.4291 9.7367 17.4985 10 17.4985C10.2633 17.4985 10.522 17.4291 10.75 17.2975L16 14.2975C16.2278 14.166 16.417 13.9769 16.5487 13.7491C16.6803 13.5214 16.7497 13.263 16.75 13Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M3.45215 6.21999L9.99965 10.0075L16.5471 6.21999" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10 17.56V10" stroke="transparent" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 4</h6>
									<h5>Inventory</h5>
								</div>
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade" [class.show]="activeClassId == 1" [class.active]="activeClassId == 1">
						<div class="edit-users">
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="url(#paint0_radial_0_17046)" fill-opacity="0.5" />
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="url(#paint1_radial_0_17046)" fill-opacity="0.5" />
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<defs>
														<radialGradient id="paint0_radial_0_17046" cx="0" cy="0" r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
															<stop stop-color="#8BD6EF" />
															<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
														</radialGradient>
														<radialGradient id="paint1_radial_0_17046" cx="0" cy="0" r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
															<stop stop-color="#8BD6EF" />
															<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
														</radialGradient>
													</defs>
												</svg>
											</span>
											General information
										</h4>
									</div>
									<div class="card-body">
										<form action="">
											<div class="row">
												<div class="col-12">
													<div class="form-group form-check">
														<input type="radio" class="form-check-input" id="Approval1" name="accessMode" [value]="2"
															[(ngModel)]="decoration.accessMode" />
														<label class="form-check-label" for="Approval1">This Decoration is available before user
															login
														</label>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group form-check">
														<input type="radio" class="form-check-input" id="Approval2" type="radio" name="accessMode"
															[value]="1" [(ngModel)]="decoration.accessMode" />
														<label class="form-check-label" for="Approval2">This Decoration is available to all logged
															in
															users
														</label>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group form-check">
														<input type="radio" class="form-check-input" id="Approval3" type="radio" name="accessMode"
															[value]="0" [(ngModel)]="decoration.accessMode" />
														<label class="form-check-label" for="Approval3">This Decoration is only visible to selected
															customers
														</label>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group form-check">
														<input type="checkbox" class="form-check-input" name="inStock" id="stock"
															[value]="decoration.inStock" [(ngModel)]="decoration.inStock" />
														<label class="form-check-label" for="stock">Decoration in Stock
														</label>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group form-check">
														<input type="checkbox" class="form-check-input" name="isReq" id="req"
															[value]="decoration.isRequired" [(ngModel)]="decoration.isRequired" />
														<label class="form-check-label" for="req">Decoration in Required
														</label>
													</div>
												</div>
												<div class="col-12 col-lg-6">
													<div class="form-group">
														<label for="decorationName" class="form-label">Decoration Name</label>
														<input type="text" class="form-control" id="decorationName" name="decorationName"
															[(ngModel)]="decoration.name" appInputMaxLength="120" />
													</div>
												</div>
												<div class="col-12 col-lg-6">
													<div class="form-group">
														<label for="decorationWeight" class="form-label">Decoration Weight <span>(grams)</span>
														</label>
														<input type="number" class="form-control" id="decorationWeight" name="decorationWeight"
															[(ngModel)]="decoration.weight" min="0" />
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<label for="decorationProduct" class="form-label">Times decoration can be used per
															product</label>
														<input type="number" name="maxPerProduct" class="form-control" id="decorationProduct"
															placeholder="Max times per product" [(ngModel)]="decoration.maxPerProduct" min="0" />
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title gap-md-2">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path d="M10 1.75V18.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path
														d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
											Pricing
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-lg-9">
												<div class="form-group">
													<label for="Amount" class="form-label">Amount ($AUD)</label>
													<input type="number" id="Amount" class="form-control" name="Amount" placeholder="Base Price"
														[(ngModel)]="decoration.basePrice" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 2" [class.active]="activeClassId == 2">
						<div class="edit-users order-cards">
							<div class="form-group" *ngFor="let colour of getImageOptions()">
								<label for="ImageUpload {{ colour }}" class="form-Image-label images">
									<div class="uploader-position" [class.d-none]="!!getImageUrl(colour)">
										<div class="Image-upload-icon">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
													<path
														d="M9 10.75C9.32209 11.1806 9.73302 11.5369 10.2049 11.7947C10.6768 12.0526 11.1986 12.2059 11.735 12.2443C12.2713 12.2827 12.8097 12.2053 13.3135 12.0174C13.8173 11.8294 14.2748 11.5353 14.655 11.155L16.905 8.90504C17.5881 8.19778 17.9661 7.25052 17.9575 6.26728C17.949 5.28404 17.5546 4.3435 16.8593 3.64822C16.164 2.95294 15.2235 2.55855 14.2403 2.55001C13.257 2.54146 12.3098 2.91944 11.6025 3.60254L10.3125 4.88503"
														stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path
														d="M11.9997 9.24999C11.6776 8.81939 11.2666 8.4631 10.7947 8.20528C10.3229 7.94746 9.80103 7.79415 9.26467 7.75574C8.72832 7.71732 8.18997 7.79471 7.68615 7.98265C7.18233 8.17059 6.72483 8.46468 6.34466 8.84499L4.09466 11.095C3.41157 11.8022 3.03359 12.7495 3.04213 13.7327C3.05068 14.716 3.44506 15.6565 4.14034 16.3518C4.83562 17.0471 5.77617 17.4415 6.75941 17.45C7.74264 17.4586 8.6899 17.0806 9.39716 16.3975L10.6797 15.115"
														stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
										</div>
										<h4 class="Image-upload-title">Upload Image</h4>
									</div>
									<div class="preview-content" [class.d-none]="!getImageUrl(colour)">
										<img src="{{ getImageUrl(colour) }}" alt="product-jacket" />
										<div *ngIf="getImageUrl(colour)" class="dlt-top">
											<button class="btn btn-secondary-icon" type="button" (click)="deleteImage(colour, $event)">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
														fill="none">
														<path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
															stroke-linejoin="round" />
														<path
															d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
															stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</span>
											</button>
										</div>
									</div>
									<span class="badge badge-front">{{ colour }}</span>
								</label>
								<input type="file" capture="user" class="form-control d-none" id="ImageUpload {{ colour }}"
									placeholder="" (change)="persistS3Image($event, colour)" />
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 3" [class.active]="activeClassId == 3">
						<!-- Add products content start here... -->
						<div class="add-products">
							<div class="form-group">
								<label for="selectCustomer" class="form-label d-none"></label>
								<app-custom-select #customerData [data]="customerSelectOptions" (onChange)="updateCustomer($event)"
									(loadData)="loadCusomerData($event)" [multiple]="true" [type]="'customer'"
									(clearQuery)="updateQuery($event)"></app-custom-select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary w-sm-100" (click)="addSelectedCustomer()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Assign Customer
								</a>
							</div>
						</div>
						<!-- Add products content end here... -->

						<!-- products add-edit cards start here... -->
						<div class="edit-users">
							<div class="edit-card card" *ngFor="let customer of decoration.customers; let i = index">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Customer {{ i + 1 }}
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="removeProductCustomer(customer)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="Name" class="form-label">Name</label>
												<input type="text" class="form-control" id="Name" placeholder="Workpac Project Services East..."
													value="{{
														customer.customer
														? customer.customer.name
														: getProductCustomerName(customer)
													}}" disabled />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="customerPrice" class="form-label">Customer Price</label>
												<input type="number" name="customerPrice" class="form-control" id="customerPrice"
													placeholder="Custom Price" [(ngModel)]="customer.customPrice" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- products add-edit cards end here... -->
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 4" [class.active]="activeClassId == 4">
						<!-- Add products content start here... -->
						<div class="add-products">
							<div class="form-group" *ngIf="lastUnleashedProductListResult">
								<label for="selectInventory" class="form-label d-none"></label>
								<app-custom-select #inventorySelect [data]="lastUnleashedProductListResult"
									(onChange)="updateInventory($event)" (loadData)="loadData($event)" [multiple]="true"
									[type]="'inventory'" (clearQuery)="updateQuery($event)"></app-custom-select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary w-sm-100" (click)="addSelectedInventoryItems()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Add Inventory Item(s)
								</a>
							</div>
						</div>
						<!-- Add products content end here... -->

						<!-- products add-edit cards start here... -->
						<div class="edit-users">
							<div class="edit-card card" *ngFor="
									let variation of decoration.variations;
									let i = index;
									first as isFirst;
									last as isLast
								">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Inventory {{ i + 1 }}
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="removeInventoryItem(variation)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="Name" class="form-label">Name</label>
												<input type="text" class="form-control" id="Name" name="variation" placeholder="Name...."
													[(ngModel)]="variation.name" />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="productCode" class="form-label">Product Code</label>
												<input type="text" class="form-control" name="code" id="productCode" placeholder="Product Code"
													[(ngModel)]="variation.productCode" />
											</div>
										</div>
										<div class="col-12">
											<div class="row" *ngFor="let option of decoration.options; let o = index">
												<div class="col-12">
													<div class="form-group form-check">
														<input type="checkbox" class="form-check-input"
															id="{{ variation.guid }}-option-{{ o }}-required" [value]="1" name="{{ variation.guid }}-option-{{
																o
															}}-required" [(ngModel)]="option.required" />
														<label class="form-check-label" for="{{ variation.guid }}-option-{{ o }}-required">Required
														</label>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<input type="text" name="{{ variation.guid }}-option-{{
																o
															}}-required" class="form-control" id="{{ variation.guid }}-option-{{ o }}"
															placeholder="Field Name (EG: Full Name)" [(ngModel)]="option.name" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<a class="btn btn-primary w-100" (click)="addVariationOption()" role="button">
													<span>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
															fill="none">
															<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
																stroke-linejoin="round"></path>
															<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
																stroke-linejoin="round"></path>
														</svg>
													</span>
													Add Configuration Option
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- products add-edit cards end here... -->
					</div>
				</div>
				<div class="wizard-footer">
					<button class="btn btn-secondary" type="button" [routerLink]="['/manage/decorations']">Cancel</button>
					<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-2">
						<button (click)="saveDecoration(true)" class="btn btn-secondary w-100" type="button">
							<span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg></span>
							Save as draft
						</button>
						<button (click)="saveDecoration()" class="btn btn-primary" type="button">
							{{ activeClassId >= 4 ? "Save" : "Continue" }}
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>