<div class="main-right-content">
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">Account Balance</h1>
		</div>
		<div class="manage-user-list">
			<app-user-allocation-edit
				[readonly]="true"
				[showTitle]="false"
				[loadCustomerUser]="false"
				[allocations]="allocations"
			>
			</app-user-allocation-edit>
		</div>
	</div>
</div>
