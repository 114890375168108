import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGroup } from '../model/group.model';

// Convenience Type
type T = IGroup;

@Injectable()
export class GroupApi extends GenericApi<T> {
  public path = "groups";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
