<div class="overview-list">
	<div class="overview-content">
		<!-- first ul start here... -->
		<ul>
			<li>
				<p>
					<span>Customer name</span>
					{{ customer.CustomerName }}
				</p>
			</li>
			<li>
				<p>
					<span>Customer code</span>
					{{ customer.CustomerCode }}
				</p>
			</li>
			<li>
				<p>
					<span>Access mode</span>
					{{
					customer.AssignedProductsOnly
					? "Selected Products Only"
					: "All Products"
					}}
				</p>
			</li>
			<li class="d-none">
				<p>
					<span>Business unit</span>
					+ Add business unit
				</p>
			</li>
		</ul>
		<!-- first ul end here... -->

		<!-- Second ul start here... -->
		<ul>
			<li>
				<p>
					<span> Small Order Fee</span>
					{{
					customer.HandlingFee || customer.HandlingFee == null
					? "Fee on Purchases Below Threshold"
					: "No Small Order Fee"
					}}
				</p>
			</li>
			<li>
				<p>
					<span> Small Order Fee amount</span>
					{{ customer.HandlingFeeAmount }}
					<img src="../../../../../assets/images/Text.dot.svg" alt="text.dot" />
					{{ customer.HandlingFeeThreshold }} threshold
				</p>
			</li>
			<li>
				<p>
					<span>Discount rate</span>
					{{ customer.discountAmount }}%
				</p>
			</li>
			<li>
				<p>
					<span>Reminders</span>
					1 (week)
				</p>
			</li>
		</ul>
		<!-- Second ul end here... -->

		<!-- Third ul start here... -->
		<ul>
			<li>
				<p>
					<span>Default landing page</span>
					{{
					customer.defaultUri
					? customer.defaultUri.split("/")[1] ||
					customer.defaultUri.split("/")[2]
					: ""
					}}
				</p>
			</li>
			<li>
				<p>
					<span>Default warehouse</span>
					{{ customer.defaultWarehouse }}
				</p>
			</li>
		</ul>
		<!-- Third ul end here... -->

		<!-- forth ul start here... -->
		<ul>
			<li>
				<p>
					<span>redirectEmail</span>
					{{ customer.redirectEmail }}
				</p>
			</li>
			<li>
				<p>
					<span>ccEmail</span>
					{{ customer.ccEmail }}
				</p>
			</li>
		</ul>
		<!-- forth ul end here... -->

		<div class="customer-overview">
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.stopCredit,
						'bg-green-25': customer.stopCredit
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.stopCredit
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent stroke-width= 1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{ !customer.stopCredit ? "Disabled" : "Enabled" }}</span>
				<p>Customer is on a stop credit</p>
				<span class="small-content">A message will be dispalyed to ever user</span>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.disable2faCheck,
						'bg-green-25': customer.disable2faCheck
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.disable2faCheck
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent stroke-width= 1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{ !customer.disable2faCheck ? "Disabled" : "Enabled" }}</span>
				<p>2FA Check</p>
				<span class="small-content">2FA will still be available. Notifications will not be sent</span>
			</div>
		</div>

		<div class="customer-overview">
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.enableOrdersRequireApproval,
						'bg-green-25': customer.enableOrdersRequireApproval
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.enableOrdersRequireApproval
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{
					!customer.enableOrdersRequireApproval ? "Disabled" : "Enabled"
					}}</span>
				<p>Send allocation for approval</p>
				<span class="small-content">Approvals are sent approvals are enabled at EITHER customer or user
					level</span>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.allowPackOrderNames,
						'bg-green-25': customer.allowPackOrderNames
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.allowPackOrderNames
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{ !customer.allowPackOrderNames ? "Disabled" : "Enabled" }}</span>
				<p>Pack order names fuctionality</p>
				<span class="small-content">Users will be permitted to upload supporting documents</span>
			</div>
		</div>

		<div class="customer-overview">
			<div>
				<span class="badge" [class]="{
						'bg-red-25': customer.disableAddressEdit,
						'bg-green-25': !customer.disableAddressEdit
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								customer.disableAddressEdit
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent stroke-width= 1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{ customer.disableAddressEdit ? "Disabled" : "Enabled" }}</span>
				<p>Permission to change shipping address</p>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.showProductSearchCategories,
						'bg-green-25': customer.showProductSearchCategories
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.showProductSearchCategories
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent stroke-width= 1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{
					!customer.showProductSearchCategories ? "Disabled" : "Enabled"
					}}</span>
				<p>Show product search categories</p>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.showProductSearchCollections,
						'bg-green-25': customer.showProductSearchCollections
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.showProductSearchCollections
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{
					!customer.showProductSearchCollections ? "Disabled" : "Enabled"
					}}</span>
				<p>Show product search collections</p>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-red-25': !customer.enableBulkOrder,
						'bg-green-25': customer.enableBulkOrder
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								!customer.enableBulkOrder
									? 'M15 5.5L6 14.5M6 5.5L15 14.5'
									: 'M16.5 5.5L8.25 13.75L4.5 10'
							" stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{ !customer.enableBulkOrder ? "Disabled" : "Enabled" }}</span>
				<p>Bulk order</p>
			</div>
		</div>
		<div class="customer-overview grid-row">
			<div>
				<span class="badge" [class]="{
						'bg-green-25':
							customer.employeePurchaseMode == 'both' || 'credit-card',
						'bg-red-25': customer.employeePurchaseMode == 'invoice'
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								customer.employeePurchaseMode == 'both' || 'credit-card'
									? 'M16.5 5.5L8.25 13.75L4.5 10'
									: 'M15 5.5L6 14.5M6 5.5L15 14.5'
							" stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{
					customer.employeePurchaseMode == "both" || "credit-card"
					? "Enabled"
					: "Disabled"
					}}</span>
				<p>Credit Card</p>
				<span class="small-content">The user may use their credit card to pay for their orders</span>
			</div>
			<div>
				<span class="badge" [class]="{
						'bg-green-25': customer.employeePurchaseMode == 'both' || 'invoice',
						'bg-red-25': customer.employeePurchaseMode == 'credit-card'
					}">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
						<path [attr.d]="
								customer.employeePurchaseMode == 'both' || 'credit-card'
									? 'M16.5 5.5L8.25 13.75L4.5 10'
									: 'M15 5.5L6 14.5M6 5.5L15 14.5'
							" stroke="transparent stroke-width=1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					{{
					customer.employeePurchaseMode == "both" || "credit-card"
					? "Enabled"
					: "Disabled"
					}}</span>
				<p>Invoice</p>
				<span class="small-content">The user may charge their order to the customer account.</span>
			</div>
		</div>
	</div>
</div>
