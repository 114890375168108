import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Subject, Subscription, tap, filter, debounce, interval, of, concatMap, bufferCount, debounceTime, distinctUntilChanged } from 'rxjs';
import { DecorationOrProduct, IHasProduct, IMightHaveId, NewDecoration, NewProduct } from 'src/app/main/model/ddb.model';
import { DecorationCustomer, IHasDecoartion } from 'src/app/main/model/decoration.model';
import { HasId } from 'src/app/main/model/generics';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { CustomerProductService } from 'src/app/main/services/customerProduct.service';
import { DecorationService } from 'src/app/main/services/decoration.service';
import { CustomerDecorationService } from 'src/app/main/services/decorationCustomer.service';
import { ProductService } from 'src/app/main/services/product.service';

@Component({
  selector: 'app-customer-decoration',
  templateUrl: './customer-decoration.component.html',
  styleUrls: []
})
export class CustomerDecorationComponent {
  @Input() customerDecoration: { decoration: DecorationOrProduct, customPrice: number }[];

  private customerId: number;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private pendingUpdates: (IMightHaveId & DecorationCustomer & IHasDecoartion)[] = [];
  private updateDecorationCustomerRequests$ = new BehaviorSubject<null>(null);
  private selectedDecoration: DecorationOrProduct;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchDecorationTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  @ViewChild('decoration') selectDecoration: NgSelectComponent;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
    order: 'desc',
    limit: 10,
    include: [{ association: 'decoration', required: true }]
  });

  public decorationQuery: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
    filter: {}
  });

  sortOptions = [{
    id: 1,
    text: "Custom Price",
    field: "customPrice"
  }, {
    id: 2,
    text: "Id",
    field: 'id'
  }];

  queryResult: QueryResult<DecorationCustomer & HasId<number>> = new QueryResult();
  decorationResult: NewDecoration[] = [];
  public noItemsFoundText: string;
  isAlreadyChecked: boolean;
  private _currentPage: any;
  constructor(
    public route: ActivatedRoute,
    private decorationService: DecorationService,
    private notifications: NotificationsService,
    private customerDecorationService: CustomerDecorationService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.customerId = parseInt(params.customerId);
      this.loadDecorations();
      this.search();
      this.productSearch();
    });

    this.updateDecorationCustomerRequests$.pipe(
      tap(() => { }),
      filter(() => this.pendingUpdates.length > 0),
      debounce(() => interval(1000))
    ).subscribe(() => {
      const updates = JSON.parse(JSON.stringify(this.pendingUpdates));
      this.pendingUpdates = [];
      of(...updates).pipe(
        concatMap((updatedCP: any) => {
          return this.customerDecorationService.update(updatedCP.id.toString(), updatedCP);
        }),
        bufferCount(updates.length)
      ).subscribe(() => {
      });
    })
  }

  /**
 * @description Loads the customer user data from the API
 */
  loadDecorationProducts() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    this.query.filter['customerId'] = this.customerId;

    this.customerDecorationService.list(this.query)
      .subscribe(queryResult => {
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadDecorationProducts();
        }
      });
  }

  loadDecorations(isScroll: boolean = false) {
    this.noItemsFoundText = "Fetching...";
    this._currentPage = isScroll ? this._currentPage + 1 : 1;
    this.decorationQuery.skip = this.decorationQuery.limit * (this._currentPage - 1);

    this.decorationService.list(this.decorationQuery)
      .subscribe(queryResult => {
        if (queryResult.rows.length == 0) {
          this.noItemsFoundText = "No product found";
        }
        if (isScroll) {
          this.decorationResult.push(...queryResult.rows);
        } else {
          this.decorationResult = queryResult.rows;
        }
      });
  }

  onProductSearch(searchTerm: { term: string; items: any[]; }) {
    this.searchDecorationTerms.next(searchTerm.term);
  }

  productSearch() {
    this.searchDecorationTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.decorationQuery.filter['$or'] = {
          name: { $like: '%' + searchTerm + '%' }
        }
      else
        delete this.decorationQuery.filter['$or'];
      this.loadDecorations();
    });
  }


  queueUpdateProductCustomer(productCustomerIdx: number) {
    const updatedCustomerProduct = this.queryResult.rows[productCustomerIdx];
    const updatedCPString = JSON.stringify(updatedCustomerProduct);
    const existingUpdate = this.pendingUpdates.findIndex(val => {
      if (val.id && updatedCustomerProduct.id && val.id === +updatedCustomerProduct.id) {
        return true;
      } else if (JSON.stringify(val) === updatedCPString) {
        return true;
      }
      return false;
    });

    const updatedCPClone = JSON.parse(updatedCPString);

    if (existingUpdate >= 0) {
      this.pendingUpdates[existingUpdate] = updatedCPClone;
    } else {
      this.pendingUpdates.push(updatedCPClone);
    }

    this.updateDecorationCustomerRequests$.next(null);
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadDecorationProducts()
  }

  /**
 * @description Ensures the page number is in sync across multiple pagination components
 *
 * @param {number} pageSize Broadcast pageSize value
 */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.loadDecorationProducts();
  }

  addSelectedProduct() {
    this.selectedDecoration = this.selectDecoration.selectedValues.length && this.selectDecoration.selectedValues[0];
    if (this.selectedDecoration.id && !this.queryResult.rows.find(product => this.selectedDecoration.id && product.decorationId == +this.selectedDecoration.id)) {
      const newProductCustomer: DecorationCustomer = {
        decoration: JSON.parse(JSON.stringify(this.selectedDecoration)),
        decorationId: +this.selectedDecoration.id,
        customPrice: null,
        customerId: this.customerId
      };
      this.customerDecorationService.create(newProductCustomer).subscribe({
        next: () => {
          this.loadDecorationProducts();
        }
      });
    } else {
      this.notifications.warn('Add Product', 'Prodct already added.Please choose another one');
    }
  }

  removeProductCustomer(productCustomerIdx: number) {
    const removedProductCustomer = this.queryResult.rows.splice(productCustomerIdx, 1);

    if (removedProductCustomer.length && removedProductCustomer[0].id) {

      const existingUpdate = this.pendingUpdates.findIndex(val => {
        if (val.id && removedProductCustomer[0].id && val.id === removedProductCustomer[0].id) {
          return true;
        }

        return false;
      });
      if (existingUpdate >= 0) {
        this.pendingUpdates.splice(existingUpdate, 1);
      }

      this.decorationService.delete(removedProductCustomer[0].id).subscribe({
        next: () => {
          this.loadDecorationProducts();
        }
      });
    }
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadDecorationProducts();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (this.query.include) {
        if (searchTerm && searchTerm.length) {
          this.query.include[0].filter = this.query.include[0].filter || {};
          this.query.include[0].filter['$or'] = {
            name: { $like: '%' + searchTerm + '%' }
          }
        } else {
          delete this.query.include[0].filter;
        }
      }
      this.loadDecorationProducts();
    });
  }
}
