import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-reports',
  template: `
    <div class="main-right-content">
  <div class="manageUser">
    <div class="d-flex align-items-start justify-content-between">
      <h1 class="title">Admin Reports</h1>
    </div>

    <div class="manage-user-list">
      <div class="edit-users">
        <div class="edit-card card report-card">
          <div class="card-body">
            <div class="reports">
              <div class="report-dlt" [routerLink]="['/manage/admin-reports/staleProduct']">
                <button class="btn btn-secondary-icon" type="button">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
                        stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </button>
              </div>
              <div class="report-content">
                <a role="button" [routerLink]="['/manage/admin-reports/staleProduct']" class="btn title">Stale Product Report</a>
                <p class="desc">
                  List all products that have not been purchased for 6
                  months or more. Report can be exported to CSV.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="edit-card card report-card">
          <div class="card-body">
            <div class="reports">
              <div class="report-dlt" [routerLink]="['/manage/admin-reports/abnormalCart']">
                <button class="btn btn-secondary-icon" type="button">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
                        stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </button>
              </div>
              <div class="report-content">
							<a role="button" [routerLink]="['/manage/admin-reports/abnormalCart']" class="btn title">Abnormal Cart Report</a>
                <p class="desc">
									Lists all orders that have products in them attached to allocation collections.
									Only shows carts where the item is not in the collection they have been assigned to.
									(EG: Boots attached to a Shirts Collection)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  styleUrls: []
})
export class AdminReportsComponent {

}
