import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { tap } from 'rxjs/internal/operators/tap';
import { filter, map } from 'rxjs/operators';
import { IAuthUser } from '../model/user.model';
import { SessionApi } from '../api/session.api';
import { logger } from '../util/Logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../template/model.component';
import { apiHost } from '../api/api.util';
import { environment } from 'src/environments/environment';

const className = "WebSocketService"

@Injectable()
export class WebSocketService {
  private _connectedUser: IAuthUser | null = null;
  private _pingCounter: number = 0;

  public _connectionSession: { id: string, key: string } | null = null;
  public _lastKnownMessage: number;
  public connection$: BehaviorSubject<WebSocketSubject<any> | null> = new BehaviorSubject(null);

  constructor(
    private session: SessionApi,
    private modalService: NgbModal,
  ) {
  }

  public initiateConnection() {
    this.monitorSessionData();
    this.automaticallyReopen();
  }

  public automaticallyReopen() {
    const signature = className + ".automaticallyReopen: ";

    interval(1000).pipe(
      filter(() => !!this._connectionSession && !!this.connection$.value),
      filter(() => !!this._lastKnownMessage),
      filter(() => {
        const diff = new Date().getTime() - this._lastKnownMessage;

        return diff > 1000;
      }),
      map(() => this.connection$.value)
    ).subscribe(connection => {
      this._pingCounter++;
      connection?.next({ "message": "PING" });

      if (this._pingCounter > 5) {
        logger.silly(signature + 'Closing dead connection');

        this.endCurrentConnection();

        if (this._connectedUser) {
          logger.silly(signature + 'Reconnecting');
          this.createNewConnection(this._connectedUser);
        } else {
          this.connection$.next(null);
        }
      }
    });

  }

  public monitorSessionData() {
    const signature = className + ".monitorSessionData: ";
    this.session.$userData.subscribe({
      next: userData => {
        const currentConnection = this.connection$.value;
        const userIsAuthenticated = userData && userData.id;
        if (currentConnection && userIsAuthenticated && userData.id === this._connectedUser?.id) {
          logger.info(signature + `Disregarding session change due to existing connection`);
          return;
        }

				this.connectCurrentUser();
      }
    });
  }

	private connectCurrentUser() {
    const signature = className + ".createNewConnectionForCurrentUser: ";
		const userData = this.session.$userData.value;

		const userIsAuthenticated = userData && userData.id;
		this.closeCurrentConnection();

		if (userIsAuthenticated) {
			this.createNewConnection(userData);
		} else {
			this.connection$.next(null);
		}
	}

  private endCurrentConnection() {
    const signature = className + ".endCurrentConnection: ";
    const currentConnection = this.connection$.value;

    if (currentConnection) {
      logger.info(signature + `Ending existing connection for User[${this._connectedUser}]`);
      currentConnection.unsubscribe();
      currentConnection.complete();
    }
  }

  private closeCurrentConnection() {
    const signature = className + ".closeCurrentConnection: ";
    const currentConnection = this.connection$.value;

    if (currentConnection) {
      this.endCurrentConnection();
      this._connectedUser = null;
      this._connectionSession = null;
    }
  }

  private createNewConnection(userData: IAuthUser) {
    const signature = className + ".createNewConnection: ";

    const wsHost = apiHost.match(/^http:\/\//) ? apiHost.replace(/^https?/, 'ws') : apiHost.replace(/^https?/, 'wss');
    const wsUrl = `${environment.wsEndPoint}/notifications/${userData.id}`;

    logger.silly(signature + `Creating Connection to ${wsUrl}`);
    const newConnection = webSocket(wsUrl);

    newConnection.pipe(
      tap(() => this._lastKnownMessage = new Date().getTime()),
      tap(() => this._pingCounter = 0)
    ).subscribe({
      next: (data: any) => {
        if ('message' in data && typeof data['message'] === 'string') {

          switch (String(data['message'])) {
            case 'SESSION_ID':
              const sessionData: { message: string, id, key } = data;
              if (!('id' in sessionData)) {
                logger.error(signature + `Expected Key[id] in Data for Message[SESSION_ID]`);
                return;
              }
              if (!('key' in sessionData)) {
                logger.error(signature + `Expected Key[key] in Data for Message[SESSION_ID]`);
                return;
              }

              this._connectionSession = sessionData;
              logger.info(signature + `Got Session[${sessionData.id}]`);
              return;
            case 'Your report is ready':
              const reportMessage: { message: string, data: string } = data;
              let prettyData = reportMessage.data.replace(/(https?:\/\/[^\s]+)\/([^\s]+)/gi, '<a href="$1/$2">$2</a>');
              this.modalService.open(ModalComponent, {
                scrollable: true,
                size: 'sm',
                windowClass: 'bulk-order'
              }).componentInstance['data'] = prettyData;
              return;
          }
        }
      },
      error: (err) => {
				if( err instanceof CloseEvent ) {
					this.closeCurrentConnection();

					// Try to reconnect 10 seconds later
					setTimeout(() => {
						this.connectCurrentUser()
					}, 10000);
					return;
				}

				console.log(err);
        logger.error(signature + 'WebSocket error: ' + err.message);
      },
      complete: () => {
        logger.info(signature + 'WebSocket connection completed.');
				newConnection.unsubscribe();
      }
    });
    this._connectedUser = userData;

    logger.info(signature + `Opening new connection for User[${this._connectedUser.id}]`);
    this.connection$.next(newConnection);
  }
}
