import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFavoriteProduct } from '../model/favorites.model';

// Convenience Type
type T = IFavoriteProduct;

@Injectable()
export class FavoritesProductApi extends GenericApi<T> {
  public path = "favorites/product";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
