import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from './environments/environment';
import { logger } from './app/main/util/Logger';
import { AppModule } from './app/main/app.module';

if (environment.enableSentry) {
	Sentry.init(
		{
			dsn: "https://0d582f56aa71f75728a7affaa9e5cfda@o135869.ingest.us.sentry.io/4507321487065088",
			environment: environment.production ? 'Production' : 'Development',
			release: environment.buildId,
			integrations: [
				new Sentry.BrowserTracing({
					tracePropagationTargets: ["localhost", environment.endpoint],
					routingInstrumentation: Sentry.routingInstrumentation,
				}),
			],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 0.05,
			// Session Replay
			replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		});
}

logger.info(`Starting Application. BuildId[${environment.buildId}]`);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.then(ref => {
		// Ensure Angular destroys itself on hot reloads.
		if (window['ngRef']) {
			window['ngRef'].destroy();
		}
		window['ngRef'] = ref;

		// Otherwise, log the boot error
	})
	.catch(err => console.error(err));
