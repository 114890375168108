import { IAuthCustomerUser } from "./customer.user.model";
import { IUserGroup } from "./group.model";
import { IRole } from "./role.model";
import { IShippingDetails, ShippingDetails } from "./shippingDetail.model";
import { IUserAllocation } from "./userAllocation.model";

export interface INewUser {
	id?: Number;
	firstName: string;
	lastName: string;
	secureLoginType: string;
	email: string;
	contactNumber: string | null;
	jobTitle: string;
	isActive?: boolean;
	isAdmin?: boolean;
	createdAt?: string;
	updatedAt?: string;
	lastLoginAt?: string | null;
	shippingDetailsId?: number | null;
	customerUsers?: Array<any> | null;
	shippingDetails?: IShippingDetails | null;
}

export interface IAuthUser {
	id: number;
	firstName: string;
	lastName: string;
	secureLoginType?: string;
	email: string;
	contactNumber: string | null;
	isActive: boolean;
	isAdmin: boolean;
	createdAt: string;
	updatedAt: string;
	lastLoginAt: string | null;
	shippingDetailsId: number | null;
	customerUsers: Array<IAuthCustomerUser> | null;
	shippingDetails: IShippingDetails | null;
	adminRoleId: number | null;
	adminRole: IRole | null;
	userRole?: IRole | null;
}

export class User {
	/** Dangerous property, avoid using where possible, and rely on actualUserID */
	UserID: string;
	actualUserId: number;
	enableOrdersRequireApproval: Boolean = false;
	CustomerID: number;
	firstName: string;
	lastName: string;
	SecureLoginType: string;
	email: string;
	ContactNumber: number | string;
	id?: number;
	ShippingDetails: ShippingDetails = new ShippingDetails();

	//ID of the cost center attached to the user
	CostCenter: string;
	// Orders over this amount go to the approvals manager
	ApprovalLimit: number | string;

	Role: string | null;
	IsAdmin = false;
	lastLoginAt: Date | null = null;
	BusinessUnit: any;
	IsGuest = false;
	adminRole: IRole | undefined
	/**
	 * Properties associated with customerUser only
	 */
	CostAccount: string;
	EmployeeNumber: string;

	/** 1:1 Property Mapping */
	approverId: number | null;
	businessUnitId: number;
	roleId: number;
	jobTitle: string;
	startDate: string; // CustomerUser Only
	actual: INewUser | null;
	adminRoleId: number | null;
	permissions: string;
	userAllocations: IUserAllocation[] = [];
	userGroups: IUserGroup[] = [];
	loginSecretToken: string | null;
}	