import { Component } from '@angular/core';
import { IRole } from '../../model/role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { AdminRoleService } from '../../services/adminRole.service';
import { NotificationsService } from 'angular2-notifications';
import { LoadingService } from '../../services/core/loading.service';


type Permission = {
  description: string;
  permission: string;
  checked?: boolean;
  implies?: string[];
}
type PermissionOptions = {
  [categoryLabel: string]: Permission[]
};

@Component({
  selector: 'app-admin-role-edit',
  templateUrl: './admin-role-edit.component.html',
  styleUrls: []
})
export class AdminRoleEditComponent {
  getOptionName = (category: string, option: Permission): string => `${category}-option-${option.permission}`;

  /**
   * General
   */
  model: IRole = {
    name: '',
    permissions: '',
    customers: [],
    accessMode: 0,
    id: 0
  };

  readonly options: PermissionOptions = {
    Products: [
      { permission: "manage_products", description: "Product : Add/Edit" },
    ],
    Categories: [
      { permission: "manage_categories", description: "Categories : Add/Edit" },
    ],
    Decorations: [
      { permission: "manage_decorations", description: "Decorations : Add/Edit" },
    ],
    Groups: [
      { permission: "manage_groups", description: "Groups : Add/Edit" },
    ],
    Brands: [
      { permission: "manage_brands", description: "Brands : Add/Edit" },
    ],
    Size_Charts: [
      { permission: "manage_sizecharts", description: "Size Charts : Add/Edit" },
    ],
    Collections: [
      { permission: "manage_collections", description: "Collections : Add/Edit" },
    ],
    Customers: [
      { permission: "manage_customers", description: "Customers : Add/Edit" },
      { permission: "switch_users", description: "Customers : Switch Users" },
      { permission: "place_order", description: "Customers : Place Orders on behalf of users" },
    ]

  }

  readonly optionCategories = Object.keys(this.options).filter(key => !!this.options[key].length);

  constructor(
    public route: ActivatedRoute,
    private adminRoleService: AdminRoleService,
    public router: Router,
    private notifications: NotificationsService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (has(params, 'id')) {
        this.model.id = params.id;

        this.loadModel(this.model.id);
      }
    });
  }

  /**
 * @description Loads the existing product data from the database
 */
  loadModel(id: number) {
    this.adminRoleService.get(id)
      .subscribe((model: IRole) => {
        this.model = model;
        this.afterLoad();
      });
  }

  /**
 * @description The opposite of beforeSave - Performs any pre-possing that needs to be done on the model after it is loaded
 */
  afterLoad() {
    console.log(this.model.permissions, "This is the permission")
    const permissionCodes = (this.model.permissions || "").split(",");
    const allCategories = this.optionCategories.map(category => this.options[category]);
    const allPermissions: Permission[] = allCategories.reduce((acc, val) => acc.concat(val), []);

    permissionCodes.forEach(code => {
      const foundPermission = allPermissions.find(obj => obj.permission === code);
      if (!!foundPermission) {
        foundPermission.checked = true;
      }
    });
  }

  /**
   * @description Does any pre-processing that needs to be done on the model before it is saved.
   * Primarily, grants downstream permissions associated with any other permission
   */
  beforeSave() {
    const selectedPermissions: any = this.optionCategories
      .map(key => this.options[key].filter(option => !!option.checked))
      .reduce((accumulator: Permission[], val) => {
        console.log(accumulator, val);
        accumulator.push(...val);
        return accumulator;
      }, [] as Permission[]);

    const impliedPermissions: any = selectedPermissions
      .filter(permission => permission.implies)
      .map(permission => permission.implies)
      .reduce((accumulator: string[], val) => {
        accumulator.push(...val);
        return accumulator;
      }, [] as string[]);

    this.model.permissions = []
      .concat(...selectedPermissions.map(permission => permission.permission))
      .concat(...impliedPermissions)
      .reduce((accumulator: string[], val) => {
        if (accumulator.indexOf(val) === -1) {
          accumulator.push(val);
        }
        return accumulator;
      }, [] as string[])
      .join(",");
  }

  /**
 * @description Validate and persist the product in the server, ignoring validating for Draft Products
 */
  saveModel() {
    if (this.model.name.trim().length < 3 || this.model.name.trim().length >= 50) {
      this.notifications.error('Error', 'Admin Role name must be between 3 and 50 characters long.');
      return;
    }
    this.beforeSave();
    const handler = this.model.id ? this.loadingService.blockWithLoadingOverlayRx(this.adminRoleService.update(this.model.id, this.model)) : this.loadingService.blockWithLoadingOverlayRx(this.adminRoleService.create(this.model));

    handler.subscribe(() => {
      if (!this.model.id) {
        this.router.navigate(['/manage/adminRoles']);
      }
    },
      err => {
        console.error(err);
      }
    );
  }
}
