import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminService } from '../services/admin.service';
import { SecurityService } from '../services/security.service';
import { CustomerService } from '../services/customer.service';
import { IQueryFilter } from '../model/query.filter.class';
import { CustomerMergeService } from '../services/customerMerge.service';
import { SelectOption } from 'src/app/types';
import { CustomerSelectComponent } from '../customers/customer-action/customer-select/customer-select.component';

@Component({
  selector: 'app-manage-system',
  templateUrl: './manage-system.component.html',
  styleUrls: []
})
export class ManageSystemComponent {
	@ViewChild('sourceCustomerSelect')
	sourceCustomerSelect: CustomerSelectComponent;

	@ViewChild('destCustomerSelect')
	destCustomerSelect: CustomerSelectComponent;

  public syncProductId: number = 0;
  public serverMessage: string = "";
  public serverMessageSeverity: string = "";
  public loadedMessage = false;
  public isSuperAdmin$: Observable<boolean | null>;
  public customerSelectOptions: { id: string, text: string }[];
  public sourceCustomer: string;
  public destinationCustomer: string;

  public allOptions = [{
    id: "disabled",
    text: "Disabled"
  }, {
    id: "primary",
    text: "Primary"
  }, {
    id: "secondary",
    text: "Secondary"
  }, {
    id: "success",
    text: "Success"
  }, {
    id: "danger",
    text: "Danger"
  }, {
    id: "warning",
    text: "Warning"
  }, {
    id: "info",
    text: "Info"
  }, {
    id: "light",
    text: "Light"
  }, {
    id: "dark",
    text: "Dark"
  }];

  constructor(
    private adminService: AdminService,
    private securityService: SecurityService,
    private customerService: CustomerService,
    private customerMergeService: CustomerMergeService
  ) { }


  ngOnInit() {

    this.adminService.getServerMessage().subscribe(result => {
      this.serverMessage = result.message;
      this.serverMessageSeverity = result.severity;
      this.loadedMessage = true;
    });

    this.isSuperAdmin$ = this.securityService.isSuperAdmin();
    this.fetchCustomer();
  }

  public executeEmployeeAllocations() {

    this.adminService.processAllocations().toPromise()
      .then(() => { })
      .catch(err => {
        let errMsg: String = "Unknown";

        if (Array.isArray(err) && err.length && err[0].hasOwnProperty('message'))
          errMsg = err[0].message;
        else
          console.log(err);

      })
  }

  public syncDiscountRates() {

    this.adminService.syncDiscountRates().toPromise()
      .then(() => { })
      .catch(err => {
        let errMsg: String = "Unknown";

        if (Array.isArray(err) && err.length && err[0].hasOwnProperty('message'))
          errMsg = err[0].message;
        else
          console.log(err);
      })
  }

  public syncProductPrice(productId?: number) {
    this.adminService.syncProductPrice(productId).toPromise()
      .then(() => { })
  }

  public setServerMessage() {
    this.adminService.setServerMessage(this.serverMessage, this.serverMessageSeverity).toPromise()
      .then(() => { });
  }

  public fetchCustomer() {
    this.customerService.list(new IQueryFilter({
      limit: 10,
      sortBy: 'name'
    }))
      .subscribe((result) => {

        this.customerSelectOptions = [
          ...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }))
        ];
      });
  }

  public prepareCustomerMerge() {
    this.customerMergeService.create({
      sourceCustomerId: +this.sourceCustomer,
      destinationCustomerId: +this.destinationCustomer
    }).subscribe(() => {
			this.sourceCustomerSelect.clear();
			this.destCustomerSelect.clear();
		})
  }

	public updateSourceCustomer(data: SelectOption[]) {
		if( !data.length ) {
			this.sourceCustomer = '';
			return;
		}
		this.sourceCustomer = data[0].id;
	}

	public updateDestinationCustomer(data: SelectOption[]) {
		if( !data.length ) {
			this.destinationCustomer = '';
			return;
		}
		this.destinationCustomer = data[0].id;
	}
}
