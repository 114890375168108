import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from "rxjs";
import { catchError, delayWhen, filter, map, switchMap, tap } from "rxjs/operators";
import { CustomerDomainApi } from "../api/customerDomain.api";
import { logger } from "../util/Logger";
import { Utils } from "../util/utils";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { NavigationStart, Router } from "@angular/router";

/**
 * The purpose of the SSOService is to handle all the relevant functionality associated with SSO Auth into the application.
 * It should trigger any necessesary downstream functionality required to complete an authentication
 */

export const blankSSOConfig = {
	ssoType: 'none' as 'none'
};

export type ValidCognitoConfig = {
	ssoType: 'cognito_user_pool';
	cognitoUserPoolId: string;
	cognitoClientId: string;
	cognitoAuthDomain: string;
	cognitoProvider: string;
};

export type SSOConfig = ValidCognitoConfig | typeof blankSSOConfig;

export type CognitoSSOSession = {
	ssoType: 'cognito_user_pool';
	jwtToken: string;
}

export type SSOSession = CognitoSSOSession;

export enum SSOState {
	// Initial state - Configuration is not yet enabled
	NOT_CONFIGURED,
	// Currently determining the SSO Options
	LOADING,
	// Determined SSO is not available
	NOT_AVAILABLE,
	// Determined SSO is available and is ready for interaction
	READY,
}

const className = "SSOService";

@Injectable()
export class SSOService {

	private ssoConfig: SSOConfig | null = null;
	public ssoData: BehaviorSubject<SSOSession | null> = new BehaviorSubject(null);
	public ssoStateSubject: BehaviorSubject<SSOState | null> = new BehaviorSubject(SSOState.NOT_CONFIGURED);

	// Tracks if the auth service is currently monitoring for auth requests
	public authRequestMonitor: boolean = false;

	public constructor(
		public utils: Utils,
		private readonly customerDomainApi: CustomerDomainApi,
		public readonly httpClient: HttpClient,
		public storageService: CookieService,

		// For this to work, the following should be added to the providers for the app. This is required to auth with cognito after sso
		// { provide: 'initialHref', useValue: window.location.href }
		@Inject('initialHref') public initialHref: string
	) {
		this.prepareSSO().subscribe();
	}

	/**
	 * @description Reliably obtains any neccesesary permissions, external packages and configurations for external SSO on the current browser domain. Should only be called once
	 * @returns { Observable<SSOConfig> }
	 */
	private prepareSSO(): Observable<SSOConfig> {
		const signature = className + ".prepareSSO: ";
		logger.silly(signature + `Preparing SSO Config`);
		this.ssoStateSubject.next(SSOState.LOADING);

		return this.getSSOConfig().pipe(
			switchMap(config => {
				if (config.ssoType === "cognito_user_pool") {

					// This guarantees that after cognito is installed the session should be present when the the cofig reaches the subscriber
					return this.installCognito(config).pipe(
						catchError(err => {
							this.ssoConfig = blankSSOConfig;
							return throwError(() => err);
						}),
						switchMap(() => this.getCurrentCognitoToken()),
						map(token => {
							if (token) {
								this.ssoData.next({
									ssoType: "cognito_user_pool",
									jwtToken: token
								});
							}
						}),
						tap(() => this.ssoStateSubject.next(SSOState.READY)),
						map(() => this.ssoConfig || blankSSOConfig)
					);
				}

				this.ssoStateSubject.next(SSOState.NOT_AVAILABLE);

				return of(this.ssoConfig || blankSSOConfig);
			}),
		);
	}

	/**
	 * @description Installs AWS Cognito
	 * @param config
	 * @returns
	 */
	private installCognito(config: ValidCognitoConfig): Observable<boolean> {
		return this.utils.appendExternalScript('https://unpkg.com/aws-amplify@4.3.45/dist/aws-amplify.js').pipe(
			filter(val => val),
			tap(() => {
				const Amplify = window['aws_amplify'] && window['aws_amplify'].Amplify;

				Amplify.configure({
					Auth: {

						// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
						// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

						// REQUIRED - Amazon Cognito Region
						region: 'ap-southeast-2',

						// OPTIONAL - Amazon Cognito Federated Identity Pool Region
						// Required only if it's different from Amazon Cognito Region
						identityPoolRegion: 'ap-southeast-2',

						// OPTIONAL - Amazon Cognito User Pool ID
						userPoolId: config.cognitoUserPoolId,

						// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
						userPoolWebClientId: config.cognitoClientId,

						// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
						mandatorySignIn: true,

						// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
						// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
						signUpVerificationMethod: 'code', // 'code' | 'link'

						// OPTIONAL - Configuration for cookie storage
						// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
						cookieStorage: {
							// REQUIRED - Cookie domain (only required if cookieStorage is provided)
							domain: window.location.host,
							// OPTIONAL - Cookie path
							path: '/',
							// OPTIONAL - Cookie expiration in days
							expires: 365,
							// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
							//sameSite: "strict" | "lax",
							sameSite: "strict",
							// OPTIONAL - Cookie secure flag
							// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
							secure: true
						},

						// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
						// authenticationFlowType: 'USER_PASSWORD_AUTH',

						// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
						// clientMetadata: { myCustomKey: 'myCustomValue' },

						// OPTIONAL - Hosted UI configuration

						oauth: {
							domain: config.cognitoAuthDomain,
							scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
							redirectSignIn: window.location.origin,
							redirectSignOut: window.location.origin,
							responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
						}
					}
				});
			})
		);
	}

	/**
	 * @description Reliably determines if the current user is authenticated and has a valid auth token
	 * @returns { Observable<string | null> }
	 */
	private getCurrentCognitoToken(): Observable<string | null> {
		const signature = className + ".getCurrentCognitoToken: ";
		const Amplify = window['aws_amplify'] && window['aws_amplify'].Amplify;

		if (!Amplify) {
			return throwError(() => new Error("Amplify is not available"));
		}

		let seed: Observable<unknown> = of(true);
		const sso_cookie = this.storageService.get("PERFORM_SSO");
		if (sso_cookie && sso_cookie === 'true' && this.initialHref.match(/code=[a-zA-Z0-9-]+/) && this.initialHref.match(/state=[a-zA-Z0-9]+/)) {
			// Removes the cookie ensuring the SSO is only performed once
			this.storageService.delete("PERFORM_SSO");

			return from(Amplify.Auth._oAuthHandler.handleAuthResponse(this.initialHref) as Promise<{ accessToken?: string }>).pipe(
				map(data => {
					if (data && data.accessToken && data.accessToken.length) {
						return data.accessToken;
					}

					return null;
				})
			);
		}

		return of(null);
		/*
		return seed.pipe(
			map(data => {
				console.log("------data",data);

				return data;
			}),
			switchMap(() =>
				from(Amplify.Auth.currentAuthenticatedUser({ bypassCache: true }))
			),
			catchError(err => {
				if (typeof err === 'string' && err.match(/not authenticated/i)) {
					logger.silly(signature + `User is not currently authenticated`);
					return of(null);
				}

				return throwError(() => err);
			}),
			switchMap((user: any) => {
				if (!user) {
					return of(null);
				}

				if (!('getSignInUserSession' in user)) {
					return throwError(() => new Error("user did not contain method getSignInUserSession"));
				}

				if (typeof user['getSignInUserSession'] !== 'function') {
					return throwError(() => new Error("user.getSignInUserSession is not a function"));
				}

				const session = user.getSignInUserSession() as object;

				if (typeof session !== 'object') {
					return throwError(() => new Error("session is not an object"));
				}

				if (!('accessToken' in session)) {
					return throwError(() => new Error("session did not contain property accessToken"));
				}

				if (typeof session['accessToken'] !== 'object') {
					return throwError(() => new Error("session.accessToken is not an object"));
				}

				const accessToken = session['accessToken'] as object;

				if (!('jwtToken' in accessToken)) {
					return throwError(() => new Error("accessToken did not contain property jwtToken"));
				}

				if (typeof accessToken['jwtToken'] !== 'string') {
					return throwError(() => new Error("session is not a string"));
				}

				return of(accessToken['jwtToken']);
			})
		);
		*/
	}

	/**
	 * @description Initiates SSO or throws an error indicating that no SSO could be initiated
	 * @returns {Observable<void>}
	 */
	public initiateSSO(): Observable<void> {
		if (!this.ssoConfig) {
			return throwError(() => new Error("No SSO Config Available"));
		}

		if (this.ssoConfig.ssoType === blankSSOConfig.ssoType) {
			return throwError(() => new Error("No SSO Config Available"));
		}

		if (this.ssoConfig.ssoType === "cognito_user_pool") {
			const Amplify = window['aws_amplify'] && window['aws_amplify'].Amplify;

			if (!Amplify) {
				return throwError(() => new Error("Amplify is not available"));
			}

			this.storageService.set("PERFORM_SSO", 'true');
			Amplify.Auth.federatedSignIn({ provider: this.ssoConfig.cognitoProvider });
			return of();
		}

		return throwError(() => new Error("No SSO Strategy"));
	}

	/**
	 * @description Reliably obtains any neccesesary permissions or external SSO on the current browser domain
	 * @returns { Observable<SSOConfig> }
	 */
	public getSSOConfig(): Observable<SSOConfig> {
		const signature = className + ".getSSOConfig: ";

		if (this.ssoConfig) {
			return of(this.ssoConfig);
		}

		const hostName = window.location.hostname;
		const subdomain = hostName.toLowerCase().replace(environment.baseDomain.toLowerCase(), '');
		const wwwDomain = hostName.toLowerCase().replace('www.' + environment.baseDomain.toLowerCase(), '');

		if (!subdomain || !wwwDomain) {
			if (this.ssoConfig) {
				this.ssoConfig = null;
			}

			return of(blankSSOConfig);
		}

		logger.debug(signature + `Fetching Domain Config by Domain[${hostName}]`);
		return this.customerDomainApi.getByReferer(hostName).pipe(
			catchError(err => {
				if (err.errorCode === 4047) {
					logger.silly(signature + 'No domain config available');
					return of<null>(null);
				}

				logger.error(signature + 'Uncaught Exception fetching SSO Config');
				logger.error(err);

				return of<null>(null);
			}),
			map(result => {
				if (result) {
					const requiredFields: (keyof ValidCognitoConfig)[] = [
						"cognitoAuthDomain",
						"cognitoClientId",
						"cognitoProvider",
						"cognitoUserPoolId"
					];

					const missingFields = requiredFields.filter(field => !result[field]);

					if (missingFields.length) {
						if (missingFields.length !== requiredFields.length) {
							logger.info(signature + `Missing Cognito auth Fields[${missingFields.join(",")}]`);
						}

						return blankSSOConfig;
					} else {
						logger.info(signature + `External SSO Config Detected[User_Pool]`);

						return {
							ssoType: 'cognito_user_pool' as 'cognito_user_pool',
							cognitoUserPoolId: result.cognitoUserPoolId!,
							cognitoClientId: result.cognitoClientId!,
							cognitoAuthDomain: result.cognitoAuthDomain!,
							cognitoProvider: result.cognitoProvider!,
						}
					}
				}

				logger.silly(signature + 'No External SSO');

				return blankSSOConfig;
			}),
			tap(config => this.ssoConfig = config)
		);
	}
}
