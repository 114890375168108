import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnleashedCustomer } from 'src/app/main/model/unleashed.model';
import { CustomerService } from 'src/app/main/services/customer.service';

@Component({
  selector: 'app-view-customer',
  templateUrl: './customer-action-tab.component.html',
  styleUrls: []
})
export class ViewCustomerComponent implements OnInit {
  public customerGuid: string;
  public customer: UnleashedCustomer;
  public count: UnleashedCustomer;
  public activeTabIdx: number = 0;

  constructor(
    public route: ActivatedRoute,
    private customerService: CustomerService
  ) { }

  getTabs(): string[] {
    return ['Overview', 'Users', 'Products', 'Addresses', 'Document'];
  }


  ngOnInit() {
    const activeTab = this.route.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
    }
    this.route.params.subscribe(params => {
      this.customerGuid = params['customerId'];
      if (this.customerGuid) {
        this.customerService.getByIdCount(this.customerGuid)
          .subscribe((customer) => {
            this.count = customer;
            const convertCustomer = this.customerService.convertToCustomer(customer);
            this.customer = Object.assign(new UnleashedCustomer(), convertCustomer);
          });
      }
    });
  }
}
