import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-tab',
  templateUrl: './order-tab.component.html',
  styleUrls: []
})
export class OrderTabComponent implements OnInit {
  @Input() isEmploye: boolean;
  public activeTabIdx: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const activeTab = this.activatedRoute.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
    }
  }

  getTabs(): string[] {
    return ['All', 'Processing', 'Completed', 'Backordered', 'Pending Approval', 'Deleted', 'Rejected'];
  }

  onTabChange(event: number): void {

    this.activeTabIdx = event;
  }

}
