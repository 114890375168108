import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { BusinessUnitApi } from '../api/businessUnit.api';
import { IBusinessUnit } from '../model/businessUnit.model';
import { HasId } from '../model/generics';
import { IMightHaveShippingDetails } from '../model/shippingDetail.model';

@Injectable()
export class BusinessUnitService {
  constructor(
    private notifications: NotificationsService,
    private businessUnitApi: BusinessUnitApi
  ) {
  }


  public list(query: IQueryFilter): Observable<any> {
    return this.businessUnitApi.list(query);
  }

  public get(id: number): Observable<IBusinessUnit & HasId> {
    return apiCallWrapper(
      this.businessUnitApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Specific Business Units"
      }
    )
  }

  public create(data: IBusinessUnit): Observable<IBusinessUnit & HasId> {
    return apiCallWrapper(
      this.businessUnitApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Creating Business Units"
      }
    )
  }

  public update(id: number, data: IBusinessUnit): Observable<IBusinessUnit & HasId> {
    return apiCallWrapper(
      this.businessUnitApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Updating Business Units"
      }
    )
  }

  public getBusinessUnits(customerId: number) {
    return this.businessUnitApi.getBusinessUnitsList(customerId) as any as Observable<(IBusinessUnit & IMightHaveShippingDetails)[]>;
  }
}
