import { Injectable } from '@angular/core';
import { ItemsGenericService } from './items-genric.service';
import { CustomerApi } from '../api/customer.api';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerUserRoleService extends ItemsGenericService<CustomerUserRoleService> {
  
  protected dataFetcher(): Observable<any> {
    return this.customerApi.getCustomerUserRolesList();
  }
  constructor(private customerApi: CustomerApi) {
    super();
  }

}
