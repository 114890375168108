<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">Email Tester</h1>
		</div>
		<div class="manage-user-list">
			<div class="edit-users">
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M4 4H16C16.825 4 17.5 4.675 17.5 5.5V14.5C17.5 15.325 16.825 16 16 16H4C3.175 16 2.5 15.325 2.5 14.5V5.5C2.5 4.675 3.175 4 4 4Z"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 5.5L10 10.75L2.5 5.5"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Details
							</h4>
						</div>
						<div class="card-body">
							<form action="">
								<div class="row">
									<div class="col-12">
										<div class="form-group">
											<label for="Destination" class="form-label"
												>Destination Email Address</label
											>
											<input
												type="email"
												class="form-control"
												id="Destination"
												placeholder="Email address where the test should be sent"
												name="email"
												[(ngModel)]="model.emailAddress"
											/>
										</div>
									</div>
									<div class="col-12">
										<h4 class="form-title">target test email</h4>
									</div>

									<div
										class="col-12 col-lg-7"
										*ngFor="let test of testEmailOptions"
									>
										<div class="form-group form-check">
											<input
												[id]="'test-email-' + test.value"
												type="radio"
												name="testEmail"
												[value]="test.value"
												[(ngModel)]="model.testEmail"
												class="form-check-input"
											/>
											<label
												class="form-check-label"
												[for]="'test-email-' + test.value"
												>{{ test.label }}
											</label>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="mt-3">
						<button
							class="btn btn-primary w-sm-100"
							type="button"
							(click)="saveModel()"
						>
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="white"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>
								</svg>
							</span>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>
