import { Component, EventEmitter, Input, Output } from '@angular/core';

type ScopeOption = {
  text: string;
  scope: string;
};
type ScopeOptions = ScopeOption[];


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: []
})
export class SearchComponent {
  @Input() sortOptions: any[]; // Assuming sortOptions is an array of objects with field and text properties
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() sortChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() scopeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input() sortText: string | undefined;
  @Input() scopeText: string | undefined;
  @Input() isScope: boolean | undefined;

  readonly scopeOptions: ScopeOptions = [{
    text: "Exclude Archived (Default)",
    scope: "ExcludeArchived"
  }, {
    text: "Include Archived",
    scope: "IncludeArchived"
  }, {
    text: "Archived Only",
    scope: "ArchivedOnly"
  }]

  constructor() { }

  updateSearchTerm(searchTerm: string): void {
    this.searchChanged.emit(searchTerm);
  }

  updateSortField(sortField: string): void {
    this.sortChanged.emit(sortField);
  }

  updateScopeField(scope: string) {
    this.scopeChanged.emit(scope);
  }
}
