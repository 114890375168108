<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/adminRoles']">Admin roles</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ model.id ? "Edit" : "Add New" }} admin role
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{ model.id ? "Edit" : "Add New" }} admin role</h1>
		</div>
		<div class="manage-user-list">
			<div class="edit-users">
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
											fill="white"
										/>
										<path
											d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
											fill="white"
										/>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								General information
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="clientName" class="form-label">Name</label>
										<input
											type="text"
											class="form-control"
											id="clientName"
											placeholder="Enter role name here...."
											[(ngModel)]="model.name"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M12.6247 6.625L15.2497 4M16.7497 2.5L15.2497 4L16.7497 2.5ZM9.54222 9.7075C9.92947 10.0896 10.2373 10.5445 10.448 11.0461C10.6587 11.5477 10.7682 12.0859 10.77 12.63C10.7718 13.174 10.666 13.713 10.4587 14.216C10.2513 14.7189 9.94651 15.1759 9.56183 15.5606C9.17714 15.9453 8.72016 16.2501 8.21719 16.4574C7.71423 16.6648 7.17522 16.7706 6.63119 16.7688C6.08717 16.7669 5.54887 16.6575 5.04731 16.4468C4.54575 16.2361 4.09082 15.9283 3.70872 15.541C2.95732 14.763 2.54154 13.721 2.55094 12.6395C2.56034 11.5579 2.99416 10.5233 3.75897 9.7585C4.52377 8.99369 5.55838 8.55987 6.63994 8.55047C7.7215 8.54107 8.76348 8.95685 9.54147 9.70825L9.54222 9.7075ZM9.54222 9.7075L12.6247 6.625L9.54222 9.7075ZM12.6247 6.625L14.8747 8.875L17.4997 6.25L15.2497 4L12.6247 6.625Z"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Access
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div
									class="col-12 col-lg-10"
									*ngFor="let category of optionCategories"
								>
									<div
										class="form-group form-check"
										*ngFor="let option of options[category]"
									>
										<input
											[id]="getOptionName(category, option)"
											type="checkbox"
											[value]="1"
											[(ngModel)]="option.checked"
											class="form-check-input"
										/>
										<label
											[for]="getOptionName(category, option)"
											class="form-check-label"
											>{{ option.description }}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt-3">
						<button class="btn btn-primary w-sm-100" type="button" (click)="saveModel()">
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="white"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>
								</svg>
							</span>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>
