import { Component, Input } from '@angular/core';
import { UnleashedCustomer } from 'src/app/main/model/unleashed.model';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: []
})
export class ViewComponent {
  @Input()
  customer: UnleashedCustomer;

  constructor(){
  }
}
