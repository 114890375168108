import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';
import { ICustomerUser } from '../model/customer.user.model';
import { QueryResult } from '../model/query.filter.class';
import { IUserAllocation } from '../model/userAllocation.model';
import { IUserGroup } from '../model/group.model';
import { ICustomUserField } from '../model/custom.user.fileld.model';

// Convenience Type
type T = ICustomerUser;

@Injectable()
export class CustomerUserApi extends GenericApi<T> {
  public path = "customer-user";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getUsers = (params: HttpParams): Observable<QueryResult<ICustomerUser>> => {
    return this.httpClient.get<QueryResult<ICustomerUser>>(
      createUrl(this.path, 'all/users'),
      { params }
    );
  };

  saveCustomerUserAllocations = (customerUserId: number, userAllocations: IUserAllocation[]) => {
    return this.httpClient.put<any>(
      createUrl(this.path, customerUserId, 'allocations'),
      userAllocations
    );
  }

  saveCustomerUserGroups = (customerUserId: number, userGroups: IUserGroup[] | undefined) => {
    return this.httpClient.put<any>(
      createUrl(this.path, customerUserId, 'groups'),
      userGroups
    );
  }

  generateTfaSecret = (data: string): Observable<{ qr: string, secret: string, uri: string }> => {
    return this.httpClient.post<any>(
      createUrl(this.path, 'generate_tfa_secret'),
      { "email": data }
    );
  };

  verifyOtp = (data: any): Observable<any> => {
    return this.httpClient.post<any>(
      createUrl(this.path, 'verify_otp'),
      data
    );
  }

  public importUser(data: { customerId: number | string, fileContent: string }) {
    return this.httpClient.post<any>(
      createUrl(this.path, 'import_user'),
      data
    );
  }

  public getCustomersUsers = (params: HttpParams): Observable<QueryResult<ICustomerUser>> => {
    return this.httpClient.get<QueryResult<ICustomerUser>>(
      createUrl(this.path),
      { params }
    );
  };

  public getFields(customerUserId: number | string) {
    return this.httpClient.get<ICustomUserField[]>(
      createUrl(this.path, customerUserId, 'userField')
    );
  };

  public postFieldValues(model: any, id: number | string): Observable<any> {
    return this.httpClient.post<any>(
      createUrl(this.path, id, 'userFieldValue'),
      model
    );
  };

}
