import { Injectable } from '@angular/core';
import { Moment } from 'moment-mini';
import * as moment from 'moment-mini';
import { Observable, of } from 'rxjs';
import { logger } from './Logger';

const className = "Utils";

@Injectable()
export class Utils {
  uniqueArray = (arr: Array<any>) => {
    let a = arr.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) {
          a.splice(j--, 1);
        }
      }
    }

    return a;
  };

  // prettyDate = (date: Date | Moment | null, format: string = "DD/MM/YYYY HH:mm") => moment(date).format(format);

  toUrlFormat = (str: string) => {
    if (!str) { return; }

    if (str.toLowerCase() === 'ppe') { return 'PPE'; }

    return str
      .toLowerCase()
      .replace(/\s/g, '_')
      .replace(/'/, '')
      ;
  };

  fromUrlFormat = (str: string) => {
    if (!str) { return; }

    if (str.toLowerCase() === 'ppe') { return 'PPE'; }

    return str
      .replace(/_/g, ' ')
      .replace(/(\b\w)/gi, function (m) { return m.toUpperCase(); })
      ;
  };

  fromCamelCase = (str: string) => {
    if (!str) { return; }

    if (str.toLowerCase() === 'ppe') { return 'PPE'; }

    return str
      .replace(/([a-z])ID$/g, '$1 id')
      .replace(/([A-Z])/g, ' $1')
      .replace(/\sid$/g, ' ID')
      .replace(/^./, function (replaced) { return replaced.toUpperCase(); })
      ;
  };

  getEnumByUrlKey = (e: any, key: string) => {
    let currentDepth = 0;

    if (!e) { return null; }

    for (let i = 0; i < e.length; i++) {
      if (this.toUrlFormat(e[i].key) === key) {
        return e[i];
      }
    }

    return null;
  };

  enableCSS = (...name: string[]) => {
    let cssLink
    name.forEach(n => {
      cssLink = this.getObjectById(n);

      if (!cssLink) {
        console.error('ERROR Getting Object by ID[' + n + ']');
      } else {
        cssLink.removeAttribute('disabled');
      }
    })
  };

  disableCSS = (...name: string[]) => {
    let cssLink
    name.forEach(n => {
      cssLink = this.getObjectById(n);

      if (!cssLink) {
        console.error('ERROR Getting Object by ID[' + n + ']');
      } else {
        cssLink.setAttribute('disabled', null);
      }
    })
  };

  getObjectById = (id: string) => {
    let domId: string = '#' + id;

    return document.querySelector(domId);
  };

  roundToPlaces = (num, places = 2) => {
    let rounded = Math.round(num * Math.pow(10, places)) / Math.pow(10, places);
    return parseFloat(rounded.toFixed(places));
  };

  twoDecimalPlaces = (num) => {
    this.roundToPlaces(num, 2);
  };

  twoDecimalPlacesString = (num) => {
    if (!num) { return '0.00'; }

    let val = this.roundToPlaces(num, 2).toString();
    let decimalMatches = val.match(/\.[0-9]+$/);
    if (decimalMatches && decimalMatches.length > 0) {
      let decimal = decimalMatches[0];
      if (decimal.length < 3) { decimal += '0' }
      val = val.replace(/\.[0-9]+$/, decimal);
    } else {
      val += '.00';
    }

    return val;
  };

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE = () => {
    let ua = window.navigator.userAgent;

    // Test values; Uncomment to check result..

    // IE 10
    // 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      let rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    let edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  };

  expressions = {
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    CreditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/,
    CCV: /^[0-9]{3,4}$/,
    CCExpiryYear: /^20[0-9]{2}$/,
    CCExpiryMonth: /^0[0-9]|1[0-2]$/
  };

  hasEachOf = (target: object, ...properties: string[]) =>
    !properties.find(property => {
      const result = !target.hasOwnProperty(property);

      // console.log(`Property[${property}] Was Missing[${result}] In[${JSON.stringify(target)}]`);

      return result;
    });

  /**
   * @description Guarantees that an external script with the supplied url exists in the head of the application. Inserting it if it does not exist
   * @param {string} URL The url of the external script
   * @param {string} id The optional unique DOM id of the element being inserted
   * @returns {Observable<boolean>} A subscription that emits when the script has completed loading
   */
  appendExternalScript(url: string, id?: string): Observable<boolean> {
    const signature = className + `.appendExternalScript: Url[ ${url} ] `;
    const win = window as typeof window & { scriptLoaded?: string[] };
    if (!Object.prototype.hasOwnProperty.call(win, 'scriptLoaded')) {
      logger.silly(signature + 'Building load cache');
      win.scriptLoaded = [];
    }

    if (win.scriptLoaded?.includes(url)) {
      logger.debug(signature + `Already Loaded`);
      return of(true);
    }

    const existingScriptElement = document.querySelectorAll(`script[src="${url}"]`);

    if (existingScriptElement.length) {
      logger.debug(signature + `Already appended but has not completed loading`);

      return new Observable(sub => {
        existingScriptElement[0].addEventListener('load', () => {
          sub.next(true);
          sub.complete();
        });
      });
    }

    return new Observable(sub => {
      const script = document.createElement('script');
      script.type = 'text/javascript';

      if (id) {
        script.id = id;
      }

      script.src = url;
      script.onload = () => {
        logger.silly(signature + `Loaded Script`);
        win.scriptLoaded?.push(url);
        sub.next(true);
        sub.complete();
      }
      script.onerror = e => {
        logger.error(signature + `Script Load Error`);
        sub.error(e);
        sub.complete();
      }
      document.head.appendChild(script);
    });
  }
}

export class Promise {
  success: any = () => null;
  failure: any = () => null;

  then = (cb: () => null) => {
    this.success = cb;

    return this;
  };

  fail = (cb: () => null) => {
    this.failure = cb;

    return this;
  };
}
