import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDecorationDraftResult, NewDecoration } from '../model/ddb.model';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';

// Convenience Type
type T = NewDecoration;

@Injectable()
export class DecorationApi extends GenericApi<T> {
  public path = "decoration";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly getDecorationDraft = (id?: number):Observable<IDecorationDraftResult> => {
		const url = id ? createUrl(this.path,'draft', id) : createUrl(this.path,'draft');
    const result = this.httpClient.get<IDecorationDraftResult>(url);

    return result;
  };
}
