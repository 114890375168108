<section class="inhoused">
	<div class="max-width-container">
		<div class="container">
			<h1>Redefining Workwear Quality and Affordability</h1>
			<p>
				Reali proudly introduces Eureka Safety, our exclusive brand of uniforms.
				By manufacturing our own brand, we exercise precise control over the
				entire production process, ensuring top-notch quality while maintaining
				competitive pricing. Our vertically integrated supply chain, coupled
				with streamlined processes, allows for efficient material sourcing,
				manufacturing, and cost-effective distribution, enabling us to deliver
				high-quality workwear at compelling prices.
			</p>
		</div>
	</div>
</section>

<section class="inhousemids">
	<div class="max-width-container">
		<div class="container">
			<div class="parent">
				<div class="inner sec-a">
					<h2>Industry-leading fabrics Ripstop fabric</h2>
					<div class="img-box">
						<img src="../../../../../assets/images/inhimg1.svg" alt="" />
					</div>
				</div>
				<div class="inner sec-b">
					<img src="../../../../../assets/images/indus3.webp" alt="" />

					<div class="cons">
						Retail features and benefits included in contract ranges
					</div>
				</div>
				<div class="inner sec-c">
					<img src="../../../../../assets/images/indus2.webp" alt="" />

					<div class="cons">Modern Fit</div>
				</div>

				<div class="inner sec-d">
					<img src="../../../../../assets/images/indus4.webp" alt="" />

					<div class="cons">Vertical supply chain</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="inhousebtms">
	<div class="max-width-container">
		<div class="container">
			<div class="img-box">
				<img src="../../../../../assets/images/partners.svg" alt="" />
			</div>
			<h3>Uniqueness Redefined with Eureka Safety</h3>
			<p>
				Eureka Safety, our private brand, stands as a symbol of uniqueness
				within our product offering. Boasting a diverse range of over 30
				high-quality, customisable uniform products, Eureka Safety provides
				clients with a wide selection that meets their specific requirements and
				aligns seamlessly with their brand identity. Our commitment to stringent
				quality control measures ensures that every uniform under the Eureka
				Safety brand represents the epitome of quality, making it a distinctive
				choice for our valued clients.
			</p>
		</div>
	</div>
</section>
