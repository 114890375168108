<!-- ================================== -->
<!-- Shipping and Delivery Banner start here... -->
<!-- ================================== -->
<section class="inhoused">
	<div class="max-width-container">
		<div class="container">
			<h1>Shipping and Delivery</h1>
			<p>
				At Reali, we strive to provide efficient and transparent shipping and
				delivery services to all our valued customers. Below, you'll find
				information regarding our shipping fees, delivery methods, and tracking
				options.
			</p>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- Shipping and Delivery Banner end here... -->
<!-- ================================== -->

<!-- ================================= -->
<!-- Shipping and Delivery content start here... -->
<!-- ================================== -->
<section class="about-ship-delivery">
	<div class="max-width-container">
		<div class="container">
			<div class="conditions">
				<h2>Shipping Fees:</h2>
				<ul class="list-condition">
					<li>
						<p>
							<span class="fw-bold">Flat Rate Shipping:</span> All orders will
							incur a flat rate shipping fee of $20 ex gst, unless otherwise
							specified in a contractual agreement.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">Small Order Fee:</span>
							Orders placed below $250 (excluding GST) will be subject to a $20
							ex gst small order fee unless otherwise specified in a contractual
							agreement.
						</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Reasons for Small Order Fee:</h2>
				<p class="desc">
					We understand that any adjustment in pricing may raise questions.
					Here's why we implement the small order fee:
				</p>
				<ul class="list-condition">
					<li>
						<p>
							<span class="fw-bold">Competitive Pricing:</span> Our priority is
							to offer competitive pricing to all customers, regardless of order
							quantity.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">No Freight Charges:</span>
							We pride ourselves on transparent pricing, without any hidden
							freight or shipping costs.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">Nationwide Delivery:</span> We deliver
							orders across Australia, ensuring accessibility for all customers,
							irrespective of location.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">Covering Operational Costs:</span>
							The small order fee helps cover warehousing and labor costs
							associated with processing smaller orders, ensuring efficient
							service.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">Free Online Order Portal:</span>
							Enjoy the convenience of our user-friendly online order portal at
							no additional cost.
						</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Reasons for Flat Freight Fee:</h2>
				<p class="desc">
					Due to increased operational costs, we're adjusting our shipping fee
					structure to maintain service standards. Here's why:
				</p>
				<ul class="list-condition">
					<li>
						<p>
							<span class="fw-bold">Operational Costs:</span> Significant
							increases in operational expenses necessitate adjustments to our
							shipping fees.
						</p>
					</li>
					<li>
						<p>
							<span class="fw-bold">Service Standards:</span>
							The flat-rate freight fee ensures we can continue providing
							exceptional service without compromise.
						</p>
					</li>
				</ul>
				<p class="desc">
					We value transparency and welcome any questions or concerns you may
					have. Reach out to our Client Experience Team at
					<a href="mailto:sales@reali.au" role="button" class="btn btn-link"
						>sales@reali.au</a
					>
					or call us at 1300 107 233.
				</p>
			</div>
			<div class="conditions">
				<h2>Delivery Partners:</h2>
				<p class="desc">
					We utilise
					<a
						href="https://startrack.com.au/"
						target="_blank"
						role="button"
						class="btn btn-link"
						>StarTrack</a
					>
					and
					<a
						href="https://www.aramex.com.au/"
						target="_blank"
						role="button"
						class="btn btn-link"
						>Aramex</a
					>
					as our trusted national couriers.
				</p>
			</div>
			<div class="conditions">
				<h2>Tracking Your Order:</h2>
				<p class="desc">
					Upon placing an order, you'll receive a tracking number once your
					order is dispatched. Alternatively, if you placed your order through
					SUMS, you can check its status in your "Order History" tab.
				</p>
			</div>
			<div class="conditions">
				<h2>Track Your Order:</h2>
				<ul class="list-condition">
					<li>
						<p>
							<a
								href="https://startrack.com.au/"
								target="_blank"
								role="button"
								class="btn btn-link"
								>StarTrack</a
							>
						</p>
					</li>
					<li>
						<p>
							<a
								href="https://www.aramex.com.au/"
								target="_blank"
								role="button"
								class="btn btn-link"
								>Aramex</a
							>
						</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- Shipping and Delivery content end here... -->
<!-- ================================== -->
