import { inject } from "@angular/core";
import { IPermissionGuardPermission, PermissionsGuard } from "./guard/permissions.guard";
import { Observable } from "rxjs";
import { SecurityService } from "./services/security.service";

// Used to validate the permissions at compile time when writing them
const permission = (permissions: IPermissionGuardPermission[]) => permissions;

// A centralised location to define access guards for reuse throughout the application

const _routePermissions = {
	/*
	 * Example Configs
	 * { superAdmin:true } - User must be a super administrator - Highest level security
	 * { security: 'functionName' } - Will call the security service and look for 'functionName' then convert the result into a boolean. Use sparingly, for complex permission sets (eg: canCheckout)
	 * { adminRoles: 'manage_customers' } - User must be an administrator, with the role manage_customers
	 * { adminRoles: ['manage_customers','manage_users'] } - User must be an administrator, with either manage_customers, OR manage_users
	 * { adminRoles: ['manage_customers','manage_users'], mode: 'ALL' } - User must be an administrator, with both manage_customers, AND manage_users
	 * { userRoles: 'manage_users' } - User must be a customerUser with 'manage_users'
	 * { userRoles: ['edit_address','manage_users'], mode: 'ALL' } - User must be a customerUser, with both edit_address, AND manage_users
	 * { authenticated: true } - Lowest level of security - user must be authenticated
	 *
	 * If there are multiple config, the user will pass if ANY configs match the user
	 */
	'/manage/users': permission([{ superAdmin: true }, { adminRoles: 'manage_customers' }, { userRoles: 'manage_users' }]),
	'/manage/orders': permission([{ security: 'hasManageOrderAccess' }]),
	'/cart/checkout': permission([{ security: 'canCheckout' }]),
	'/admin-reports': permission([{ superAdmin: true }])
}

export const routePermissions = _routePermissions as unknown as Record<keyof typeof _routePermissions, IPermissionGuardPermission[]>;

export const routeSecurity = Object.keys(_routePermissions).reduce((acc, key, idx) => {
	acc[key] = [() => inject(PermissionsGuard).canActivate(routePermissions[key])];
	return acc;
}, {} as { [K in keyof typeof routePermissions]: (() => Observable<boolean>)[] });
