import { Injectable } from '@angular/core';
import { StorageServiceKey } from './storage.service';
import jwt_decode from 'jwt-decode';
import { IJWTPayload, IJWTPayloadDecoded } from '../model/auth.model';
import { logger } from '../util/Logger';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';

const className = "JwtService";

@Injectable()
export class JwtService {

  // currentJwtPayload should reflect the value currently stored in cookies and be used as a proxy for the current known JWT payload
  private currentJwtPayloadSubject = new BehaviorSubject<IJWTPayloadDecoded | null | undefined>(undefined);

	public currentJwtPayload$ = this.currentJwtPayloadSubject.asObservable().pipe(
		filter(init => init !== undefined ),
		distinctUntilChanged((oldVal, newVal) => JSON.stringify(oldVal) === JSON.stringify(newVal)),
		map(payload => payload as IJWTPayloadDecoded | null )
	);

  constructor(
    public storageService: CookieService
  ) {
		const signature = className + '.constructor: ';
		const jwt = this.decodeJWT();
		// Loads the last known JWT into the service on init, even if its null

		logger.silly(signature + 'Updating Payload');
		this.currentJwtPayloadSubject.next(jwt);
	}

  public saveJWTData(jwtPayload: IJWTPayload): boolean {
    const signature = className + ".saveJWTData: ";
    /**
     * Verify jwt before saving
     */
    const payload = this.decodeJWT(jwtPayload.accessToken);

    if (!payload) {
      logger.silly(signature + "Jwt was not valid and was rejected");
      return false;
    }

    const payloadVerified = this.verifyJWT(payload);

    if (!payloadVerified) {
      logger.silly(signature + "Jwt did not pass verification and was rejected");
      return false;
    }

    this.storageService.set(StorageServiceKey.jwt, jwtPayload.accessToken, 30, undefined, undefined, true, 'Strict');
    this.storageService.set(StorageServiceKey.jwt_refresh, jwtPayload.refreshToken, 30, undefined, undefined, true, 'Strict');
    this.storageService.set(StorageServiceKey.jwt_token_type, jwtPayload.tokenType, 30, undefined, undefined, true, 'Strict');

		logger.silly(signature + 'Setting Payload');
		this.currentJwtPayloadSubject.next(payload);

    return true;
  };

  public getJWTString(): string {
    return this.storageService.get(StorageServiceKey.jwt) || ''
  };

  public getJWTRefreshString(): string {
    return this.storageService.get(StorageServiceKey.jwt_refresh) || ''
  };

  public getJWTTypeString(): string {
    return this.storageService.get(StorageServiceKey.jwt_token_type) || ''
  };

  public decodeJWT(token?: string): IJWTPayloadDecoded | null {
    const signature = className + '.decodeJWT: ';
    const jwt = token || this.storageService.get(StorageServiceKey.jwt);
    let payload: IJWTPayloadDecoded;

    if (!jwt) {
      logger.silly(signature + 'JWT is null');
      return null;
    }

    try {
      logger.silly(signature + `Decoding JWT[${jwt}]`);
      payload = jwt_decode(jwt);
    } catch (e) {
      logger.silly(signature + 'Decoding Error');
      console.log(e);
      return null;
    }

    if (!payload) {
      logger.silly(signature + 'Payload is null');
      return null;
    }

    return payload;
  };

  public verifyJWT(token?: string | IJWTPayloadDecoded | null): boolean {
    const signature = className + '.verifyJWT: ';
    // logger.silly(signature + `Verifying using token From[${(token ? 'args' : 'decode')}]`);
    const payload = token ? typeof token === 'string' && this.decodeJWT(token) || (token as IJWTPayloadDecoded) : this.decodeJWT();

    if (!payload) {
      logger.silly(signature + 'Payload is null');
      return false;
    }

    if (payload.exp < Math.round(new Date().getTime() / 1000)) {
      logger.silly(signature + 'Payload is expired');
      return false;
    }

    return true;
  };

  public removeJWTData() {
    const signature = className + '.removeJWTData: ';
    this.storageService.delete(StorageServiceKey.jwt);
    this.storageService.delete(StorageServiceKey.jwt_refresh);
    this.storageService.delete(StorageServiceKey.jwt_token_type);
		logger.silly(signature + 'Cleared');
    this.currentJwtPayloadSubject.next(null);
  };

  public isExpired(token?: string | IJWTPayloadDecoded): boolean {
    const payload = token ? typeof token === 'string' && this.decodeJWT(token) || (token as IJWTPayloadDecoded) : this.decodeJWT();

    if (!payload) {
      return false;
    }

    const now = new Date();
    const nowSeconds = Math.round(now.getTime() / 1000);

    if (payload.exp < nowSeconds) {
      return true;
    }

    return false;
  };
}
