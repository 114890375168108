import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { IGroup, IGroupAllocation, IGroupCustomer } from '../../model/group.model';
import { GroupService } from '../../services/group.service';
import { CollectionService } from '../../services/collection.service';
import { ICollection, IGroupCollection } from '../../model/collection.model';
import moment from 'moment-mini';
import { KVArray } from '../../model/shared.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';


enum AllocationFrequency {
  disabled = 'Disabled',
  inactive = "Ad Hoc (No Automatic Allocation)",
  weekly = 'First Day of Week (Weekly)',
  monthly = 'First Day of Month (Monthly)',
  annually = 'First Day of Year (Yearly)',
  financialYear = "First Day of Financial Year (Yearly)",
  arbitraryYear = "Yearly from Start Date",
  twoYear = "Bi-Yearly from Start Date",
  threeYear = "Three Yearly from Start Date"
}

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: []
})
export class GroupEditComponent implements OnInit, OnDestroy {
  public model: IGroup = {
    name: '',
    customers: [],
    accessMode: 0,
    isRestrictedGroup: false,
    isRestrictedPricing: false,
    collections: [],
    allocations: [],
    addCollections: false
  };
  /**
 * @description Temporary holding for the selected customer option
 */
  selectedCollectionOption: { id: string, text: string };
  selectedCustomer: { id: string, text: string }[] = [];
  @ViewChild('selectedCustomer') selectCustomer: NgSelectComponent;

  @ViewChild('coll') selectCollection: NgSelectComponent;

  @ViewChild('collection') selectCollectionAll: NgSelectComponent;
  private unsubscribe$ = new Subject<void>();
  public customerSelectOptions: { id: string, text: string }[];
  public collectionSelectOptions: { id: string | undefined | number, text: string }[];
  public allCollection: ICollection[];
  public allocations: IGroupAllocation[] = [];
  allocationFrequencyOptions: KVArray = [];
  selectedItems: any[];
  public activeClassId: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  public customerQuery = new IQueryFilter({
    limit: 10,
  })

  public collectionQuery = new IQueryFilter({
    limit: 25
  })

  private collectionTerms: Subject<string> = new Subject<string>();
  public noCollectionFoundText: string;
  public noCustomerFoundText: string;
  isCustomer: boolean;
  customerId: string | null;

  constructor(
    private customerService: CustomerService,
    public route: ActivatedRoute,
    private groupService: GroupService,
    private collectionService: CollectionService,
    private router: Router,
    private modalService: NgbModal,
    private notifications: NotificationsService
  ) { }
  @ViewChild('allocation_confirm_modal')
  public collectionModal: ElementRef;

  @ViewChild('customer_confirm_modal')
  public customerModal: ElementRef;
  ngOnInit() {
    this.search();
    this.collectionSearch();
    this.isCustomer = this.route.snapshot.queryParamMap.get('isCustomer') == 'true';
    this.customerId = this.route.snapshot.queryParamMap.get('customerId');
    this.route.params.subscribe(params => {
      if (has(params, 'groupId')) {
        this.model.id = params.groupId;
        if (this.model.id) {
          this.loadModel(this.model.id);
        }
      }
      if (has(params, 'customerId')) {
        this.customerId = params.customerId;
        this.model.customers?.push({
          customerId: params.customerId,
          name: '',
        })
      } else {
        this.handleCustomerListGet();
      }
    });

    this.handleCollection();
    this.allocationFrequencyOptions = Object.keys(AllocationFrequency).map(key => ({ key: key, value: AllocationFrequency[key] }));
  }


  handleCollection(isScroll: boolean = true) {
    this.noCollectionFoundText = 'Fetching...';
    if (isScroll) {
      this.collectionQuery.limit = this.collectionQuery.limit + 10;
    }
    this.collectionService.list(this.collectionQuery).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe((result) => {
        if (result.rows.length == 0) {
          this.noCollectionFoundText = "Collection not found";
        }
        this.allCollection = result.rows;
        this.collectionSelectOptions = [
          ...result.rows.map(collection => ({ id: collection.id, text: collection.name }))
        ];
      });
  }
  /**
 * @description Loads the existing group data from the database
 */
  loadModel(id: number) {
    this.groupService.get(id.toString()).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe((group: IGroup) => {
        this.model = group;
        if (!this.model.allocations) this.model.allocations = [];

        this.model.collections?.forEach((data) => {
          if (data.collection && data.collection.products) {
            delete data.collection.products;
          }
        });
      });
  }

  addCollections(collection?: { groupAllocationId?: number, collectionId: number | undefined, itemCount: number, name: string }[]) {
    for (const selectedCollection of this.selectCollectionAll.selectedValues) {
      let existing = collection?.find((existingItem) => existingItem.collectionId == selectedCollection.id);
      if (!existing) {
        let foundRow = this.collectionSelectOptions.find((row) => row.id === selectedCollection.id);
        if (foundRow) {
          collection?.push({
            itemCount: 0, // You can initialize itemCount as required
            collectionId: foundRow.id ? +foundRow.id : undefined,
            name: foundRow.text
          });
        }
      }
    }
    this.disableSelectChange = true;

    this.selectCollectionAll.clearModel();
    this.selectCollectionAll.searchTerm = '';
    this.selectCollectionAll.close();

    setTimeout(() => {
      this.disableSelectChange = false;
    }, 0);
  }

  /**
 * @description Turns response from Customer Get into options for select2
 */
  handleCustomerListGet(isScroll: boolean = false) {
    this.noCustomerFoundText = "Fetching...";
    if (isScroll) {
      this.customerQuery.limit = this.customerQuery.limit + 10;
    }
    this.customerService.list(this.customerQuery).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe((result) => {
        if (result.rows.length == 0) {
          this.noCustomerFoundText = "No Customer Found";
        }
        this.customerSelectOptions = [
          ...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }))
        ];
      });
  }

  onCustomerSearch(searchTerm: { term: string; items: any[]; }) {
    this.searchTerms.next(searchTerm.term);
  }

  onCollectionSearch(searchTerm: { term: string; items: any[]; }) {
    this.collectionTerms.next(searchTerm.term);

  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(searchTerm => {
      this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
      this.handleCustomerListGet();
    });
  }

  collectionSearch() {
    this.collectionTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(searchTerm => {
      this.collectionQuery.filter.name = { $like: '%' + searchTerm + '%' };
      this.handleCollection();
    });
  }

  onClear(type: string) {
    if (type == 'customer' && this.customerQuery.filter.name) {
      delete this.customerQuery.filter.name;
      this.handleCustomerListGet();
    } else if (this.collectionQuery.filter.name) {
      delete this.collectionQuery.filter.name;
      this.handleCollection();
    }
  }

  disableSelectChange = false;
  onSelectChange(select) {
    if (this.disableSelectChange) {
      return;
    }
    setTimeout(() => {
      select.open();
    });
  }

  changeFrequency(allocation: IGroupAllocation, frequency: string) {
    const frequencyKey = Object.keys(AllocationFrequency).find(key => AllocationFrequency[key] === frequency);

    if (frequencyKey) {
      allocation.frequency = frequencyKey;
    }
  }

  getCollectionName(modelCustomer: any) {
    if (!this.collectionSelectOptions)
      return;

    let collectionSelectOptions = this.collectionSelectOptions.find(selectOption => {
      return selectOption.id == modelCustomer.collectionId.toString()
    });

    if (!collectionSelectOptions)
      return "";

    return collectionSelectOptions.text;
  }

  /**
  * @description Removes the selected customer from the list customers and re-indexes the
  *              customer array to maintain the existing order
  *
  * @param modelCustomer
  */
  removeCustomer(modelCustomer: IGroupCustomer) {
    if (!this.model.customers) return;

    for (let i = 0; i < this.model.customers.length; i++) {
      if (this.model.customers[i].customerId === modelCustomer.customerId) {
        this.model.customers.splice(i, 1);
        break;
      }
    }
  }

  /**
  * @description Takes the temporarily stored selected customer ID and adds it to the group customers
  */
  addSelectedCustomer = () => {
    if (!this.model.customers) {
      this.model.customers = [];
    }
    // Push all selected values to the model
    this.selectCustomer.selectedValues.forEach(selectedValue => {
      if (!this.model.customers?.find(customer => customer.customerId === +selectedValue.id)) {
        this.model.customers?.push({
          customerId: +selectedValue.id,
          name: selectedValue.text
        });
      }
    });
  }

  isReadOnly(allocation: IGroupAllocation): boolean {

    if (allocation.id) return true;

    return false;
  }


  /**
   * @description Validate and persist the groups in the server, ignoring validating for Draft groups
   */
  async saveModel() {
    if (this.isCustomer ? this.activeClassId == 3 : this.activeClassId == 4) {
      let canModelOpen = false;
      this.model.collections?.forEach((coll) => {
        const findColl = this.allCollection.find(c => c.id == coll.collectionId);
        if (findColl) {
          this.model.customers?.forEach(cusId => {
            const exists = findColl.customers?.some((customer) => customer.customerId == cusId.customerId);
            if (!exists) {
              canModelOpen = true;
              return;
            }
          })
        }
      });

      if (canModelOpen) {
        await this.openCustomerModal();
      }

      const promises: any = [];
      let modalAlreadyOpened = false;

      this.model.allocations.forEach((allocation) => {
        allocation.allocationCollections.forEach((allocationCollection) => {
          const { collectionId, name } = allocationCollection;

          const exists = this.model.collections?.some((collection) => collection.collectionId === collectionId);

          if (!exists) {
            if (!modalAlreadyOpened) {
              promises.push(
                new Promise((resolve, reject) => {
                  const modalRef = this.modalService.open(this.collectionModal, {
                    size: 'sm',
                    centered: true,
                    backdrop: true,
                    windowClass: 'deleteModal'
                  });

                  modalRef.result.then(
                    () => {
                      resolve({ collectionId, name });
                    },
                    () => {
                      resolve(null);
                    }
                  );
                })
              );
              modalAlreadyOpened = true;
            } else {
              this.model.collections?.push({ collectionId, name });
            }
          }
        });
      });

      Promise.all(promises).then((results) => {
        const collectionsToAdd = results.filter((result) => result !== null);
        collectionsToAdd.forEach(({ collectionId, name }) => {
          this.model.collections?.push({ collectionId, name });
        });
        this.callGroupService();
      });
    } else {
      this.activeClassId = this.activeClassId + 1;
    }
  }

  openCustomerModal() {
    return new Promise<void>((resolve, reject) => {
      const modalRef = this.modalService.open(this.customerModal, {
        size: 'sm',
        centered: true,
        backdrop: true,
        windowClass: 'deleteModal'
      });

      modalRef.result.then(
        () => {
          try {
            this.model.addCollections = true;
            resolve();
          } catch (error) {
            resolve();
          }
        },
        () => {
          resolve();
        }
      );
    });
  }

  public callGroupService() {
    if (this.model.name.length > 50) {
      this.notifications.warn('Save Group', 'group name is too large');
      return;
    }

    if (this.model.name.length < 3) {
      this.notifications.warn('Save Group', 'group name have minimum 3 characters');
      return;
    }
    const handler = this.model.id ? this.groupService.update(this.model.id, this.model) : this.groupService.create(this.model);
    handler.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        if (!this.model.id) {
          this.getRoute();
        }
      },
      (err) => {
        let errMsg = "Unknown Error Saving Group";
        if (err.status === 400 || err.status === 406) {
          console.log(err);
          if (err.error && err.error.errors) {
            errMsg = err.error.errors;
          } else if (err.error && err.error.message) {
            errMsg = err.error.message;
          }
        }
      }
    );
  }

  /**
  * @description Takes the temporarily stored selected customer ID and adds it to the group customers
  */
  addSelectedCollection = () => {
    if (!this.model.collections) {
      this.model.collections = [];
    }

    // Push all selected values to the model
    this.selectCollection.selectedValues.forEach(selectedValue => {
      if (!this.model.collections?.find(collection => collection.collectionId == +selectedValue.id)) {
        this.model.collections?.push({
          collectionId: +selectedValue.id,
          name: selectedValue.text
        });
      }
    });


    this.disableSelectChange = true;

    this.selectCollection.clearModel();
    this.selectCollection.searchTerm = '';
    this.selectCollection.close();

    setTimeout(() => {
      this.disableSelectChange = false;
    }, 0);
  }

  addAllocation = () => {
    this.model.allocations.push({
      maxBalance: null,
      type: "garment",
      frequency: 'arbitraryYear',
      startAt: moment().startOf('day').add(1, "day").toISOString(),
      mode: '',
      amount: 0,
      allocationCollections: []
    });
  }

  setActive(id: number) {
    this.activeClassId = id;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRoute() {
    if (!this.isCustomer) {
      this.router.navigate(['/manage/users'], { queryParams: { activeTab: 1 } });
    } else {
      this.router.navigate(['/manage/customer/edit', this.customerId], { queryParams: { activeTab: 5 } });
    }
  }

}
