import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../../services/report.service'
import { SessionApi } from '../../api/session.api';

type IReportDataRow = {
  id: number;
  name: string;
  code: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactEmail: string;
  address_name: string;
  street_address: string;
  street_address2: string;
  suburb: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
};

@Component({
  selector: 'app-business-unit-report',
  templateUrl: './business-unit-report.component.html'
})
export class BusinessUnitReportComponent {
  public reportData: IReportDataRow[] = [];
  public pathBase = '/account/reports';
  public customerId: number;
  public name: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public reportService: ReportService,
    private session: SessionApi
  ) {
    if (this.session.$userData.value?.isAdmin) {
      this.pathBase = '/manage/reports';
    }
  }

  ngOnInit() {
    this.customerId = this.activatedRoute.snapshot.queryParams.customerId || null;
    this.name = this.activatedRoute.snapshot.queryParams.name || null;
    const reportData: IReportDataRow[] = [];

    this.reportService.getBusinessUnitReport({ customerId: this.customerId }).subscribe(data => {
      if (data.length > 0) {
        data.forEach(item => {
          const tmpItem: typeof reportData[number] = {
            id: item.id || 0,
            name: item.name,
            code: item.code,
            ContactFirstName: 'N/A',
            ContactLastName: 'N/A',
            ContactEmail: 'N/A',
            address_name: 'N/A',
            street_address: 'N/A',
            street_address2: 'N/A',
            suburb: 'N/A',
            city: 'N/A',
            region: 'N/A',
            postalCode: 'N/A',
            country: 'N/A'
          };

          if (item.contact && item.contact.user) {
            tmpItem.ContactFirstName = item.contact.user.firstName;
            tmpItem.ContactLastName = item.contact.user.lastName;
            tmpItem.ContactLastName = item.contact.user.email;
          }

          if (item.shippingDetails) {
            tmpItem.address_name = item.shippingDetails.addressName || tmpItem.address_name;
            tmpItem.street_address = item.shippingDetails.streetAddress || tmpItem.street_address;
            tmpItem.street_address2 = item.shippingDetails.streetAddress2 || tmpItem.street_address2;
            tmpItem.suburb = item.shippingDetails.suburb || tmpItem.suburb;
            tmpItem.city = item.shippingDetails.city || tmpItem.city;
            tmpItem.region = item.shippingDetails.region || tmpItem.region;
            tmpItem.postalCode = item.shippingDetails.postalCode || tmpItem.postalCode;
            tmpItem.country = item.shippingDetails.country || tmpItem.country;
          }

          reportData.push(tmpItem);
        })
      }

      this.reportData = reportData;
    });
  }


  /**
  * @description Exports the current contents of reportData to CSV
  */
  downloadFile() {
    this.reportService.createCSV({ data: { customerId: this.customerId }, type: 'businessUnit' }).subscribe();
  }
}
