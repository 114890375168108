

export function hasClass(element: Element, className: string | string[], operator: "OR" | "AND" = "AND") {
	if (!element.classList) return false;

	if (Array.isArray(className)) {
		switch (operator) {
			case 'OR': {
				const foundClass = className.find(cssClass => element.classList.contains(cssClass));

				return foundClass;
			}
			default: {
				const missingClass = className.find(cssClass => !element.classList.contains(cssClass));

				console.log(missingClass);

				return !missingClass;
			}
		}
	}

	return element.classList.contains(className);
}

export function hasParentWithClass(element: MouseEvent["target"], className: string | string[], operator: "OR" | "AND" = "AND"): boolean {
	if (!(element instanceof Element)) {
		return false;
	}

	let refElement = element;
	if (hasClass(refElement, className, operator)) {
		return true;
	}
	while (refElement.parentElement) {
		refElement = refElement.parentElement;
		if (hasClass(refElement, className, operator)) {
			return true;
		}
	}
	return false;
}
