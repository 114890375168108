<div *ngIf="type === 'detail' && !isAdmin" class="dropdown multi-wish">
	<button
		class="btn btn-wishlist btn-secondary-icon text-danger dropdown-toggle"
		type="button"
		data-bs-toggle="dropdown"
		aria-expanded="false"
		[class.active]="isProductInList(null)"
	>
		<ng-container *ngFor="let list of listsContainingProduct; let i = index">
			<p
				class="d-flex align-items-center gap-2"
				*ngIf="isProductInList(list.id) && i < 1"
			>
				{{ list.name }}
				<span
					[class.d-none]="listsContainingProduct.length <= 1"
					class="badge rounded-pill px-2 border top-0 text-danger"
					>+{{ listsContainingProduct.length - 1 }}</span
				>
			</p>
		</ng-container>
		<span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					d="M17.3671 3.84123C16.9415 3.41541 16.4361 3.07761 15.8799 2.84714C15.3237 2.61667 14.7275 2.49805 14.1254 2.49805C13.5234 2.49805 12.9272 2.61667 12.371 2.84714C11.8147 3.07761 11.3094 3.41541 10.8838 3.84123L10.0004 4.72457L9.11709 3.84123C8.25735 2.98149 7.09129 2.49849 5.87542 2.49849C4.65956 2.49849 3.4935 2.98149 2.63376 3.84123C1.77401 4.70098 1.29102 5.86704 1.29102 7.0829C1.29102 8.29876 1.77401 9.46482 2.63376 10.3246L3.51709 11.2079L10.0004 17.6912L16.4838 11.2079L17.3671 10.3246C17.7929 9.89894 18.1307 9.39358 18.3612 8.83736C18.5917 8.28115 18.7103 7.68497 18.7103 7.0829C18.7103 6.48083 18.5917 5.88465 18.3612 5.32844C18.1307 4.77222 17.7929 4.26686 17.3671 3.84123Z"
					stroke="transparent"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</span>
	</button>
	<ul class="dropdown-menu more-menu drop-list-menu righ-0">
		<li *ngIf="!favoritesListResult.rows.length">No Favourites List</li>
		<li class="drop-item" *ngFor="let data of favoritesListResult.rows">
			<a
				(click)="addFav(data.id)"
				class="dropdown-link form-check d-flex align-items-center justify-content-between"
				role="button"
			>
				<label class="form-check-label cursor-pointer" for="favoritesList">
					{{ data.name }}
				</label>
				<input
					class="form-check-input"
					type="checkbox"
					value=""
					id="{{ 'fav' + data.id }}"
					[checked]="isProductInList(data.id)"
				/>
			</a>
		</li>
	</ul>
</div>
<button
	*ngIf="type == 'detail'"
	class="btn btn-wishlist btn-secondary-icon shadow-none p-0"
	type="button"
></button>

<div
	class="form-group"
	*ngIf="type == 'model' && favoritesListResult.rows.length"
>
	<label for="Colour" class="form-label d-flex flex-wrap gap-2"
		>My Favourite
		<p *ngFor="let list of listsContainingProduct">
			<span
				class="choosed badge bg-aqua-25 ps-3 py-2 d-inline-flex"
				*ngIf="isProductInList(list.id)"
			>
				{{ list.name }}
			</span>
		</p>
	</label>
	<select
		#fav
		class="form-select"
		aria-label="Default select example"
		id="Colour"
		(change)="addFav(fav.value)"
	>
		<option selected>Add to a Favourite list</option>
		<option *ngFor="let data of favoritesListResult.rows" [value]="data.id">
			{{ data.name }}
		</option>
	</select>
</div>
