import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../../services/report.service'
import { SessionApi } from '../../api/session.api';

@Component({
	selector: 'app-user-allocation',
	templateUrl: './user-allocation.component.html',
})
export class UserAllocationComponent {
	public reportData: any;
	public pathBase = '/account/reports';
	public customerId: number;
	public name: string;
	constructor(
		private activatedRoute: ActivatedRoute,
		public reportService: ReportService,
		public session: SessionApi
	) {
		if (this.session.$userData.value?.isAdmin) {
			this.pathBase = '/manage/reports';
		}
	}

	ngOnInit() {
		this.customerId = this.activatedRoute.snapshot.queryParams.customerId || null;
    this.name = this.activatedRoute.snapshot.queryParams.name || null;

		this.reportService.getUserAllocationReport({ customerId: this.customerId }).subscribe(data => {

			this.reportData = data;
		});
	}

	/**
	 * @description Exports the current contents of reportData to CSV
	 */
	downloadFile() {
		this.reportService.createCSV({ data: { customerId: this.customerId }, type: 'userAllocation' }).subscribe();
	}
}
