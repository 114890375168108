// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'ap-southeast-2',
  endpoint: 'https://dev.api.reali.au',
  wsEndPoint: 'ws://localhost:5000',
  appName: 'GAWN',
  // endpoint: 'https://local.api.reali.au',
  // wsEndPoint: 'wss://local.api.reali.au',
  logLevel: 'silly',
  buildId: 'local',
  defaultCollectionLimit: 10,
  enableSentry: false,
  defaultTestEmailAddress: "",
  version: 'LOCAL',
  baseDomain: 'localhost'
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
