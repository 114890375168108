import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { FileUploadApi } from '../api/fileUpload.api';
import { v4 as uuid } from 'uuid';
import { SessionApi } from "../api/session.api";

@Injectable()
export class FileUploadService {
  constructor(
    private notifications: NotificationsService,
    private fileUploadApi: FileUploadApi,
    private session: SessionApi,
  ) {
  }

  uploadProductImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'products'
    }, callback)
  }

  uploadCustomerImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'customers'
    }, callback)
  }

  uploadProductOptionImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'productOptions'
    }, callback)
  }

  uploadSupportingDocument(file: any, type: string, callback: (err, data) => void) {
    const folder = this.getFolder();

    // TODO: Ensure this does not overwrite an existing file
    this.session.$userData.subscribe(user => {
      this.uploadFile({
        file: file,
        bucket: `supportingDocuments/${folder}/${type}/${user?.id}`
      }, callback)
    })
  }

  uploadDocument(file: any, type: string, callback: (err, data) => void) {
    const folder = this.getFolder();

    // TODO: Ensure this does not overwrite an existing file

    this.session.$userData.subscribe(user => {
      this.uploadFile({
        file: file,
        bucket: `supportingDocuments/${folder}/${type}/${user?.id}`
      }, callback)
    })
  }

  uploadDataSheet(file: any, callback: (err, data) => void) {
    const folder = this.getFolder();

    this.uploadFile({
      file: file,
      bucket: `dataSheet/${folder}`
    }, callback)
  }

  uploadSizeChart(file: any, callback: (err, data) => void) {
    const folder = this.getFolder();

    this.uploadFile({
      file: file,
      bucket: `sizeChart/${folder}`
    }, callback)
  }

  /**
   * Helper method for determining the destination for a file upload
   */
  getFolder = (): string => process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV === 'staging' ? 'stage' : 'dev';

  uploadFile(options: { file: any, bucket: string }, callback: (err, data) => void) {

    let extension = options.file.name.match(/[^\.]+$/);

    let key = options.bucket + '/' + uuid() + '.' + extension[0];

    this.fileUploadApi.getSignedUploadUrl('static.reali.supply', key)
      .subscribe(signedUrlResult => {
        this.fileUploadApi.decodeUri(options.file, signedUrlResult.url,).subscribe(result => callback(null, {
          Location: signedUrlResult.url.replace(/\?.*$/, '')
        }));
      });

  }


  public list(query: IQueryFilter): Observable<any> {
    return apiCallWrapper(
      this.fileUploadApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User"
      }
    )
  }
}
