<div class="main-right-content">
  <nav aria-label="breadcrumb" class="pageBreadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item">
        <a (click)="navigateToUserEdit()">Users</a>
      </li>
      <li class="breadcrumb-item">
        <a (click)="navigateToUserEdit()">{{userName}}</a>
      </li>
      <li class="breadcrumb-item">
        <a (click)="navigateToUserAllocation()">Edit allocation</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        View Logs
      </li>
    </ol>
  </nav>

  <div class="manageUser">
    <h1 class="title">Allocation Logs</h1>

    <div class="manage-user-list">
      <app-search [sortText]="getSortText()" [sortOptions]="sortOptions" (searchChanged)="updateSearchTerm($event)"
        (sortChanged)="updateSortField($event)"></app-search>
      <div class="manage-table main-table d-none d-lg-block">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Logs</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of queryResult.rows">
              <td>
                <div class="user-name">
                  <h5>{{ item.id }}</h5>
                </div>
              </td>

              <td>
                <p>{{ item.createdAt | date:'MMM d yyyy h:mma' }}</p>
              </td>
              <td>
                <p class="logs-para">
                  {{ item.message }}
                </p>
              </td>
            </tr>
            <tr *ngIf="queryResult.rows.length === 0 && !isLoading">
              <td colspan="6">
                <div class="no-results-content">
                  <div class="no-result-img for-admin">
                    <img src="../../../../assets/images/No-result.png" alt="No-result" />
                  </div>
                  <h4 class="title">No Results Found!</h4>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Cards for sm screen start here... -->
      <div class="manage-cards d-lg-none">
        <div class="card manage-card" *ngFor="let item of queryResult.rows">
          <div class="card-body">
            <ul class="card-list">
              <li>
                <div class="manage-grid">
                  <span class="head"> ID </span>
                  <div class="user-name">
                    <h5>{{ item.id }}</h5>
                  </div>
                </div>
              </li>
              <li>
                <div class="manage-grid">
                  <span class="head"> Date </span>
                  <div>
                    <p>{{ item.createdAt | date:'MMM d yyyy h:mma' }}</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="manage-grid">
                  <span class="head"> Data </span>
                  <div>
                    <p>
                      {{ item.message }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="no-results-content" *ngIf="queryResult.rows.length == 0 && !isLoading">
          <div class="no-result-img for-admin">
            <img src="../../../../assets/images/No-result.png" alt="No-result" />
          </div>
          <h4 class="title">No Results Found!</h4>
        </div>
      </div>
      <!-- Cards for sm screen end here... -->

      <app-pagination [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
        (pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>
    </div>
  </div>
</div>