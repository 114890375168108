import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createUrl } from './api.util';
import { Observable } from 'rxjs';
import { DecorationCustomer } from '../model/decoration.model';

// Convenience Type
type T = DecorationCustomer;

@Injectable()
export class CustomerDecorationApi extends GenericApi<T> {
  public path = "decoration-customer";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getAllDecorationCustomersByDecorationId = (decorationId: number | string): Observable<DecorationCustomer[]> => {
    return this.httpClient.get<DecorationCustomer[]>(
      createUrl(this.path, 'getAllDecorationCustomersByDecorationId'),
      { params: { decorationId: decorationId } }
    );
  };
}
