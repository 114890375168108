import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { logger } from '../util/Logger';
import { SessionApi } from '../api/session.api';
import { SecurityService } from '../services/security.service';

@Injectable()
export class UserRoleGuard implements CanActivate {
  protected permissions: string[] = [];
  protected roles: string[] = [];

  constructor(
    private readonly router: Router,
		private readonly session: SessionApi,
		private readonly securityService: SecurityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const signature = 'UserRoleGuard.canActivate: ';
    this.permissions = route.data.permissions || [];
    this.roles = route.data.roles || [];

		return this.session.$sessionChanged.pipe(
			filter(ready => ready),
			switchMap(() => this.securityService.isAuthenticated()),
			filter(authenticated => {
				if( !authenticated ) {
					logger.info(signature + 'User is not authenticated');
					return false;
				}

				return true;
			}),
			switchMap(() => this.session.$customerUser),
			filter( customerUser => {
				if( !customerUser ) {
          logger.info(signature + 'Current permissions does not include target customer');
          return false;
				}

				return true;
			}),
			switchMap(() => this.session.$roleData),
			filter( role => {
				if( !role ) {
          logger.info(signature + 'Current role could not be found');
          return false;
				}

				return true;
			}),
			tap(role => logger.silly(signature + `Found Permissions[${role!.permissions}]`)),
			map(role => {
				const currentPermissions = String(role!.permissions).split(",");

				const hasPermission = this.permissions.some(permission => permission === 'all' || currentPermissions.includes(permission));

        if (!hasPermission) {
          logger.error(signature + 'User failed role check. Redirecting to Login');
          this.router.navigate(['/home']);
        }

        return hasPermission;
			})
    );
  }
}
