import { Component } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { NewProduct } from '../../model/ddb.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FavoriteService } from '../../services/favorites.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-favorites-detail',
  templateUrl: './favorites-detail.component.html',
  styleUrls: []
})
export class FavoritesDetailComponent {
  public productResult: QueryResult<NewProduct> = new QueryResult();
  public queryParams: Params = {};
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  public name: string | null = '';
  public isEdit: boolean = false;
  public query: IQueryFilter = new IQueryFilter({
    sortBy: 'name'
  });
  removedProducts: { index: number, product: any }[] = [];
  public isLoading = false;
  isAlreadyChecked: boolean;
  canInitiate: boolean = false;
  constructor(
    private productService: ProductService,
    public activatedRoute: ActivatedRoute,
    public favoriteService: FavoriteService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    public router: Router
  ) {
    this.name = this.activatedRoute.snapshot.queryParamMap.get('name');
    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      favoriteService.get(params.id).subscribe(() => {
        this.canInitiate = true;
      }, err => {
        router.navigate(['/account/favorites']);
      })
    });
  }

  loadProducts() {
    this.isLoading = true;
    let params: any = {};
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.queryParams.id) {
      params.favoritesListId = this.queryParams.id;
    }

    this.productService.getProductList(this.query, params)
      .subscribe(queryResult => {
        this.isLoading = false;
        this.productResult = queryResult;
        this.totalCountActive = queryResult.count;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadProducts();
        }
      }, (error) => {
        this.notifications.error('Fetch Product', error.error && error.error.message);
      });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadProducts()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadProducts();
  }

  public openRemoveModal() {
    if (this.queryParams.id) {
      const modal = this.modalService.open(ModalComponent, {
        scrollable: false,
        size: 'sm',
        centered: true,
        backdrop: true,
        windowClass: 'deleteModal'
      });

      if (modal.componentInstance) {
        const component = modal.componentInstance as ModalComponent;

        component.title = 'Delete Document';
        component.showIcon = true;
        component.data = `
				<div>
					<h4 class="title">Remove Document</h4>
					<p class="desc">Would you like to remove this document?</p>
				</div>`;
        component.buttons = [{
          text: 'Remove',
          action: 'close',
          value: true,
          class: 'btn-danger'
        }, {
          text: 'Cancel',
          action: 'close',
          value: false,
          class: 'btn btn-secondary'
        }]
      }

      modal.result
        .then((isDelete) => {
          if (isDelete) {
            this.notifications.warn('Processing', 'Deleting the list.');
            this.favoriteService.removeFavoriteList(this.queryParams.id).then(() => {
              this.notifications.success('Success', 'Favorite List Was Removed.');
              this.router.navigate(['/account/favorites']);
            });
          }
        })
        .catch(() => {
          modal.dismiss();
        });
    }
  }

  deleteProduct(i: number, id: number | undefined) {
    if (id && this.queryParams.id) {
      const removedProduct = this.productResult.rows.splice(i, 1)[0];
      this.removedProducts.push({ index: i, product: removedProduct });
    }
  }

  cancelProduct() {
    this.removedProducts.sort((a, b) => a.index - b.index);
    for (const item of this.removedProducts) {
      this.productResult.rows.splice(item.index, 0, item.product);
    }
    this.removedProducts = [];
    this.isEdit = false;
  }

  saveChanges() {
    if (this.queryParams.id) {
      const removalPromises = this.removedProducts.map(data => {
        return this.favoriteService.removeProductFromFavoriteList(data.product.id, this.queryParams.id)
          .then(() => {
            this.loadProducts();
            this.isEdit = false;
          })
          .catch(err => {
            console.log(err);
          });
      });

      Promise.all(removalPromises)
        .then(() => {
          // this.router.navigate(['/account/favorites']);
        })
        .catch(err => {
          console.log('Error while removing products:', err);
        });
    }
  }

}
