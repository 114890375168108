import { Component } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { User } from '../../model/user.model';
import { IQueryFilter } from '../../model/query.filter.class';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-admin-list',
  templateUrl: './list.component.html',
  styleUrls: []
})
export class AdminListComponent {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  public isLoading: boolean;

  public quertResult: User[];
  // query: IQueryFilter = new IQueryFilter();
  query: IQueryFilter = new IQueryFilter({
    filter: {},
    sortBy: 'firstName',
    order: 'desc'
  });

  sortOptions = [{
    id: 1,
    text: "First Name",
    field: "firstName"
  }, {
    id: 2,
    text: "Last Name",
    field: "lastName"
  }, {
    id: 3,
    text: "Email",
    field: "email"
  }];
  isAlreadyChecked: boolean;

  constructor(
    private adminService: AdminService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(queryparams => {
      this.currentPageActive = +queryparams['page'] || 1;
      this.query.limit = +queryparams['pageSize'] || 10;
      this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
      // this.loadQuery();
    });
    this.search();
  }

  loadQuery() {
    // this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.adminService.list(this.query).subscribe((res) => {
      this.totalCountActive = res.count;
      this.quertResult = res.rows;
      this.isLoading = false;

      if (this.totalCountActive > 0 && res.rows.length == 0 && !this.isAlreadyChecked) {
        this.isAlreadyChecked = true;
        this.query.skip = 0;
        this.currentPageActive = 1;
        this.loadQuery();
      }
    });
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (!this.query.filter) this.query.filter = {};

      if (searchTerm && searchTerm.length)
        this.query.filter['$or'] = {
          firstName: searchTerm,
          lastName: searchTerm,
          email: searchTerm
        }
      else
        delete this.query.filter['$or'];
      this.loadQuery();
    });
  }


  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadQuery()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadQuery();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadQuery();
  }

  deleteAdmin(adminId: number | undefined) {
    if (adminId) {
      this.adminService.delete(adminId).subscribe(() => {
        this.loadQuery();
      })
    }
  }

  public openRemoveModal(adminId: number | undefined) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Admin';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Admin</h4>
					<p class="desc">Would you like to remove this admin user?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && adminId) {
          this.deleteAdmin(adminId);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }
}
