<div class="main-right-content">
  <!-- Back-button start here... -->
  <div class="backs" *ngIf="isAdmin">
    <a [routerLink]="[editLink]" [queryParams]="{ activeTab: activeTab }" class="btn btn-back p-0 gap-2 gap-2" role="button">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      Back to Edit customer
    </a>
  </div>
  <!-- Back-button end here... -->

  <!-- manage-user main start here... -->
  <div class="manageUser">
    <div class="d-flex align-items-start justify-content-between">
      <h1 class="title">{{shippingDetailsId ? 'Edit' : 'Add'}} address</h1>
    </div>

    <div class="manage-user-list">
      <div class="edit-users">
        <div>
          <div class="edit-card card">
            <div class="card-head">
              <h4 class="head-title">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16.75 8.5C16.75 13.75 10 18.25 10 18.25C10 18.25 3.25 13.75 3.25 8.5C3.25 6.70979 3.96116 4.9929 5.22703 3.72703C6.4929 2.46116 8.20979 1.75 10 1.75C11.7902 1.75 13.5071 2.46116 14.773 3.72703C16.0388 4.9929 16.75 6.70979 16.75 8.5Z"
                      stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M10 10.75C11.2426 10.75 12.25 9.74264 12.25 8.5C12.25 7.25736 11.2426 6.25 10 6.25C8.75736 6.25 7.75 7.25736 7.75 8.5C7.75 9.74264 8.75736 10.75 10 10.75Z"
                      stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                Address Detail
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="addressName" class="form-label">Name *</label>
                    <input type="text" class="form-control" id="addressName" placeholder="Address Name"
                      [(ngModel)]="address.addressName" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="enterCountry" class="form-label">Country *</label>
                    <input type="text" class="form-control" id="enterCountry" placeholder="Enter Country"
                      [(ngModel)]="address.country" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="addressLine1" class="form-label">Address Line 1 *</label>
                    <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                      [(ngModel)]="address.streetAddress" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="Suburb" class="form-label">Suburb *</label>
                    <input type="text" class="form-control" id="Suburb" placeholder="Enter Suburb"
                      [(ngModel)]="address.suburb" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="addressLine2" class="form-label">Address Line 2</label>
                    <input type="text" class="form-control" id="addressLine2" placeholder="Address Line 2"
                      [(ngModel)]="address.streetAddress2" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="City" class="form-label">City *</label>
                    <input type="text" class="form-control" id="City" placeholder="Enter City"
                      [(ngModel)]="address.city" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="State" class="form-label">State *</label>
                    <input type="text" class="form-control" id="State" placeholder="Enter State"
                      [(ngModel)]="address.region" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="postalCode" class="form-label">Postal Code *</label>
                    <input type="text" class="form-control" id="postalCode" placeholder="Enter Postal Code"
                      [(ngModel)]="address.postalCode" />
                  </div>
                </div>
                <div class="col-12 col-md-10">
                  <div class="form-group form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [value]="true"
                      [(ngModel)]="address.isFreeHandling" />
                    <label class="form-check-label" for="flexCheckDefault">
                       Small Order Fee Free
                      <span class="d-block mt-1">Shipping to this address is never charged the
                         Small Order Fee</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-start justify-content-between">
            <div class="edit-btns w-sm-100">
              <button (click)="saveAddress()" class="btn btn-primary w-sm-100" type="button">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
