import { Component, Input } from '@angular/core';
import { IGroup } from '../../model/group.model';

@Component({
  selector: 'app-group-overview',
  templateUrl: './overview.component.html',
  styleUrls: []
})
export class OverviewComponent {
  @Input() group: IGroup;

}
