import { Component, EventEmitter, Output } from '@angular/core';
import { CollectionService } from '../../services/collection.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { ICollection } from '../../model/collection.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-allocation-loder',
  templateUrl: './allocation-loder.component.html',
  styleUrls: []
})
export class AllocationLoderComponent {
  collectionResult: ICollection[] = [];
  public total: number = 0;
  public completionPercentage: number;
  public allocationPercentage: number;
  @Output() totalCalculated = new EventEmitter<number>();
  @Output() collections = new EventEmitter<ICollection[]>();
  private subscription: Subscription;
  constructor(private collectionService: CollectionService) { }

  ngOnInit() {
    this.subscription = this.collectionService.getCollections().subscribe(collections => {
      this.collectionResult = collections.filter(coll => coll.allocationAvailable);
      this.total = this.calculateTotalAllocation();
      this.totalCalculated.emit(this.total);
      this.collections.emit(this.collectionResult);
      const total = this.collectionResult.reduce((total, coll) => {
        return total + (coll.allocationCount || 0);
      }, 0);
      if (this.total == 0) {
        this.allocationPercentage = 100;
        return;
      }
      this.allocationPercentage = Math.round(((total - this.total) / total) * 100);
      const remainder = this.allocationPercentage % 10;
      if (remainder >= 5) {
        this.allocationPercentage = Math.ceil(this.allocationPercentage / 10) * 10;
      } else {
        this.allocationPercentage = Math.floor(this.allocationPercentage / 10) * 10;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  calculateTotalAllocation(): number {
    return this.collectionResult.reduce((total, coll) => {
      return total + (coll.allocationAvailable || 0);
    }, 0);
  }
}
