import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMightHaveShippingDetails, ShippingDetailsClass } from '../../model/shippingDetail.model';
import { CartAttrs } from '../../model/cart.model';
import { SessionApi } from '../../api/session.api';
import { combineLatest, debounceTime, distinctUntilChanged, filter, first, Subject, switchMap } from 'rxjs';
import { BusinessUnitService } from '../../services/businessUnit.service';
import { ShippingDetailService } from '../../services/shippingDetail.service';
import { IBusinessUnit } from '../../model/businessUnit.model';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { SecurityService } from '../../services/security.service';
import { CartService } from '../../services/cart.service';
import { omit } from 'lodash';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IQueryFilter } from '../../model/query.filter.class';
import { BusinessUnit } from '../../model/unleashed.model';
import { CustomerService } from '../../services/customer.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-cart-checkout-contact',
  templateUrl: './cart-checkout-contact.component.html',
  styleUrls: []
})
export class CartCheckoutContactComponent implements OnDestroy {
  private _attrs: CartAttrs = new CartAttrs();
  hasEditShippingAddress: boolean = false;
  hasSelectAccountAddress: boolean = false;
  shippingDetailList: ({ displayName?: string } & ShippingDetailsClass)[] = [];
  filteredShippingDetailList: ({ displayName?: string } & ShippingDetailsClass)[] = [];
  private prefilRequest: Promise<void> | null = null;
  private businessUnits: (BusinessUnit & IMightHaveShippingDetails)[] = [];
  private readonly subscriptionGroup = new SubscriptionGroup();
  shippingDetails: ShippingDetailsClass = new ShippingDetailsClass();
  businessUnitsSelect2Data: Array<{
    text: string, id?: string, selected: boolean
  }> = [];
  businessQuery: IQueryFilter = new IQueryFilter({ sortBy: 'id', limit: 10 });
  noBusinessUnitFoundText = 'No business unit found';
  private _currentPage: number = 1;

  public address: ShippingDetailsClass = new ShippingDetailsClass();
  public isBusinessUnitDisabled: boolean;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Australia];
  PhoneNumberFormat = PhoneNumberFormat;
  poIsReq: boolean;

  @ViewChild('business') selectBusineessUnit: NgSelectComponent;

  @Input()
  message: string | null;

  @Input()
  messageSeverity: string;

  @Output()
  addressChanged = new EventEmitter<ShippingDetailsClass | null>();

  // The cart Attrs that this component will operate on
  @Input()
  attrs: CartAttrs

  phoneForm: FormGroup;
  searchText: string;
  customerBusinessUnitId: number | undefined;
  private searchTerms = new Subject<string>();

  constructor(
    private session: SessionApi,
    private businessUnitService: BusinessUnitService,
    private shippingDetailService: ShippingDetailService,
    private securityService: SecurityService,
    public cartService: CartService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.phoneForm = this.fb.group({
      mobile: ['', [Validators.required]]
    });
    this.requestContactPrefil()
    this.monitorAccess();
    const customerData = this.session.$customerData.getValue();
    if (!customerData) {
      throw new Error("Expected to reliably have access to customer data");
    }

    if (!customerData.purchaseOrderPattern) {
      this.poIsReq = false;
    } else {
      this.poIsReq = true;
    }

    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((term: string) => this.loadBusinessUnits(term))
    ).subscribe(businessUnitsResponse => {
      const newUnits = businessUnitsResponse.rows.map(item => ({
        ...item,
        text: `${item.code} - ${item.name}`
      }));

      this.businessUnits = newUnits;
      this.businessUnitsSelect2Data = this.mapBusinessUnitOptions(this.businessUnits, this.customerBusinessUnitId || null);
    });
  }

  get f() {
    return this.phoneForm.controls;
  }

  serachAddress() {
    const searchTextLower = this.searchText.toLowerCase();
    this.filteredShippingDetailList = this.shippingDetailList.filter(detail =>
      detail.addressName?.toLowerCase().includes(searchTextLower) ||
      detail.postalCode?.toLowerCase().includes(searchTextLower) ||
      detail.streetAddress?.toLowerCase().includes(searchTextLower) ||
      (detail.streetAddress2 && detail.streetAddress2.toLowerCase().includes(searchTextLower)) ||
      detail.region?.toLowerCase().includes(searchTextLower)
    );
  }

  onPhoneNumberChange(event: any): void {
    if (event && event.number) {
      this.attrs.contactPhone = event.number;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attrs && changes.attrs.previousValue instanceof CartAttrs) {
      this.requestContactPrefil();
    }
  }

  /**
 * @description Waits for session to become available before attempting a contact prefil, prevents duplicate attempts
 */
  requestContactPrefil = () => {
    if (this.prefilRequest) {
      return this.prefilRequest;
    }

    this.prefilRequest = new Promise<void>((resolve, reject) => {

      this.session.$sessionChanged.pipe(
        filter(isInit => !!isInit),
        switchMap(() => this.session.$customerData),
        filter(customerData => !!customerData),
        first(),
        switchMap(customerData => {
          const customerId = customerData?.id || 0;
          return combineLatest(
            this.customerService.getBusinessUnit(customerId, this.businessQuery),
            this.shippingDetailService.getShippingDetailsList(customerId)
          );
        }
        )
      ).subscribe(([businessUnits, shippingDetailsList]) => {
        // Handle Business units
        this.businessUnits = businessUnits.rows;
        this.businessUnits.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        });

        this.businessUnits = businessUnits.rows.map(item => {
          return {
            ...item,
            name: `${item.code} - ${item.name}`
          };
        });

        // /Handle Business units

        // Handle Shipping Details Options
        this.shippingDetailList = [];
        let userShippingDetails = this.session.getUserProperty('shippingDetails');
        if (userShippingDetails) {
          this.shippingDetailList.push(Object.assign({
            displayName: "Home Address"
          }, userShippingDetails));
        }

        this.shippingDetailList.push(...shippingDetailsList);
        this.shippingDetails = this.shippingDetailList[0];
        // /Handle Shipping Details Options

        // This must occur after a prefil of the contact details to ensure the right business unit is selected
        const customerUser = this.session.getCustomerUser();

        if (!customerUser) {
          throw new Error("Expected to always have a customer user at this point");
        }

        this.customerBusinessUnitId = customerUser.businessUnitId;
        this.businessUnitsSelect2Data = this.mapBusinessUnitOptions(businessUnits.rows, customerUser.businessUnitId || null);

        // this._loadedExternalData = true;

        this.fulfilContactPrefil();
        this.filteredShippingDetailList = this.sortAddress(this.shippingDetailList);
        resolve();
      });
    });
  }

  loadBusinessUnits(searchTerm: string = '', loadMore: boolean = false) {
    if (loadMore) {
      this._currentPage += 1;
      this.businessQuery.skip = this.businessQuery.limit * (this._currentPage - 1);
    } else {
      this._currentPage = 1; // reset page for new search
      this.businessQuery = new IQueryFilter({ sortBy: 'id', limit: 10 });
    }

    if (searchTerm) {
      this.businessQuery.filter['$or'] = [
        { name: { $like: '%' + searchTerm + '%' } },
        { code: { $like: '%' + searchTerm + '%' } }
      ];
    } else {
      delete this.businessQuery.filter['$or'];
    }

    return this.session.$customerData.pipe(
      first(),
      switchMap(customerData => {
        const customerId = customerData?.id || 0;
        return this.customerService.getBusinessUnit(customerId, this.businessQuery);
      })
    );
  }

  fetchBusinessUnit(loadMore: boolean = false) {
    this.loadBusinessUnits('', loadMore).subscribe(businessUnitsResponse => {
      const newUnits = businessUnitsResponse.rows.map(item => ({
        ...item,
        text: `${item.code} - ${item.name}`
      }));
      this.businessUnits = [...this.businessUnits, ...newUnits];
      this.businessUnitsSelect2Data = this.mapBusinessUnitOptions(this.businessUnits, this.customerBusinessUnitId || null);
    });
  }

  onClear() {
    delete this.businessQuery.filter['$or'];
    this.fetchBusinessUnit();
  }

  onBusinessUnitSearch(searchTerm: { term: string; items: any[] }) {
    this.searchTerms.next(searchTerm.term); // emit search term
  }

  mapBusinessUnitOptions(units: Array<BusinessUnit>, defaultBusinessUnit: number | null) {

    const defaultBusinessUnitId = this.cartService.cart.attrs.businessUnitId || defaultBusinessUnit;

    const result = [
      //{ text: 'Clear', id: ' ' },
      ...units.map(item => {
        let text = item.code;
        if (item.name && item.name.length) { text += ' - ' + item.name; }

        return {
          text,
          id: item.id?.toString(),
          selected: defaultBusinessUnitId === item.id
        }
      })
    ];

    if (defaultBusinessUnit) {
      this.cartService.cart.attrs.businessUnitId = defaultBusinessUnit;
    }

    // exclude functionality of business unit address.
    // this.renderBusinessUnitAddress(defaultBusinessUnit || undefined);

    return result;
  }

  sortAddress(shippingDetailList: ({ displayName?: string } & ShippingDetailsClass)[]) {
    return shippingDetailList
      .filter(detail => detail.addressName)
      .sort((a, b) => {
        const addressNameA = a.addressName ?? '';
        const addressNameB = b.addressName ?? '';

        if (addressNameA < addressNameB) return -1;
        if (addressNameA > addressNameB) return 1;
        return 0;
      });
  }

  monitorAccess() {

    this.subscriptionGroup.add(
      combineLatest(
        this.session.$roleData,
        this.securityService.hasEditShippingAddress(),
        this.securityService.hasSelectAccountAddress()
      ).pipe(
        filter(([roleData]) => !!roleData)
      )
        .subscribe(([_roleData, hasEditShippingAddress, hasSelectAccountAddress]) => {
          this.hasEditShippingAddress = hasEditShippingAddress;
          this.hasSelectAccountAddress = hasSelectAccountAddress;

          if (!hasSelectAccountAddress && !hasEditShippingAddress) {
            this.isBusinessUnitDisabled = true;
          } else {
            this.isBusinessUnitDisabled = false;
          }
        })
    );
  }

  /**
 * @description Fulfils a contact prefil request
 */
  private fulfilContactPrefil = () => {
    this.prefillContactDetails();
    this.prefillShippingDetails();
    this.prefilRequest = null;
  }

  /**
 * @description If no address information has already been selected, this prefills the address details. If the user has a defined business unit, that will be selected first
 * @returns {void}
 */
  private prefillShippingDetails = () => {

    // Check if its blank
    if (
      (!this.shippingDetails.addressName || !this.shippingDetails.addressName.length) &&
      (!this.shippingDetails.streetAddress || !this.shippingDetails.streetAddress.length) &&
      (!this.shippingDetails.streetAddress2 || !this.shippingDetails.streetAddress2.length) &&
      (!this.shippingDetails.suburb || !this.shippingDetails.suburb.length) &&
      (!this.shippingDetails.city || !this.shippingDetails.city.length) &&
      (!this.shippingDetails.postalCode || !this.shippingDetails.postalCode.length)
    ) {
      const userShippingDetails = this.session.getUserProperty("shippingDetails");

      if (userShippingDetails) {
        this.shippingDetails.addressName = userShippingDetails.addressName || "";
        this.shippingDetails.streetAddress = userShippingDetails.streetAddress || "";
        this.shippingDetails.streetAddress2 = userShippingDetails.streetAddress2 || "";
        this.shippingDetails.suburb = userShippingDetails.suburb || "";
        this.shippingDetails.city = userShippingDetails.city || "";
        this.shippingDetails.country = userShippingDetails.country || "";
        this.shippingDetails.postalCode = userShippingDetails.postalCode || "";
        this.shippingDetails.region = userShippingDetails.region || "";
      }
    }
  }


  /**
 * @description Loads the current user information into the contact details where ever those details are not already present
 * @returns {void}
 */
  private prefillContactDetails = () => {
    if (this.attrs) {
      // RS20-184 Reload the default contact details every time the page checkout page is loaded

      // if (!this.attrs.contactFirstName || !this.attrs.contactFirstName.length)
      this.attrs.contactFirstName = this.session.getUserProperty('firstName') || "";

      // if (!this.attrs.contactLastName || !this.attrs.contactLastName.length)
      this.attrs.contactLastName = this.session.getUserProperty('lastName') || "";

      // if (!this.attrs.contactEmail || !this.attrs.contactEmail.length)
      this.attrs.contactEmail = this.session.getUserProperty('email') || "";

      // if (!this.attrs.contactPhone || !this.attrs.contactPhone.length)
      this.attrs.contactPhone = this.session.getUserProperty('contactNumber');
    }
  };

  public readonly renderBusinessUnitAddress = (defaultBusinessUnitId?: number) => {
    // Remove existing business unit address from the list, add a new one
    this.shippingDetailList = this.shippingDetailList.filter(detail => !detail.displayName || detail.displayName !== "Business Unit");

    const businessUnitId = defaultBusinessUnitId || this.cartService.cart.attrs.businessUnitId;
    if (!businessUnitId) return;

    let businessUnit = this.businessUnits.find(b => b.id === businessUnitId);

    if (!businessUnit || !businessUnit.shippingDetails) return;

    this.shippingDetails = businessUnit.shippingDetails;
    // If the first item is a home address, the second should be business unit, else, business unit should be #1
    if (this.shippingDetailList.length > 0 && this.shippingDetailList[0].displayName && this.shippingDetailList[0].displayName === "Home Address") {
      const firstElement = this.shippingDetailList.shift();
      this.shippingDetailList.unshift(Object.assign({
        displayName: "Business Unit"
      }, businessUnit.shippingDetails));
      if (firstElement) {
        this.shippingDetailList.unshift(firstElement);
      }
    } else {
      this.shippingDetailList.unshift(Object.assign({
        displayName: "Business Unit"
      }, businessUnit.shippingDetails));
    }

  }

  /**
 * Generates easily read display text for any given shipping details item
 * @param details
 * @param includeName
 */
  addressDisplayText(details: (ShippingDetailsClass & { displayName?: string }), includeName = false) {
    return (includeName && (details.addressName || details.displayName) ? (details.displayName || details.addressName) + ': ' : '') +
      (details.streetAddress ? details.streetAddress + ' ' : '') +
      (details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
      (details.suburb ? details.suburb : '');
  }

  public readonly selectAddress = (details: ShippingDetailsClass | null) => {
    const existingDetails = JSON.stringify(this.shippingDetailList);
    const newDetails = JSON.stringify(details);

    if (existingDetails !== newDetails) {
      if (!details) {
        this.shippingDetails = new ShippingDetailsClass();
      } else {
        this.shippingDetails = omit(details, 'id');
      }

      this.attrs.shippingDetails = this.shippingDetails;

      this.addressChanged.emit(this.shippingDetails);
      this.modalService.dismissAll();
    }
  }

  /**
 * @description Updates the selected business unit after checking the value against all known business units
 * @param {string|number} businessUnitId
 */
  public selectBusinessUnit() {
    const businessUnitId = this.selectBusineessUnit.selectedValues.length && this.selectBusineessUnit.selectedValues[0].id;
    // Needs to be pushed to the top of the stack because of the way the element works
    const businessUnit = businessUnitId ? this.businessUnits.find(b => b.id == Number(businessUnitId)) : null;

    if (!businessUnit) {
      this.selectAddress(null);
    } else {

      // exclude functionality of business unit address.
      // if (businessUnit.shippingDetails) {
      //   this.selectAddress(businessUnit.shippingDetails);
      // }

      if (businessUnit.id) {
        this.cartService.cart.attrs.businessUnitId = businessUnit.id;
      }
    }

    // exclude functionality of business unit address.
    // this.renderBusinessUnitAddress(+businessUnitId);
  };

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }
  }

  openAddressModel(content) {
    this.modalService.open(content, {
      size: 'lg', centered: true, backdrop: true, windowClass: 'bulk-order'
    })
  }
}
