import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { IWellFormattedError } from '../../model/auth.model';
import { SSOService, SSOState } from '../../services/sso.service';
import { SessionApi } from '../../api/session.api';
import { CookieService } from 'ngx-cookie-service';
import { noWhitespaceValidator } from '../../view/contactus/contact-us/contact-us.component';
import {
	SearchCountryField,
	CountryISO,
	PhoneNumberFormat
} from "ngx-intl-tel-input";
import { AppHeaderComponent } from '../../template/app-header/app-header.component';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: []
})
export class LoginComponent {
	useSSO$ = this.ssoService.ssoStateSubject.asObservable().pipe(
		map(state => state === SSOState.READY)
	);

	heading: string = "Login";
	loginForm: FormGroup;
	accountForm: FormGroup;
	passwordResetRequired: boolean = false;
	token: string;
	logginChallenge: boolean = false;
	forgotPassword: boolean = false;
	public createAccount: boolean;
	public captcha: boolean;
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.Australia];
	PhoneNumberFormat = PhoneNumberFormat;
	isLoading: boolean = false;
	@Input() requestEmail: string | null;
	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		public notifications: NotificationsService,
		public router: Router,
		private jwtService: JwtService,
		private ssoService: SSOService,
		private headerComponent: AppHeaderComponent
	) {
		this.loginForm = this.fb.group({
			username: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, noWhitespaceValidator]]
		});
		this.accountForm = this.fb.group({
			username: ['', [Validators.required, Validators.email, noWhitespaceValidator]],
			firstname: ['', [Validators.required, noWhitespaceValidator]],
			lastname: ['', [Validators.required, noWhitespaceValidator]],
			company: ['', [Validators.required, noWhitespaceValidator]],
			mobile: [undefined, [Validators.required]],
		});
	}

	ngOnInit() {
		if (this.requestEmail) {
			this.loginForm.get('username')?.setValue(this.requestEmail);
		}
		this.authService.getAccountMenu().subscribe(menu => {
			if (menu == 'account') {
				this.createAccount = true;
			} else {
				this.createAccount = false;
			}
		})
		this.completeSSO();
	}

	completeSSO() {
		if (this.ssoService.authRequestMonitor) return;
		this.ssoService.authRequestMonitor = true;

		combineLatest([
			this.ssoService.ssoStateSubject,
			this.ssoService.ssoData
		]).pipe(
			filter(([state, session]) => (state === SSOState.READY) && !!session),
			first(),
			map(([, session]) => session!),
			tap(() => this.isLoading = true),
			switchMap(data => this.authService.sso(data))
		).subscribe({
			next: (res) => {
				if ('accessToken' in res) {
					this.jwtService.saveJWTData(res);
					this.authService.navigateToDefaultUrl();
					this.notifications.success('Logged In', 'Welcome');

					return;
				}
			},
			error: (error) => {
				if (error.error.detail && error.error.detail.errorCode == 4047) {
					this.wellFormattedAuthenticateErrorHandler(error.error.detail);
					return;
				}

				if (403 === error.error.statusCode) {
					this.notifications.error("ERROR", error && error.error && error.error.detail);
				}
			},
			complete: () => {
				this.isLoading = false;
			}
		});
	}

	login() {
		if (this.loginForm.invalid) return;
		this.isLoading = true;
		this.loginForm.markAsPending();
		this.authService.authenticate(this.loginForm.value).subscribe(
			(res) => {
				this.isLoading = false;
				if ('message' in res && res.message && res.message == "loginChallenge Required") {
					this.logginChallenge = true;
					this.loginForm.addControl('loginChallenge', this.fb.control('', Validators.required));
					this.notifications.warn("Login", res && res.message);
					return;
				}

				if ('accessToken' in res) {
					this.jwtService.saveJWTData(res);
					this.authService.navigateToDefaultUrl();
					this.notifications.success('Logged In', 'Welcome');
					return;
				}
			},
			(error) => {
				this.isLoading = false;
				if (error.error.detail && error.error.detail.errorCode == 4047) {
					this.wellFormattedAuthenticateErrorHandler(error.error.detail);
					return;
				}
				if (403 === error.error.statusCode) {
					this.notifications.error("ERROR", error && error.error && error.error.detail);
				}
			}
		);
	}

	private readonly wellFormattedAuthenticateErrorHandler = (
		err: IWellFormattedError
	) => {
		if (err.errorCode === 4047) {
			if (!err.detail || !err.detail.length) {
				throw new Error("Password reset request did not present reset token");
			}
			this.token = err.detail[0];
			this.notifications.warn('Update Required', 'Your password needs to be reset');
			this.passwordResetRequired = true;
		}
	}

	reset() {
		this.isLoading = true;
		const data = this.loginForm.value;
		data.resetToken = this.token;
		data.email = this.loginForm.value.username;
		this.authService.reset(data).subscribe(
			(res) => {
				this.isLoading = false;
				this.jwtService.saveJWTData(res);
				this.router.navigate(['/manage/dashboard']);
				this.isLoading = false;
			},
			(error) => {
				this.notifications.error("ERROR", error && error.detail && error.detail.message || error.message || {});
			}
		);
	}


	back() {
		this.passwordResetRequired = false;
		this.forgotPassword = false;
		this.heading = "Login";
		this.loginForm.addControl('password', this.fb.control('', Validators.required));
	}

	forget() {
		this.heading = "Forgot Password";
		this.forgotPassword = true;
		this.loginForm.removeControl('password');
		this.loginForm.reset();
	}

	close() {
		this.headerComponent.enableStickyMenu('');
		this.headerComponent.removeActiveClassFromElement();
	}

	updatePassword() {
		if (this.loginForm.invalid) return;
		this.isLoading = true;
		const data = { email: this.loginForm.value.username };
		this.authService.forgot(data).subscribe(
			() => {
				this.isLoading = false;
			},
			(error) => {
				this.notifications.error("ERROR", error && error.detail && error.detail.message || error.message || {});
				this.isLoading = false;
			}
		);
	}

	performSSO() {
		this.ssoService.initiateSSO().subscribe({
			next: () => { },
			error: (e) => {
				console.log(e);
				this.notifications.error("Error", "There was an error signing in. Please contact support.");
			}
		});
	}

	create() {
		if (!this.accountForm.valid && !this.captcha) {
			return;
		}
		this.isLoading = true;
		const mobileNumber = this.accountForm.value.mobile.e164Number || '';
		this.accountForm.patchValue({
			mobile: mobileNumber
		});
		this.notifications.warn("Request Account", 'Sending Request');
		this.authService.accountCreate(this.accountForm.value).subscribe(
			(res) => {
				this.captcha = false;
				grecaptcha.reset();
				this.accountForm.reset();
				this.isLoading = false;
				this.notifications.success("Request Sent", 'Thank you for requesting access to SUMS. Your request has been successfully received and is currently being reviewed. If you would like to chat with our Sales Team directly please email sales@reali.au or call 1300 107 233 ');
			},
			(error) => {
				this.notifications.error("ERROR", error && error.detail && error.detail.message || error.message || {});
				this.isLoading = false;
			}
		);
	}

	resolved(res: string) {
		if (res) {
			this.captcha = true;
		}
	}

}


