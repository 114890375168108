import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IOrderCardPaymentPrepared } from "../../model/order.model";
import { CartService } from '../../services/cart.service';
import { Observable, Subject, combineLatest, filter, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { SecurityService } from '../../services/security.service';
import { Router } from '@angular/router';
import { Notification, NotificationsService } from 'angular2-notifications';
import { Utils } from '../../util/utils';
import { Console } from 'console';
import { SessionApi } from '../../api/session.api';
import { SubscriptionGroup } from '../../util/subscriptionGroup';

@Component({
	selector: 'app-payment-method',
	templateUrl: './payment-method.component.html',
	styleUrls: []
})
export class PaymentMethodComponent implements OnInit {
	@Input()
	ccTransaction: Partial<IOrderCardPaymentPrepared> = {};

	balance: number = 0;
	freeCCTransaction: boolean = false;
	public _paymentType: string = 'Invoice';
	public isLoading: boolean = false;

	public totalRemainingGarments = 0;

	public canUseCreditCardPayment$ = this.securityService.canUseCreditCardPayment();
	public canUseInvoicePayment$ = this.securityService.canUseInvoicePayment();
	public showPaymentOptions$ = combineLatest([ this.canUseCreditCardPayment$, this.canUseInvoicePayment$ ]).pipe(
		map( ([creditPayment, invoicePayment]) => {
			if( this.freeCCTransaction ) return false;

			return creditPayment || invoicePayment;
		})
	);

	private defaultPaymentType$ = this.canUseInvoicePayment$.pipe(
		map( (accountAccess) => {
			if( !accountAccess ) return 'CC';

			return 'Invoice';
		})
	);

	private subscriptionGroup = new SubscriptionGroup();

	constructor(
		private cartService: CartService,
		private securityService: SecurityService,
		public router: Router,
		private notifications: NotificationsService,
		public utils: Utils,
		public session: SessionApi
	) { }

	/** This needs to be manually called when the application is ready to discuss payment options */
	public init() {
		this.monitorGatewayAccess();
	}

	ngOnInit(): void {
		this.subscriptionGroup.add(
			this.session.$userData
				.pipe(
					tap(() => this.totalRemainingGarments = 0),
					filter(data => !!data),
					map(data => data?.customerUsers || []),
					filter(customerUsers => !!customerUsers.length),
					map(customerUsers => customerUsers[0]),
					map(firstCustomerUser => firstCustomerUser.userAllocations || []),
					filter(allocations => !!allocations.length)
				)
				.subscribe(allocations => {
					let remainingGarmentCount = 0;
					allocations.forEach(allocation => {
						(allocation.allocationCollections || []).forEach(allocationCollection => {
							remainingGarmentCount += allocationCollection.remainingItemCount;
						})
					});

					this.totalRemainingGarments = remainingGarmentCount;
				})
		);
	}

	ngOnDestroy() {
		this.subscriptionGroup.unsubscribe();
	}

	set paymentType(val: string) {

		this._paymentType = val;

		if (this.ccTransaction.consumedAllocation) {
			this.balance = this.ccTransaction.consumedAllocation;
		}

		if (val == "CC") {

			this.freeCCTransaction = (
				(this.ccTransaction && Number(this.ccTransaction['EPS_AMOUNT']) === 0) ||
				(this.ccTransaction['responseMessage'] && this.ccTransaction['responseMessage'] === 'Approved')
			);
		}

		if (val == "Invoice") {
			this.freeCCTransaction = Number(this.ccTransaction.EPS_AMOUNT) === 0
		}
	}

	get paymentType() {
		return this._paymentType;
	}

	/**
	 * Monitors access to the various payment gateways
	 */
	private monitorGatewayAccess() {
		this.subscriptionGroup.add(
			this.cartService.cardPaymentDetails$.pipe(
				filter(preparedTransaction => !!preparedTransaction),
				tap(preparedTransaction => this.ccTransaction = preparedTransaction || {}),
				switchMap(() => this.defaultPaymentType$)
			).subscribe(defaultPaymentType => {
				this.paymentType = defaultPaymentType;
			})
		);

		this.cartService.requestCCUpdate();
	}

	/**
 * @description Generates the callback url based on the current url
 */
	getCallbackUrl(): string {
		let currentAbsoluteUrl = window.location.href;
		let currentRelativeUrl = this.router.url;
		let index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
		let baseUrl = currentAbsoluteUrl.substring(0, index);
		let callbackUrl = this.router.createUrlTree([`/cart/complete/${this.cartService.cart.id}`]);

		return baseUrl + callbackUrl.toString();
	}

	validate(): { isValid: boolean, notification: Notification | null } {
		if (!String(this.ccTransaction['EPS_CARDNUMBER'] || '').match(this.utils.expressions.CreditCard)) {
			const notification: Notification = this.notifications.error('Invalid Credit Card Number', 'Please enter a valid credit card number');

			return { isValid: false, notification };
		}

		if (!String(this.ccTransaction['EPS_EXPIRYMONTH'] || '').match(this.utils.expressions.CCExpiryMonth)) {
			const notification = this.notifications.error('Invalid Expiry Month', 'Please a card expiry month');

			return { isValid: false, notification };
		}

		if (!String(this.ccTransaction['EPS_EXPIRYYEAR'] || '').match(this.utils.expressions.CCExpiryYear)) {
			const notification = this.notifications.error('Invalid Expiry Year', 'Please enter a valid 4 digit expiry year');

			return { isValid: false, notification };
		}

		if (!String(this.ccTransaction['EPS_CCV'] || '').match(this.utils.expressions.CCV)) {
			const notification = this.notifications.error('Invalid Credit Security Code', 'Please enter a valid CCV');

			return { isValid: false, notification };
		}

		let expiryDate = new Date((Number(this.ccTransaction['EPS_EXPIRYYEAR'])), (Number(this.ccTransaction['EPS_EXPIRYMONTH']) - 1), 1);
		if (expiryDate < (new Date())) {
			const notification = this.notifications.warn('Invalid Credit Card Expiry', 'The details you entered have expired. Please enter Card Details which have not yet expired.');
			return { isValid: false, notification };
		}


		const notification = this.notifications.warn('Processing Credit Card', 'Do not refresh or navigate away while we process your payment');
		return { isValid: true, notification };
	}

	@ViewChild('CCForm') ccForm: ElementRef;
	submit() {
		this.ccForm.nativeElement.submit();
	}

	public isCCTransaction(): boolean {
		return this.paymentType === "CC" && !this.freeCCTransaction;
	}
}
