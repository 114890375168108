<!-- ================================== -->
<!-- Returns and Exchanges Banner start here... -->
<!-- ================================== -->
<section class="inhoused">
	<div class="max-width-container">
		<div class="container">
			<h1>Returns and Exchanges Policy</h1>
			<p>
				At Reali, we prioritise your satisfaction and aim to facilitate
				hassle-free returns and exchanges. Please familiarize yourself with our
				policy to ensure a smooth process:
			</p>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- Returns and Exchanges Banner end here... -->
<!-- ================================== -->

<!-- ================================= -->
<!-- Returns and Exchanges content start here... -->
<!-- ================================== -->
<section class="about-ship-delivery">
	<div class="max-width-container">
		<div class="container">
			<div class="conditions">
				<h2>Return Guidelines:</h2>
				<ul class="list-condition">
					<li>
						<p>
							We enforce a strict 14-day return policy from the date of
							receiving your order. Failure to initiate a return request within
							this timeframe exempts us from liability to offer a return.
						</p>
					</li>
					<li>
						<p>
							Returns will only be accepted for undecorated items that are in
							their original packaging, with tags attached, and in brand-new
							condition.
						</p>
					</li>
					<li>
						<p>
							Reali reserves the right to refuse returns if the items do not
							meet the above criteria.
						</p>
					</li>
					<li>
						<p>Please note that we do not accept returns on decorated items.</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Exchanges Procedure:</h2>
				<ul class="list-condition">
					<li>
						<p>
							Should you wish to exchange an item, kindly request a return
							following the process outlined below. Please place a separate
							order for the new item you require, as Reali does not manage new
							order requests within return processes.
						</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Restocking Fee and Return Costs:</h2>
				<ul class="list-condition">
					<li>
						<p>A $20 restocking fee will be applied to all returns</p>
					</li>
					<li>
						<p>
							Reali does not cover the cost of return shipping, which is to be
							borne by the customer.
						</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Return Request Process:</h2>
				<ul class="list-condition">
					<li>
						<p>
							All returns must be requested through our designated return
							request form. Our returns team will respond within 24 hours to
							notify you if your return has been approved, along with
							instructions on the appropriate return location.
						</p>
					</li>
					<li>
						<p>
							Please include the approved Return Authorisation Request Form
							inside the package and clearly label the package with the return
							address.
						</p>
					</li>
				</ul>
			</div>
			<div class="conditions">
				<h2>Cancellation Policy:</h2>
				<ul class="list-condition">
					<li>
						<p>
							Should you decide to cancel an order 24 hours after it has been
							processed in our system, a $20 cancellation fee will be charged.
						</p>
					</li>
				</ul>
				<p class="desc">
					We appreciate your cooperation in adhering to our returns and
					exchanges policy. If you have any queries or require further
					assistance, please do not hesitate to contact our Customer Experience
					team.
					<a href="mailto:sales@reali.au" role="button" class="btn btn-link"
						>sales@reali.au</a
					>
					or call us at 1300 107 233.
				</p>
			</div>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- Returns and Exchanges content end here... -->
<!-- ================================== -->
