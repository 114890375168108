import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SizeChartEntry } from '../model/sizeChart.model';

// Convenience Type
type T = SizeChartEntry;

@Injectable()
export class SizeChartApi extends GenericApi<T> {
  public path = "size_chart";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
