import { Subscription } from 'rxjs';

export class SubscriptionGroup {
  private readonly subscriptionGroup: any = [];

  add(subscription: Subscription): void {
    this.subscriptionGroup.push(subscription);
  }

  addAll(...subscriptions: Subscription[]): void {
    subscriptions.forEach(s => this.subscriptionGroup.push(s));
  }

  unsubscribe(): void {
    this.subscriptionGroup.forEach(s => s.unsubscribe());
  }
}
