<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-md-row align-items-start justify-content-between gap-4 manage-top">
			<h1 class="title mb-0">User Roles</h1>
			<a [routerLink]="['/manage/userRoles/edit']" class="btn btn-primary w-sm-100">Add new user role
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
							stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
			</a>
		</div>

		<div class="manage-user-list">
			<app-search [sortText]="getSortText()" [sortOptions]="sortOptions" (searchChanged)="updateSearchTerm($event)"
				(sortChanged)="updateSortField($event)" (scopeChanged)="updateScopeField($event)" [isScope]="true"
				[scopeText]="getScopeText()"></app-search>

			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of queryResult.rows">
							<td>
								<div class="user-name">
									<h5>{{ item.name }}</h5>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center justify-content-start" *ngIf="!item.deletedAt">
									<a (click)="openRemoveModal(item.id)" class="btn btn-delete" role="button">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M3.25 5.5H4.75H16.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</a>
									<a [routerLink]="['/manage/userRoles/edit', item.id]" class="btn btn-edit" role="button">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of queryResult.rows">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Name </span>
									<div class="user-name">
										<h5>{{ item.name }}</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Actions: </span>
									<div class="d-flex align-items-center w-100 justify-content-end" *ngIf="!item.deletedAt">
										<a (click)="openRemoveModal(item.id)" class="btn btn-delete" role="button">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</a>
										<a [routerLink]="['/manage/userRoles/edit', item.id]" class="btn btn-edit" role="button">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
													stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</a>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->

			<app-pagination [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
				(pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>