<div class="edit-users">
  <div>
    <div class="edit-card card">
      <div class="card-head">
        <h4 class="head-title">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
                fill="url(#paint0_radial_0_17046)" fill-opacity="0.5" />
              <path
                d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
                fill="url(#paint1_radial_0_17046)" fill-opacity="0.5" />
              <path
                d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
                stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <defs>
                <radialGradient id="paint0_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint1_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </span>
          Domain
        </h4>
      </div>
      <div class="card-body">
        <div class="row" *ngIf="model">
          <div class="col-12">
            <div class="form-group">
              <label for="DomainName" class="form-label">Domain</label>
              <input type="text" class="form-control" id="DomainName" [(ngModel)]="model.domain" />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="singleSign" [value]="true"
                [(ngModel)]="model.useSSO" />
              <label class="form-check-label" for="singleSign">Use Single Sign On
                <span>Requires domain and manual server
                  configration</span>
              </label>
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group">
              <label for="congnitoUser" class="form-label">Cognito User Pool ID</label>
              <input type="text" class="form-control" id="congnitoUser" placeholder=""
                [(ngModel)]="model.cognitoUserPoolId" />
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group">
              <label for="cognitoClient" class="form-label">Cognito Client ID</label>
              <input type="text" class="form-control" id="cognitoClient" placeholder=""
                [(ngModel)]="model.cognitoClientId" />
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group">
              <label for="cognitoAuth" class="form-label">Cognito Auth Domain</label>
              <input type="text" class="form-control" id="cognitoAuth" placeholder=""
                [(ngModel)]="model.cognitoAuthDomain" />
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group">
              <label for="cognitoAuthprovider" class="form-label">Cognito Auth Provider</label>
              <input type="text" class="form-control" id="cognitoAuthprovider" placeholder=""
                [(ngModel)]="model.cognitoProvider" />
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group" *ngIf="userRoles">
              <label for="defaultUser" class="form-label">Default User Role</label>
              <select #role class="form-select" aria-label="Default select example" id="defaultUser"
                (change)="model.defaultUserRoleId = +role.value">
                <option *ngFor="let data of userRoles.rows" [value]="data.id"
                  [selected]="model.defaultUserRoleId && data.id && +model.defaultUserRoleId == data.id">
                  {{data.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="model.useSSO">
            <div class="form-group">
              <label for="notificationReciever" class="form-label">New User Notification Reciever
              </label>
              <select #customerUser class="form-select" aria-label="Default select example" id="defaultUser"
                (change)="model.contactId = +customerUser.value">
                <option *ngFor="let data of customerUsers" [value]="data.id"
                  [selected]="model.contactId && data.id && +model.contactId == data.id">
                  {{data.user.firstName +
                  data.user.lastName}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button class="btn btn-primary" type="button" (click)="saveModel()">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </span>
        Submit
      </button>
    </div>
  </div>
</div>