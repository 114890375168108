import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import { SessionApi } from '../api/session.api';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { CartAttrs } from '../model/cart.model';
import { CartService } from '../services/cart.service';
import { ProductService } from '../services/product.service';
import { NewProduct } from '../model/ddb.model';
import { Utils } from '../util/utils';

import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexYAxis,
	ApexTitleSubtitle,
	ApexLegend,
	ApexGrid,
	ApexResponsive
} from "ng-apexcharts";
import { AuthService } from '../services/auth.service';
import { SecurityService } from '../services/security.service';
import { AuthApi } from '../api/auth.api';
import { NotificationsService } from 'angular2-notifications';
import { CollectionService } from '../services/collection.service';
import { IAuthCustomerUser } from '../model/customer.user.model';
import { logger } from '../util/Logger';
import { IAuthDataResponse } from '../model/session.model';
import { IAuthCustomer } from '../model/customer.model';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	yaxis: ApexYAxis;
	title: ApexTitleSubtitle;
	labels: string[];
	legend: ApexLegend;
	subtitle: ApexTitleSubtitle;
	grid: ApexGrid;
};

const className = 'DashboardComponent';
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./_dashboard.component.sass']
})
export class DashboardComponent implements OnInit, OnDestroy {
	public user: IAuthDataResponse | null;
	public completedOrderCount: number;
	public completionPercentage: number;
	public backOrderCount: number;
	public approveOrderCount: number;
	public totalOrderCount: number;
	public todayOrder: number;
	public customerId: number | undefined = undefined;
	public actualUserId: number | null = null;
	public orderPath = '/account/orders';
	public products: { product: NewProduct, count: number }[] = [];
	public isSwitch: boolean;
	query: IQueryFilter = new IQueryFilter({
		filter: {
			$and: [{
				status: {
					$ne: "PENDING"
				}
			}, {
				status: {
					$ne: "DELETED"
				}
			}]
		},
		sortBy: 'updated_at',
		order: 'desc'
	});
	queryResult: QueryResult<CartAttrs> = new QueryResult();
	chartQuery: IQueryFilter = new IQueryFilter({
		limit: 1
	});
	public isLoading: boolean;
	@ViewChild("chart") chart: ChartComponent;
	private unsubscribe$ = new Subject<void>();
	public chartOptions: Partial<{
		series: ApexAxisChartSeries;
		chart: ApexChart;
		xaxis: ApexXAxis;
		stroke: ApexStroke;
		dataLabels: ApexDataLabels;
		yaxis: ApexYAxis;
		title: ApexTitleSubtitle;
		labels: string[];
		legend: ApexLegend;
		subtitle: ApexTitleSubtitle;
		grid: ApexGrid,
		responsive: ApexResponsive[]
	}> = {};
	public productQuery = new IQueryFilter({ limit: 5 });
	public total: number = 0;
	public fetchedChatData: number;
	public totalGrandTotal: number = 0;
	public canShowChart: boolean = false;
	hasOwnDetailAccess: boolean = false;
	@ViewChild('cancel_model') public cancel_model: ElementRef;
	public cancelReason: string;
	public deletedOrderId: number;
	public isShowAllocation: number;
	currentCustomer: IAuthCustomer | null;
	allCustomers: IAuthCustomerUser[] | undefined;

	constructor(
		private readonly session: SessionApi,
		private cartService: CartService,
		private productService: ProductService,
		public utils: Utils,
		public authService: AuthService,
		public securityService: SecurityService,
		public authApi: AuthApi,
		public collectionService: CollectionService,
		private notifications: NotificationsService
	) {
		this.chartOptions = {
			chart: {
				type: "area",
				height: 400,
				width: 1200,
				zoom: {
					enabled: false
				}
			}
		};
	}


	ngOnInit(): void {
		this.checkUser();
		this.fetchTopProducts();
	}

	sortCustomers() {
		if (!this.user) {
			return [];
		}

		const customerUsers = this.user.customerUsers || [];
		if (!customerUsers.length) {
			return [];
		}

		if (!this.currentCustomer) {
			return customerUsers;
		}

		customerUsers.sort((a, b) => {
			if (a.customerId === this.currentCustomer?.id) return -1;
			if (b.customerId === this.currentCustomer?.id) return 1;
			return 0;
		});
		this.allCustomers = customerUsers;
	}

	monitorAccess() {
		this.securityService.hasReportsAccess().pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(hasReportAccess => {
			this.canShowChart = this.securityService.isAdmin() || hasReportAccess;
			if (this.canShowChart) {
				this.fetchChartData();
			}
		})
		this.securityService.hasOwnDetailAccess().pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(hasOwnDetailAccess => this.hasOwnDetailAccess = hasOwnDetailAccess);
	}


	checkRoute() {
		window.scroll(0, 0);
	}

	loadOrders() {
		if (this.authService.isAuthenticated()) {
			this.cartService.getOrderAttrsList(this.query)
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(queryResult => {
					this.isLoading = false;
					this.queryResult = queryResult;
					this.totalOrderCount = queryResult.totalOrders;
					this.completedOrderCount = queryResult.completedOrders;
					this.backOrderCount = queryResult.backOrders;
					this.approveOrderCount = queryResult.approvalOrder;
					this.completionPercentage = this.totalOrderCount > 0 ? (this.completedOrderCount / this.totalOrderCount) * 100 : 0;
					this.todayOrder = queryResult.todayOrder;
				});
		}
	}

	checkUser() {
		this.securityService.isEmulatingUser().subscribe({
			next: isEmulating => {
				if (isEmulating) {
					const currentUser = this.session.$userData.getValue();
					if (currentUser && currentUser.customerUsers?.length) {
						this.actualUserId = currentUser?.customerUsers[0].id || null;
					}
					return;
				}

				this.actualUserId = null;
			}
		});

		combineLatest(
			this.session.$userData.pipe(distinctUntilChanged()),
			this.session.$customerData.pipe(distinctUntilChanged())
		).pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(([userData, customerData]) => {
			this.monitorAccess();
			this.currentCustomer = customerData;
			this.customerId = this.currentCustomer?.id;
			this.total = 0;
			if (userData) {
				this.user = userData;
				if (this.user.isAdmin) {
					this.orderPath = '/manage/orders';
				}
			}
			if (!userData?.isAdmin && userData?.customerUsers?.length) {
				this.collectionService.getCollections().subscribe(avlCollection => {
					this.isShowAllocation = avlCollection.reduce((total, coll) => {
						return total + (coll.allocationCount || 0);
					}, 0);
				})
			}
			this.loadOrders();
			this.sortCustomers();
		});
	}

	updateTotal(total: number) {
		this.total = total;
	}

	/**
 * @description UI helper method to determine if the order approval functionality should be presented to the user
 *
 * @param item
 */
	showOrderApproval(item: CartAttrs) {
		if (!this.user) return false;

		return (
			item.status.toUpperCase() === 'APPROVAL'
			&& (
				this.user.isAdmin
				|| this.isApprovingManager(item)
			)
		);
	}

	/**
	/**
	 * @description UI Helper method to determine if the current user is the approving manager
	 *
	 * @param item
	 */
	isApprovingManager(item: CartAttrs) {
		if (!this.user) return false;
		return item.approvedById && this.user.id === item.approvedById;
	}

	public get isAdmin(): boolean {
		if (!this.user) return false;
		return this.user.isAdmin;
	}

	/**
 * @description Approves an order
 *
 * @param item
 */
	approveOrder(item: CartAttrs) {
		this.cartService.approveCart(item.orderId)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(() => {
				this.loadOrders();
			},
				(err) => {
					console.log(err);
				})
	}


	onSaveCompleted() {
		this.loadOrders();
	}

	fetchTopProducts() {
		this.productService.getTopProducts(this.productQuery).pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(res => {
			this.products = res;
		})
	}

	fetchChartData() {
		let chartSeries: { name: string, data: number[] }[] = [];
		this.cartService.fetchChartData(this.chartQuery).pipe(
			takeUntil(this.unsubscribe$)
		).subscribe((res) => {
			this.fetchedChatData = res.length;
			res.forEach((obj) => {
				let grand_total: number[] = [];
				for (let data of Object.values(obj)) {
					this.totalGrandTotal += data.grand_total;
					grand_total.push(data.grand_total);
				}
				chartSeries.push({
					name: Object.values(obj)[0].date.substring(0, 7),
					data: grand_total
				});

				grand_total = [];
			});
			this.chartOptions = {
				series: chartSeries,
				chart: {
					type: "area",
					height: 400,
					width: '100%',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: "smooth"
				},
				xaxis: {
					type: "category",
					labels: {
						formatter: function (val) {
							return val;
						}
					}
				},
				yaxis: {
					show: true,
					min: 0,
					labels: {
						show: true,
						formatter: function (val) {
							return '$' + val;
						},
					},
				},
				legend: {
					horizontalAlign: "right"
				},
				grid: {
					position: 'back',
					borderColor: "gray",
					strokeDashArray: 5,
					xaxis: {
						lines: {
							show: false,
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					}

				},
				responsive: [{
					breakpoint: 576,
					options: {
						chart: {
							type: "area",
							height: 250,
							width: 600,
							zoom: {
								enabled: false
							}
						},
						grid: {
							position: 'back',
							borderColor: "gray",
							strokeDashArray: 5,
							xaxis: {
								lines: {
									show: false,
								}
							},
							yaxis: {
								lines: {
									show: true
								}
							}

						}
					}
				}, {
					breakpoint: 900, options: {
						grid: {
							position: 'back',
							borderColor: "gray",
							strokeDashArray: 5,
							xaxis: {
								lines: {
									show: false,
								}
							},
							yaxis: {
								lines: {
									show: true
								}
							}

						}
					}
				}]
			};
		})
	}

	getDefaultChart(): ApexChart {
		return {
			type: "area",
			height: 350,
			zoom: {
				enabled: false
			}
		};
	}

	getDefaultResp() {
		return [{
			breakpoint: 576,
			options: {
				chart: {
					type: "area",
					height: 250,
					width: 600,
					zoom: {
						enabled: false
					}
				},
				grid: {
					position: 'back',
					borderColor: "gray",
					strokeDashArray: 5,
					xaxis: {
						lines: {
							show: false,
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					}

				}
			}
		},
		{
			breakpoint: 900, options: {
				grid: {
					position: 'back',
					borderColor: "blue",
					strokeDashArray: 5,
					xaxis: {
						lines: {
							show: false,
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					}

				}
			}
		}]
	}

	setChartType(limit: string) {
		this.chartQuery.limit = +limit;
		this.totalGrandTotal = 0;
		this.fetchChartData();
	}

	setQuery(limit: string) {
		this.productQuery.limit = +limit;
		this.fetchTopProducts();
	}

	public switchCustomer(customerUser: IAuthCustomerUser) {
		const signature = className + `.switchCustomer: CustomerId[${customerUser.customerId}]`;
		const currentUser = this.session.$userData.getValue();

		if (!currentUser) {
			logger.error(signature + `Unauthenticated user cannot switch customers`);
			return;
		}


		if (customerUser.customerId) {
			this.isSwitch = true;
			this.authService.switchUser(customerUser.customerId, currentUser.id).pipe(
				takeUntil(this.unsubscribe$)
			).subscribe((res) => {
				this.collectionService.loadCollections();
				this.notifications.success('Switch', 'Your access was updated');
				this.isSwitch = false;
			}, err => {
				this.isSwitch = false;
				this.notifications.error('Switch', 'Something wrong');
			});
		}
	}

	fetchColor(status: string) {
		if (status === "PROCESSING") {
			return 'aqua';
		}
		if (status === "COMPLETED") {
			return 'green';
		}
		if (status === "BACKORDERED") {
			return 'orange';
		}
		if (status === "APPROVAL") {
			return 'purple';
		}
		if (status === "DELETED") {
			return 'red';
		}
		if (status === "PLACED") {
			return 'blue';
		}
		if (status === "REJECTED") {
			return 'brown';
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public capitalizeFirstLetter = (str: string) => {
		str = str.toLowerCase();
		return str ? str[0].toUpperCase() + str.slice(1) : '';
	};
}
