import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFavorite } from '../model/favorites.model';

// Convenience Type
type T = IFavorite;

@Injectable()
export class FavoriteApi extends GenericApi<T> {
  public path = "favorites";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
