<div class="edit-users edit-allocation">
	<!-- New Groups start here... -->
	<div class="edit-card card">
		<div class="card-head">
			<h4 class="head-title">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475"
							fill="url(#paint0_radial_0_15739)" fill-opacity="0.5" />
						<path
							d="M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75"
							fill="url(#paint1_radial_0_15739)" fill-opacity="0.5" />
						<path
							d="M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16"
							fill="url(#paint2_radial_0_15739)" fill-opacity="0.5" />
						<path
							d="M7.75 9.25C9.40685 9.25 10.75 7.90685 10.75 6.25C10.75 4.59315 9.40685 3.25 7.75 3.25C6.09315 3.25 4.75 4.59315 4.75 6.25C4.75 7.90685 6.09315 9.25 7.75 9.25Z"
							fill="url(#paint3_radial_0_15739)" fill-opacity="0.5" />
						<path
							d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z"
							stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						<defs>
							<radialGradient id="paint0_radial_0_15739" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 11.9625)">
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
							<radialGradient id="paint1_radial_0_15739" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 11.9625)">
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
							<radialGradient id="paint2_radial_0_15739" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 11.9625)">
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
							<radialGradient id="paint3_radial_0_15739" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 11.9625)">
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
				</span>
				Add new group
			</h4>
			<button class="btn btn-secondary-icon" type="button" *ngIf="groupAssign" (click)="removeGroup()">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
						<path
							d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
							stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="card-body">

			<form action="">
				<div class="row">
					<div class="col-12 col-lg-8">
						<div class="form-group" *ngIf="!groupAssign">
							<label for="Groups" class="form-label">Groups</label>
							<select #group class="form-select" aria-label="Default select example" id="Groups"
								(change)="groupChange(group.value)">
								<option selected *ngIf="this.model.userGroups && this.model.userGroups.length == 0">Select a group
								</option>
								<option *ngFor="let group of allGroups" [value]="group.id">
									{{ group.name }}
								</option>
							</select>
						</div>

						<div class="form-group" *ngIf="groupAssign">
							<label for="group" class="form-label">Groups</label>
							<input disabled type="text" class="form-control" id="group" [value]="getGroupName()" />
						</div>
					</div>
					<div class="col align-self-end" *ngIf="!groupAssign">
						<div class="form-group">
							<button [disabled]="this.model.userGroups && this.model.userGroups.length == 0"
								class="btn btn-secondary w-100" type="button" (click)="groupAssign = true">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
										<path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</span>
								Assign Group
							</button>
						</div>
					</div>
					<div class="col align-self-end" *ngIf="groupAssign">
						<div class="form-group form-check">
							<input type="checkbox" checked class="form-check-input" id="setprimary" value="true" />
							<label class="form-check-label" for="setprimary">Set to Primary Group
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>