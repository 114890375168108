import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerDomain } from '../model/customerDomain';
import { createUrl, getPublicRoutesHeaders } from './api.util';
import { Observable } from 'rxjs';
import { HasId } from '../model/generics';

// Convenience Type
type T = ICustomerDomain;

@Injectable()
export class CustomerDomainApi extends GenericApi<T> {
  public path = "customer-domain";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }


  getByCustomerId = (id: number | string): Observable<ICustomerDomain & HasId> => {
    return this.httpClient.get<ICustomerDomain & HasId>(
      createUrl(this.path, 'customer', id)
    );
  };

  /**
 * @description Unauthenticated endpoint that fetches domain SSO Config by the supplied domain
 * @returns 
 */
  getByReferer = (domain: string): Observable<ICustomerDomain & HasId> => {
    return this.httpClient.get<ICustomerDomain & HasId>(
      createUrl(this.path, 'domain', domain),
      { headers: getPublicRoutesHeaders() }
    );
  };
}
