import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-template',
  templateUrl: './auth-template.component.html',
  styleUrls: []
})
export class AuthTemplateComponent {

}
