import { orderBy } from 'lodash';
import { v4 as uuid } from 'uuid';

export class SizeChartRow {
  isNew?: boolean;
  private _isUpdated = false;
  private _name: string;
  private _orderNumber: number;

  get orderNumber(): number {
    return this._orderNumber;
  }

  set orderNumber(value: number) {
    this._isUpdated = true;
    this._orderNumber = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._isUpdated = true;
    this._name = value;
  }

  get isUpdated(): boolean {
    if (this.isNew) {
      return false;
    }

    return this._isUpdated;
  }

  constructor(
    name = '',
    orderNumber = 1,
    public id?: number,
  ) {
    this.isNew = !this.id;
    this._name = name;
    this._orderNumber = orderNumber;
    this.id = id || uuid();
  }
}

export class SizeChartColumn {
  isNew?: boolean;
  private _isUpdated = false;
  private _title: string;
  private _subtitle: string;

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._isUpdated = true;
    this._title = value;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  set subtitle(value: string) {
    this._isUpdated = true;
    this._subtitle = value;
  }

  get isUpdated(): boolean {
    if (this.isNew) {
      return false;
    }

    return this._isUpdated;
  }


  constructor(
    title = '',
    subtitle = '',
    public id?: number,
  ) {
    this._title = title;
    this._subtitle = subtitle;
    this.isNew = !this.id;
    this.id = id || uuid();
  }
}

export class SizeChartCell {
  isNew?: boolean;
  private _isUpdated = false;
  private _value: string;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._isUpdated = true;
    this._value = value;
  }

  get isUpdated(): boolean {
    if (this.isNew) {
      return false;
    }

    return this._isUpdated;
  }

  constructor(
    public readonly columnId: number | undefined,
    public readonly rowId: number | undefined,
    value = '',
    public id?: number,
  ) {
    this._value = value;
    this.isNew = !this.id;
    this.id = id || uuid();
  }
}

export class SizeChartEntry {
  id?: number;
  name = '';
  columns: SizeChartColumn[] = [];
  rows: SizeChartRow[] = [];
  rowsSaved: any[] = [];
  /**
   * Let`s assume that cells array is flat
   */
  cells: SizeChartCell[] = [];

  constructor() {
    //this.setData([new SizeChartColumn()], [new SizeChartRow()]);
    this.setData([new SizeChartColumn()], []);
  }

  /**
   * TODO: Think about implementation
   */
  get columnsOrdered() {
    return this.columns;
  }

  get rowsOrdered(): Array<{
    row: SizeChartRow,
    cells: SizeChartCell[]
  }> {
    const rows = this.getRowsOrdered();
    const columnsOrdered = this.columnsOrdered;
    const rowsSaved = rows.map(row => {
      const cellsForRow = this.cells.filter(r => r.rowId === row.id);
      const cellsOrdered = columnsOrdered.map(column => {
        let cell = cellsForRow.find(cellInner => {
          return cellInner.columnId === column.id;
        });

        if (!cell) {
          console.log('[cell error]', cell);
        }

        return cell;
      });

      return {
        row,
        cells: cellsOrdered,
      }
    });

    if (JSON.stringify(this.rowsSaved) !== JSON.stringify(rowsSaved)) {
      this.rowsSaved = rowsSaved;
    }

    return this.rowsSaved;
  }

  addColumn(column?: SizeChartColumn): void {
    const columnParams = column || new SizeChartColumn();
    const newCells = this.generateCellsFoColumn(columnParams);

    this.columns = [
      ...this.columns,
      columnParams,
    ];
    this.cells = [
      ...this.cells,
      ...newCells,
    ];
  }

  /**
   * Adds a new row by row size name
   *
   * @param newSizeName {string}
   */
  addRowByName = (newSizeName: string) =>
    this.addRow(new SizeChartRow(newSizeName, this.rows.length + 1));

  /**
   * Prevents duplicate rows names
   *
   * @param row {SizeChartRow}
   */
  addRow(row: SizeChartRow): void {
    if (!!this.rows.find(existingRow => existingRow.name === row.name))
      return;

    this.rows = [
      ...this.rows,
      row,
    ];
    this.cells = [
      ...this.cells,
      ...this.generateCellsFoRow(row),
    ];
  }

  removeColumn(column: SizeChartColumn): void {
    this.columns = this.columns.filter(c => c.id !== column.id);
    this.cells = this.cells.filter(c => c.columnId !== column.id);
  }

  removeRow(row: SizeChartRow): void {
    this.rows = this.rows.filter(r => r.id !== row.id);
    this.cells = this.cells.filter(r => r.rowId !== row.id);
  }

  setData(columnsGenerated: SizeChartColumn[], rowsGenerated: SizeChartRow[], cellsGenerated: SizeChartCell[] = []) {
    this.columns = columnsGenerated;
    this.rows = rowsGenerated;
    this.cells = cellsGenerated.length ? cellsGenerated : this.generateCells();
  }

  moveUp(row: SizeChartRow) {
    const rows = [...this.getRowsOrdered()];
    const index = rows.indexOf(row);

    if (index === 0) {
      return;
    }

    const orderNumber = row.orderNumber;
    row.orderNumber = rows[index - 1].orderNumber;
    rows[index - 1].orderNumber = orderNumber;
  }

  moveDown(row: SizeChartRow) {
    const rows = [...this.getRowsOrdered()];
    const index = rows.indexOf(row);

    if (index + 1 === rows.length) {
      return;
    }

    const orderNumber = row.orderNumber;
    row.orderNumber = rows[index + 1].orderNumber;
    rows[index + 1].orderNumber = orderNumber;
  }

  private readonly getRowsOrdered = () => {
    return orderBy(this.rows, ['orderNumber'], ['asc']);
  };

  private readonly generateCellsFoColumn = (column: SizeChartColumn): SizeChartCell[] => {
    return this.rows.map(row => {
      return new SizeChartCell(column.id, row.id);
    })
  };

  private readonly generateCellsFoRow = (row: SizeChartRow): SizeChartCell[] => {
    return this.columns.map(column => {
      return new SizeChartCell(column.id, row.id);
    })
  };

  private readonly generateCells = (): SizeChartCell[] => {
    return this.columns
      .map(column => {
        return this.generateCellsFoColumn(column);
      })
      .reduce((acc, val) => {
        return [...acc, ...val];
      }, []);
  }
}
