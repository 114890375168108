import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Subject, Subscription, bufferCount, concatMap, debounce, debounceTime, distinctUntilChanged, filter, interval, of, tap } from 'rxjs';
import { IHasProduct, IMightHaveId, NewProduct, ProductCustomer } from 'src/app/main/model/ddb.model';
import { HasId } from 'src/app/main/model/generics';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { CustomerProductService } from 'src/app/main/services/customerProduct.service';
import { ProductService } from 'src/app/main/services/product.service';

type ISortOption = {
  id: number;
  text: string;
  field: string;
};

type ISortOptions = ISortOption[];

@Component({
  selector: 'app-customer-products',
  templateUrl: './products.component.html',
  styleUrls: []
})
export class ProductsComponent {
  private customerId: number;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private pendingUpdates: (IMightHaveId & ProductCustomer & IHasProduct)[] = [];
  private updateProductCustomerRequests$ = new BehaviorSubject<null>(null);
  private selectedProduct: NewProduct;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchProductTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  @ViewChild('product') selectProduct: NgSelectComponent;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
    order: 'desc',
    limit: 50,
    include: [{
      association: 'product',
      required: true
    }],
  });

  public productQuery: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
    filter: {}
  });

  sortOptions: ISortOptions = [{
    id: 1,
    text: "Custom Price",
    field: "customPrice"
  }, {
    id: 2,
    text: "Id",
    field: 'id'
  }];

  queryResult: QueryResult<ProductCustomer & HasId<string>> = new QueryResult();
  productResult: QueryResult<HasId & NewProduct> = new QueryResult();
  public noItemsFoundText: string;
  isAlreadyChecked: boolean;
  constructor(
    public route: ActivatedRoute,
    private productCustomerService: CustomerProductService,
    private productService: ProductService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.customerId = parseInt(params.customerId);
      // this.loadCustomerProducts();
      this.loadProducts();
      this.search();
      this.productSearch();
    });

    this.updateProductCustomerRequests$.pipe(
      tap(() => { }),
      filter(() => this.pendingUpdates.length > 0),
      debounce(() => interval(1000))
    ).subscribe(() => {
      const updates = JSON.parse(JSON.stringify(this.pendingUpdates));
      this.pendingUpdates = [];
      of(...updates).pipe(
        concatMap((updatedCP: any) => {
          return this.productCustomerService.update(updatedCP.id.toString(), updatedCP);
        }),
        bufferCount(updates.length)
      ).subscribe(() => {
      });
    })
  }

  /**
 * @description Loads the customer user data from the API
 */
  loadCustomerProducts() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    this.query.filter['customerId'] = this.customerId;

    this.productCustomerService.list(this.query)
      .subscribe(queryResult => {
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadCustomerProducts();
        }
      });
  }

  loadProducts(isScroll: boolean = false) {
    this.noItemsFoundText = "Fetching...";
    if (isScroll) {
      this.productQuery.limit = this.productQuery.limit + 10;
    }
    this.productService.list(this.productQuery)
      .subscribe(queryResult => {
        if (queryResult.rows.length == 0) {
          this.noItemsFoundText = "No product found";
        }
        this.productResult = queryResult;
      });
  }

  onProductSearch(searchTerm: { term: string; items: any[]; }) {
    this.searchProductTerms.next(searchTerm.term);
  }

  productSearch() {
    this.searchProductTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.productQuery.filter['$or'] = {
          name: { $like: '%' + searchTerm + '%' },
          code: { $like: '%' + searchTerm + '%' },
          uri: { $like: '%' + searchTerm + '%' },
          description: { $like: '%' + searchTerm + '%' }
        }
      else
        delete this.productQuery.filter['$or'];
      this.loadProducts();
    });
  }


  queueUpdateProductCustomer(productCustomerIdx: number) {
    const updatedCustomerProduct = this.queryResult.rows[productCustomerIdx];
    if (updatedCustomerProduct.subsidyAmount === null) {
      updatedCustomerProduct.subsidyAmount = 0;
    }
    const updatedCPString = JSON.stringify(updatedCustomerProduct);
    const existingUpdate = this.pendingUpdates.findIndex(val => {
      if (val.id && updatedCustomerProduct.id && val.id === +updatedCustomerProduct.id) {
        return true;
      } else if (JSON.stringify(val) === updatedCPString) {
        return true;
      }
      return false;
    });

    const updatedCPClone = JSON.parse(updatedCPString);

    if (existingUpdate >= 0) {
      this.pendingUpdates[existingUpdate] = updatedCPClone;
    } else {
      this.pendingUpdates.push(updatedCPClone);
    }

    this.updateProductCustomerRequests$.next(null);
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadCustomerProducts()
  }

  /**
 * @description Ensures the page number is in sync across multiple pagination components
 *
 * @param {number} pageSize Broadcast pageSize value
 */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.loadCustomerProducts();
  }

  addSelectedProduct() {
    this.selectedProduct = this.selectProduct.selectedValues.length && this.selectProduct.selectedValues[0];
    if (this.selectedProduct.id && !this.queryResult.rows.find(product => this.selectedProduct.id && product.productId == +this.selectedProduct.id)) {
      const newProductCustomer: ProductCustomer & IHasProduct = {
        product: JSON.parse(JSON.stringify(this.selectedProduct)),
        productId: +this.selectedProduct.id,
        customPrice: null,
        subsidyAmount: 0,
        customerId: this.customerId
      };
      this.productCustomerService.create(newProductCustomer).subscribe({
        next: () => {
          this.loadCustomerProducts();
        }
      });
    } else {
      this.notifications.warn('Add Product', 'Prodct already added.Please choose another one');
    }
  }

  removeProductCustomer(productCustomerIdx: number) {
    const removedProductCustomer = this.queryResult.rows.splice(productCustomerIdx, 1);

    if (removedProductCustomer.length && removedProductCustomer[0].id) {

      const existingUpdate = this.pendingUpdates.findIndex(val => {
        if (val.id && removedProductCustomer[0].id && val.id === removedProductCustomer[0].id) {
          return true;
        }

        return false;
      });
      if (existingUpdate >= 0) {
        this.pendingUpdates.splice(existingUpdate, 1);
      }

      this.productCustomerService.delete(removedProductCustomer[0].id).subscribe({
        next: () => {
          this.loadCustomerProducts();
        }
      });
    }
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadCustomerProducts();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (this.query.include) {
        if (searchTerm && searchTerm.length) {
          this.query.include[0].filter = this.query.include[0].filter || {};
          this.query.include[0].filter['$or'] = {
            name: { $like: '%' + searchTerm + '%' },
            code: { $like: '%' + searchTerm + '%' },
          }
        } else {
          delete this.query.include[0].filter;
        }
      }
      this.loadCustomerProducts();
    });
  }
}
