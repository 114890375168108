import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { AppHeaderComponent } from '../../template/app-header/app-header.component';

@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: []
})
export class HomeComponent {
  public apiURL = environment.endpoint;
  @ViewChild(AppHeaderComponent) headerComponent!: AppHeaderComponent;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  slides = [
    {
      logo: "../../../../assets/images/bmd-logo 1.svg",
      images: [
        { img: "../../../../assets/images/BMD-Testimonial-1.png" },
        { img: "../../../../assets/images/BMD-Testimonial-2.png" },
        { img: "../../../../assets/images/BMD-Testimonial-3.png" },
      ],
      text: 'Previously using manual paper-based processes for uniform orders, BMD faced challenges like errors, delays, and signature acquisition issues. Since implementing SUMS, BMD has achieved significant improvements in cost savings and resource optimisation. The system efficiently manages garment allocations, anniversaries, and product swaps, ensuring better cost control and flexibility in uniform management between corporate and site wear.',
      user: 'Kristal Hallahan',
      subText: 'Procurement Team Leader'
    },
    {
      logo: "../../../../assets/images/workPack-logo.svg",
      images: [
        { img: "../../../../assets/images/Workpac-Testimonial-1.png" },
        { img: "../../../../assets/images/Workpac-Testimonial-2.png" },
        { img: "../../../../assets/images/Workpac-Testimonial-3.png" },
      ],
      text: "Before Reali's SUMS portal, Workpac Field Team members relied on verbal or email orders with their mobilisation team, leading to inefficiencies like back- and - forth communication, incorrect orders, and unnecessary stock ordering. SUMS enables FTMs to directly place uniform orders with Reali, cutting out intermediaries like the mobilisation team. Orders are tracked online, reducing spending, and ensuring prompt uniform delivery, boosting confidence in timely readiness for work. This streamlined process also lightens Workpac's administrative load, allowing for more efficient resource allocation to crucial mobilisation tasks.",
      user: 'Doug Ventham',
      subText: 'Head of Credit & Procurement'
    }
  ];
  slideConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },],
  };

  productRoute(isExplore = false) {
    if (this.checkAuthenticated()) {
      isExplore ? this.router.navigate(['/safety-uniform-management-system']) : this.router.navigate(['/products']);
    } else {
      isExplore ? this.router.navigate(['/safety-uniform-management-system']) : this.authService.setStickyMenu('login');
      this.navigateAbove();
    }
  }

  checkAuthenticated() {
    return this.authService.isAuthenticated();
  }

  navigateAbove() {
    window.scroll(0, 0);
  }

}
