import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICollection } from '../model/collection.model';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';

// Convenience Type
type T = ICollection;

@Injectable()
export class CollectionApi extends GenericApi<T> {
  public path = "collections";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  collectionList = (params): Observable<QueryResult<ICollection>> => {
    return this.httpClient.get<QueryResult<ICollection>>(
      createUrl(this.path),
      { params }
    );
  };
}
