<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container-fluid">
			<div class="product-list-head mb-45">
				<h4 class="main-title">
					Cart
					<span class="badge bg-alpha-25 px-3 py-1">
						{{ cartService.items.length }}
					</span>
				</h4>
			</div>
			<div class="cart-content">
				<app-cart-items
					[item]="cartService.items"
					(deleted)="handleItemDeleted($event)"
					[showPrice]="anyItemHasPrice()"
					(cartUpdate)="handelCartUpdate()"
				></app-cart-items>

				<div class="row justify-content-end">
					<div class="col-12 col-lg-4">
						<div class="card cart-total main-shadow">
							<div class="balance-content rounded-top-4">
								<ul>
									<li *ngIf="availableBalance > 0">
										<p>Balance</p>
										<p>{{ getBalanceText() }}</p>
									</li>
								</ul>
							</div>
							<div class="card-body">
								<ul>
									<li>
										<p>Subtotal</p>
										<p>
											${{
												utils.twoDecimalPlacesString(cartService.getSubtotal())
											}}
										</p>
									</li>
									<li>
										<p> Small Order Fee</p>
										<p>
											${{
												utils.twoDecimalPlacesString(
													cartService.getHandlingFee()
												)
											}}
										</p>
									</li>
									<li>
										<p>GST</p>
										<p>
											${{ utils.twoDecimalPlacesString(cartService.getTax()) }}
										</p>
									</li>
								</ul>
								<hr class="bg-dash" />
								<ul>
									<li>
										<p class="fw-bold">Grand Total</p>
										<p class="fw-bold">{{ getGrandTotalText() }}</p>
									</li>
								</ul>
								<div class="btns mt-4" *ngIf="canCheckout$ | async">
									<a
										(click)="checkout()"
										class="btn btn-primary w-100 gap-0"
										role="button"
									>
										Checkout
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="21"
												height="20"
												viewBox="0 0 21 20"
												fill="none"
											>
												<path
													d="M8.7002 13.6004L12.3002 10.0004L8.7002 6.40039"
													stroke="white"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
