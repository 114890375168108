// Instance of a customer from the Unleashed Application - Served through the RealiSupply Lambda
import { ShippingDetails } from './shippingDetail.model';
import { ISupportingDocument } from './supporting.document.model';
import { User } from "./user.model";
import { CustomerUserRolesInterface } from "./role.model";
import { ICustomerUser } from './customer.user.model';
import { DecorationOrProduct, IRequiredField, NewDecoration, ProductCustomer } from './ddb.model';
import { QueryResult } from './query.filter.class';
import { ICollectionCustomer } from './collection.model';

export type EmployeePurchaseMode = "credit-card" | "invoice" | "both"

export class UnleashedCustomer {
  id: number | null = null;
  name: string;
  code: string;
  Guid: string | null = null;
  CustomerCode: string | null = null;
  CustomerName: string | null = null;
  imageUrl: string | null = null;
  BusinessUnits: QueryResult<Array<BusinessUnit>> | Array<BusinessUnit>;
  Addresses: Array<ShippingDetails> = [];
  Products: QueryResult<Array<ProductCustomer>>;
  AssignedProductsOnly = false;
  HandlingFee = true;
  HandlingFeeAmount: number | null = null;
  HandlingFeeThreshold: number;
  HandlingFeeCoPay: number;
  PurchaseOrderPattern = '';
  search: string | null = null;
  reminders: any;
  RegisterEnabled: boolean = false;
  RegisterEmailPattern: string | null = null;
  RegisterUserRole: any = null;
  RegisterUserRoleId: number | null = null;
  RegisterSecret: string | null = null;

  /**
   * All futuer property names should be in normal casing so that
   * this class can ultimately be deprecated
   */
  employeePurchaseMode: EmployeePurchaseMode = "credit-card";
  supportingDocuments: ISupportingDocument[];
  customerUsers: ICustomerUser[];
  disableAddressEdit: boolean;
  showProductSearchCategories: boolean;
  showProductSearchCollections: boolean;
  enableBulkOrder: boolean;
  enableOrdersAllocation: boolean;
  defaultUri: string;
  redirectEmail: string;
  ccEmail: string | null = null;
  discountAmount: number = 0;
  enableOrdersRequireApproval: boolean = false;
  stopCredit: boolean = false;
  allowPackOrderNames: boolean = false;
  disable2faCheck: boolean = false;
  defaultWarehouse: string | null = null;
  obsolete: boolean = false;
  collections: ICollectionCustomer[];
  decorations: { decoration: DecorationOrProduct, customPrice: number }[];
  automaticCollectionSelection: boolean = false;
  enableUsersRequireApproval: boolean = false;
  requiredField: string;
  beforeSave = () => {
    this.search = (this.CustomerName + ' ' + this.CustomerCode).toLowerCase();
  }
}

export class UnleashedCustomerExtended extends UnleashedCustomer {
  CustomerUser: User | null;
  CustomerUserRole: CustomerUserRolesInterface | null;
  IsDefault: boolean | null;
}

export class UnleashedCustomerListResult {
  pagination: UnleashedPaginationData;
  items: Array<UnleashedCustomer> = [];
}

export class UnleashedCustomerListRequest {
  Pagination: UnleashedPaginationData;
  Items: Array<UnleashedCustomer> = [];
}
export class Reminder {
  id?: number;
  customerId: number;
  noOfUnit: number;
  unit: string;
}
export class BusinessUnit {
  id?: number;
  name: string;

  Guid: string;
  Name: string;
  code: string;
  // The ID of the user who is the contact for this cost center
  // DO NOT STORE USER DETAILS HERE else we will be forever having to
  // Update them every time a user is updated
  Contact: string;
  contact: any;
  CustomerId: string;
  ShippingDetailsId: string;
  ShippingDetails: ShippingDetails = new ShippingDetails();

  /**
   * 1:1 Mapping of properties until this class can be decprecated
   */
  contactId: number | null;

  beforeSave = () => {
    this.ShippingDetails.beforeSave();
  }
}

export class DocumentClass {
  id: number;
  userId: number;
  customerId: number;
  modifiedById: number | null;
  createdById: number | null;
  approvedById: number | null;
  isApproved: boolean;
  name: string;
  path: string;
  fileSize: number;
  mimeType: string;
}

export class UnleashedPaginationData {
  public NumberOfItems: number;
  public PageSize: number;
  public PageNumber: number;
  public NumberOfPages: number;

  constructor(fields?: {
    NumberOfItems: number,
    PageSize: number,
    PageNumber: number,
    NumberOfPages: number
  }) {
    if (fields) { Object.assign(this, fields) }
  }

  hasMorePages = () => {
    return (this.PageNumber < this.NumberOfPages);
  }
}

