import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { UnleashedApi } from '../api/unleashed.api';
import { UnleashedCustomerListRequest } from '../model/unleashed.model';
import { UnleashedProductListResult } from '../model/productListResult.model';

@Injectable()
export class UnleashedService {
  handleErrors(err: any) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private notifications: NotificationsService,
    private unleashedApi: UnleashedApi,
    private readonly raven: ErrorHandler
  ) {
  }

  public getCustomerById(customerGuid: string): Observable<any> {
    if (!customerGuid || !customerGuid.length) {
      return of(new Error("Invalid or Blank customerGuid"));
    }
    return apiCallWrapper(
      this.unleashedApi.getCustomerById(customerGuid),
      {
        notificationsService: this.notifications,
        action: "Fetching Customer From Unleashed"
      }
    )
  }

  public getWarehouseList(): Observable<any> {
    return apiCallWrapper(
      this.unleashedApi.getWarehouseList(),
      {
        notificationsService: this.notifications,
        action: "Fetching WareHouses"
      }
    )
  }

  public getSalesShipmentsByOrderNumber(orderNumber: string): Observable<any> {
    return apiCallWrapper(
      this.unleashedApi.getSalesShipmentsByOrderNumber(orderNumber),
      {
        notificationsService: this.notifications,
        action: "Fetching Shipment details"
      }
    )
  }

  readonly getUnleashedCustomers = (parameters: { [key: string]: any }): Observable<UnleashedCustomerListRequest> => {
    const param: any = {
      page: parameters.page || 1
    };

    if (parameters.term) {
      param.criteria = parameters.term;
    }

    return this.unleashedApi.getUnleashedCustomers(param)
      .pipe(
        catchError(err => {
          console.log('CommunicationService: An error was encountered when listing products from unleashed');

          this.handleErrors(err);

          return throwError(err)
        }),
        map(this.handleStandardResponse),
      );
  };

  private readonly handleStandardResponse = (response: any) => {
    // let json = JSON.parse(response.Payload.toString());
    const json = response;

    if (response === undefined)
      return;

    if (json.hasOwnProperty('hasError') && json.hasError === true) {
      console.log('An error occurred.');
      console.log(json);

      try {
        if (typeof json === 'object') {
          console.log(JSON.stringify(json));
        }
      } catch (e) {
        /**
         * Die Silently
         */
      }

      this.raven.handleError(new Error(json.messages[0].message));

      throw json.messages[0].message;
    } else if (json.hasOwnProperty('errorMessage')) {
      console.error('An unexpected Error occurred.');
      console.error(response.toString());
      this.raven.handleError(new Error(json['errorMessage']));

      throw json['errorMessage'];
    } else {
      return json;
    }
  };

  readonly getUnleashedProducts = (parameters: { [key: string]: any }): Observable<UnleashedProductListResult> => {
    return this.unleashedApi.getUnleashedProducts(parameters)
      .pipe(
        catchError(err => {
          console.log('CommunicationService: An error was encountered when listing products from unleashed');

          this.handleErrors(err);

          return throwError(err)
        }),
        map(this.handleStandardResponse),
      );
  };
}
