import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerUserService } from '../services/customerUser.service';
import { CustomerService } from '../services/customer.service';
import { IQueryFilter } from '../model/query.filter.class';
import { SecurityService } from '../services/security.service';
import { Subject, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { query } from '@angular/animations';

type ReportAccess = {
  [key: string]: {
    permission: string,
    access: boolean
  }
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent {
  public customerSelectOptions: { id: string, text: string }[];
  public isAdmin: boolean = false;
  pathBase = '/account';
  /**
   * @description Temporary holding for the selected customer option
   */
  selectedCustomerOption: string;
  selectedCustomerText: string;

  access: ReportAccess = {
    allocationReport: {
      permission: 'report_allocation',
      access: false
    },
    userListReport: {
      permission: 'report_user_list',
      access: false
    },
    orderReport: {
      permission: 'report_order',
      access: false
    },
    pendingOrderReport: {
      permission: 'report_order',
      access: false
    },
    businessUnitReport: {
      permission: 'report_business_unit',
      access: false
    },
    overspendReport: {
      permission: 'report_overspend',
      access: false
    }
  };

  private searchTerms: Subject<string> = new Subject<string>();
  public customerQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  })
  @ViewChild('customer') selectCustomer: NgSelectComponent;
  public noCustomerFoundText: string;
  constructor(
    private readonly customerUserService: CustomerUserService,
    public router: Router,
    public route: ActivatedRoute,
    private customerService: CustomerService,
    public securityService: SecurityService
  ) { }

  ngOnInit() {
    this.selectedCustomerOption = this.route.snapshot.queryParams.customerId || null;
    this.selectedCustomerText = this.route.snapshot.queryParams.name || null;

    Object.keys(this.access).forEach(key => {
      this.securityService.isAdmin$().pipe(
        distinctUntilChanged(),
        switchMap(isAdmin => {
          if (isAdmin) {
            this.isAdmin = isAdmin;
          }
          this.pathBase = isAdmin ? '/manage' : '/account';

          if (isAdmin) {
            return of(isAdmin);
          }

          return this.securityService.isLoggedInWithPermission(this.access[key].permission).pipe(
            distinctUntilChanged()
          );
        })
      )
        .subscribe(hasAccess => {
          this.access[key].access = hasAccess;
        })
    });

    if (this.securityService.isAdmin()) {
      this.loadCustomers();
    }
    this.search();
  }

  onCustomerSearch(searchTerm: { term: string; items: any[]; }) {
    this.searchTerms.next(searchTerm.term);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
      this.loadCustomers();
    });
  }

  loadCustomers(isScroll: boolean = false) {
    this.noCustomerFoundText = "Fetching...";
    if (isScroll) {
      this.customerQuery.limit = this.customerQuery.limit + 10;
    }

    this.customerService.list(this.customerQuery)
      .subscribe((result) => {
        if (result.rows.length == 0) {
          this.noCustomerFoundText = "No customers found";
        }
        this.customerSelectOptions = [
          ...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }))
        ];
      });
  }

  customerChange() {
    if (this.selectCustomer.selectedValues.length) {
      this.selectedCustomerOption = this.selectCustomer.selectedValues[0].id;
      this.selectedCustomerText = this.selectCustomer.selectedValues[0].text;
    }
  }

  getCustomerName() {
    return this.selectedCustomerText;
  }


  onClear() {
    delete this.customerQuery.filter.name;
    this.loadCustomers();
  }

}
