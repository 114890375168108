<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<nav aria-label="breadcrumb" class="pageBreadcrumb mb-45">
				<ol class="breadcrumb mb-0">
					<li class="breadcrumb-item">
						<a [routerLink]="['/home']">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M3.25 7.75L10 2.5L16.75 7.75V16C16.75 16.3978 16.592 16.7794 16.3107 17.0607C16.0294 17.342 15.6478 17.5 15.25 17.5H4.75C4.35218 17.5 3.97064 17.342 3.68934 17.0607C3.40804 16.7794 3.25 16.3978 3.25 16V7.75Z"
									stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M7.75 17.5V10H12.25V17.5" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						All products
					</li>
				</ol>
			</nav>

			<div class="product-list-head mb-45">
				<h4 class="main-title">All Products <span *ngIf="checkAuthenticated()">({{totalProductCount }})</span></h4>
				<div class="head-filter">
					<button *ngIf="showCategories || showCollections" class="btn btn-secondary shadow-none bg-transparent"
						type="button" (click)="checkFilter()">
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path d="M16 16.75V13" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M13.75 13H18.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M4 16.75V11.5" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M1.75 11.5H6.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M10 16.75V10" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M7.75 7H12.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M16 10V3.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M10 7V3.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M4 8.5V3.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</span>
						{{ !isOpen ? "Show" : "Hide" }} filters
					</button>
					<div class="sorted">
						<h5>Sort by:</h5>
						<div class="dropdown">
							<button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								{{ sortText }}
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#36394A" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
							</button>
							<ul class="dropdown-menu">
								<li *ngFor="let item of sortOptions" (click)="updateSortField(item.field)">
									<a class="dropdown-item">{{ item.text }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="category-product-list">
				<div class="category-filter" [class.active]="isOpen">
					<div>
						<div class="d-flex align-items-start justify-content-between">
							<h4 class="title" *ngIf="showCategories">Filters</h4>
							<button (click)="isOpen = false" class="btn btn-secondary-icon shadow-none py-0 d-flex d-lg-none">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</button>
							<button class="btn btn-secondary-icon shadow-none py-0 d-none d-lg-flex"
								(click)="handleCategoryClick(undefined, undefined, true, true)" *ngIf="primaryCategory">
								Clear Filter
							</button>
						</div>

						<div class="d-flex align-items-center justify-content-between mb-4" *ngIf="queryParams.collection">
							<h4 class="title mb-0">Filltering By Collections</h4>
							<button class="btn btn-secondary shadow-none py-0" (click)="removeQueryParams('collection')">
								Clear Filter
							</button>
						</div>

						<div class="main-filters-column">
							<div class="w-100" *ngIf="showCategories">
								<h5 class="type">CATEGORY</h5>
								<div class="filters">
									<div class="accordion" id="FilterCategory" *ngFor="let category of getCategoriesByParent()">
										<div class="accordion-item border-0">
											<h2 class="accordion-header">
												<button #subButton class="accordion-button collapsed"
													[class.no-sub]="!hasChildCategories(category)" type="button" data-bs-toggle="collapse"
													[attr.data-bs-target]="'#filterOne' + category.id" aria-expanded="false"
													aria-controls="filterOne" (click)="
														handleCategoryClick(category.name, undefined, true)
													" [class.collapsed]="actualCategory !== category.name">
													{{ category.name }}
													<span>{{ category.count || 0 }}</span>
												</button>
											</h2>
											<div *ngIf="hasChildCategories(category)" [id]="'filterOne' + category.id"
												class="accordion-collapse collapse" [class.show]="checkCategoryName(category.name)"
												data-bs-parent="#FilterCategory">
												<div class="accordion-body">
													<ul>
														<li *ngFor="
																let category1 of getCategoriesByParent(category)
															">
															<div class="form-check">
																<input #subCategory class="form-check-input" type="radio" name="opop"
																	id="{{ primaryCategory }}{{ category1.id }}" (click)="
																		handleCategoryClick(
																			category.name,
																			category1.name,
																			subCategory.checked
																		)
																	" [class.active-check]="
																		checkSecondCategory(category1.name)
																	" />
																<label class="form-check-label" for="{{ primaryCategory }}{{ category1.id }}">
																	{{ category1.name }}
																	<span>{{ category1.count || 0 }}</span>
																</label>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="w-100" *ngIf="showCollections && collections.rows.length">
								<h5 class="type">COLLECTIONS</h5>
								<div class="filters">
									<ul>
										<li *ngFor="let collection of collections.rows">
											<div class="form-check">
												<input class="form-check-input" type="radio" name="collection"
													id="Collection {{ collection.id }}" [routerLink]="['/products']"
													[queryParams]="{ collection: collection.id }" (click)="handleCollectionClick(collection)"
													[checked]="queryParams.collection == collection.id" />
												<label class="form-check-label" for="Collection {{ collection.id }}">
													{{ collection.name }}
													<!-- <span>{{collection.products.length}}</span> -->
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="filter-bottom d-block d-lg-none">
						<div class="btns d-flex flex-column">
							<button class="btn btn-secondary w-100" type="button" (click)="
									handleCategoryClick(undefined, undefined, true, true);
									isOpen = false
								" *ngIf="primaryCategory">
								Clear filters
							</button>
							<button (click)="isOpen = false" class="btn btn-primary w-100" type="button">
								Show Results
							</button>
						</div>
						<div class="swipe-tilt">
							<div class="tilt"></div>
						</div>
					</div>
				</div>
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</section>