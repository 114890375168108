import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, filter, from, of, switchMap, tap } from "rxjs";
import { AuthService } from "../services/auth.service";
import { logger } from "../util/Logger";
import { SessionApi } from "../api/session.api";
import { SecurityService } from "../services/security.service";

const className = "AuthGuard";


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
		private readonly session: SessionApi,
		private readonly securityService: SecurityService
  ) {
  }

  canActivate(): Observable<boolean> {
    const signature = className + '.canActivate: ';

		return this.session.$sessionChanged.pipe(
			filter(ready => ready),
			switchMap(() => this.securityService.isAuthenticated()),
			tap(isAuthenticated => {
				if (!isAuthenticated) {
					logger.silly(signature + `Redirecting unauthenticated user user to home`);
					this.router.navigate(['']);
				}
			})
		);
  }
}
