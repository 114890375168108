import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { DecorationApi } from '../api/decoration.api';
import { NewDecoration } from '../model/ddb.model';
import { DecorationDraft } from '../model/decoration.model';

@Injectable()
export class DecorationService {
  constructor(
    private notifications: NotificationsService,
    private decorationApi: DecorationApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<NewDecoration>> {
    return apiCallWrapper(
      this.decorationApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Decorations"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.decorationApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Decoration"
      }
    )
  }

  public get(id: number): Observable<Partial<NewDecoration>> {
    return apiCallWrapper(
      this.decorationApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Decoration By Id"
      }
    )
  }

  public update(id: number, data: NewDecoration) {
    return apiCallWrapper(
      this.decorationApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Update Decoration"
      }
    )
  }

  public create(data: NewDecoration) {
    return apiCallWrapper(
      this.decorationApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create Decoration"
      }
    )
  }

  public getDecorationDraft(id?: number) {
    return apiCallWrapper(
      this.decorationApi.getDecorationDraft(id),
      {
        notificationsService: this.notifications,
        action: "Fetching DecorationDraft By Id"
      }
    )
  }

}
