import { Component } from '@angular/core';
import { ReportService } from '../../services/report.service';
import moment from 'moment-mini';

@Component({
	selector: 'abnormal-cart-report',
	templateUrl: 'abnormal-cart-report.component.html',
	styleUrls: ['abnormal-cart-report.component.scss']
})
export class AbnormalCartReportComponent {
	public isDataLoaded: boolean;
	public reportData: any = [];

	constructor(
		public reportService: ReportService
	) { }

	ngOnInit() {
		this.loadReportData();
	}

	loadReportData() {
		this.reportData = []
		this.reportService.getAbnormalCartAdminReport().subscribe(data => {
			this.reportData = data;
			this.isDataLoaded = true;
		});
	}

	getOrderDate(orderedAt: string | null | undefined): string {
		if (!orderedAt) { return ''; }

		return moment(orderedAt).local().format('DD MMM, YYYY');
	}

	/**
	 * @description Exports the current contents of reportData to CSV
	 */
	downloadFile() {
		this.reportService.createCSV({ data: {}, type: 'abnormalCart' }).subscribe();
	}

	getInitials(name: string): string {
		if (!name) return '';

		const nameWithoutDetails = name.split(/[()\s]/)[0].trim();
		const nameParts = nameWithoutDetails.split(' ');
		const initialsArray: string[] = [];
		for (const part of nameParts) {
			const initial = part.charAt(0).toUpperCase();
			initialsArray.push(initial);
		}
		const initials = initialsArray.join('');

		return initials;
	}

}
