<!--- ViewPort -->
<div>
  <app-header></app-header>
  <div class="main-content">
    <div>
      <app-menu></app-menu>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
  <!-- <app-footer *ngIf="showFooter"></app-footer> -->
</div>