import { Component } from '@angular/core';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { EnumCreateParams, NewDecoration } from '../../model/ddb.model';
import { DecorationService } from '../../services/decoration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-decoration-list',
  templateUrl: './decoration-list.component.html',
  styleUrls: []
})
export class DecorationListComponent {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name'
  });

  categories: EnumCreateParams[];

  sortOptions = [{
    id: 1,
    text: "Decoration Name",
    field: "name"
  }, {
    id: 2,
    text: "Price",
    field: "basePrice"
  }];

  queryResult: QueryResult<NewDecoration> = new QueryResult();
  isAlreadyChecked: boolean;

  constructor(
    private decorationService: DecorationService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private route: ActivatedRoute
  ) {
    // this.route.queryParams.subscribe(queryparams => {
    //   this.currentPageActive = +queryparams['page'] || 1;
    //   this.query.limit = +queryparams['pageSize'] || 10;
    //   this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    //   this.loadDecorations();
    // });
    this.search();
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadDecorations()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.loadDecorations();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadDecorations();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  loadDecorations() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.decorationService.list(this.query)
      .subscribe(queryResult => {
        this.totalCountActive = queryResult.count;
        this.queryResult = queryResult;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadDecorations();
        }
      });
  }

  /**
 * @description Archives a product
 *
 * @param {string} productId
 */
  deleteDecoration(decorationId: number | undefined) {
    if (decorationId) {
      this.decorationService.delete(decorationId).subscribe(() => {
        this.loadDecorations();
      })
    }
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.name = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.name;

      this.loadDecorations();
    });
  }

  public openRemoveModal(decorationId: number | undefined,) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Decoration';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Decoration</h4>
					<p class="desc">Would you like to remove this decoration?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && decorationId) {
          this.notifications.warn('Processing', 'Deleting the decoration.');
          this.deleteDecoration(decorationId);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

}
