import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable} from 'rxjs';

@Injectable()
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private show() {
    this.loadingSubject.next(true);
  }

  private hide() {
    this.loadingSubject.next(false);
  }

  // This method takes an observable, shows the loader, and hides it when done
  blockWithLoadingOverlayRx<T>(observable: Observable<T>): Observable<T> {
    this.show();
    return observable.pipe(finalize(() => this.hide()));
  }
}
