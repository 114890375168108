import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UnleashedCustomer } from '../../model/unleashed.model';
import { CollectionService } from '../../services/collection.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { Subject, Subscription, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { ICollection } from '../../model/collection.model';
import { SessionApi } from '../../api/session.api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-collection-list',
  templateUrl: './list.component.html',
  styleUrls: []
})
export class CollectionListComponent implements OnChanges, OnInit, OnDestroy {
  @Input() customer: UnleashedCustomer;
  isCustomer: boolean = false;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  public customerId: number;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name'
  });

  sortOptions = [{
    id: 1,
    text: "Collection Name",
    field: "name"
  }];
  isLoading: boolean;
  queryResult: QueryResult<ICollection> = new QueryResult();
  public isAdmin: boolean;
  private unsubscribe$ = new Subject<void>();
  isAlreadyChecked: boolean;
  constructor(
    private collectionService: CollectionService,
    private session: SessionApi,
    private router: Router,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.session.$userData.pipe(takeUntil(this.unsubscribe$)).subscribe((userData) => {
      if (!userData?.isAdmin) {
        if (userData?.customerUsers && userData?.customerUsers?.length > 0) {
          this.isAdmin = false;
          this.customerId = userData?.customerUsers[0].customerId;
        }
      } else {
        this.isAdmin = true;
      }
    });
    this.route.queryParams.subscribe(queryparams => {
      this.currentPageActive = +queryparams['page'] || 1;
      this.query.limit = +queryparams['pageSize'] || 10;
      this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
      // this.loadCollections();
    });
    this.search();
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadCollections()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadCollections();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadCollections();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  ngOnChanges(): void {
    if (this.customer) {
      this.isCustomer = true;
      if (this.customer.id) {
        this.customerId = this.customer.id;
        this.query.filter.customerId = this.customerId;
        this.loadCollections();
      }
    }
  }

  loadCollections() {
    this.isLoading = true;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.collectionService.list(this.query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(queryResult => {
        this.isLoading = false;
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadCollections();
        }
      });
  }

  deleteCollections(collId: number | undefined) {
    if (collId) {
      this.collectionService.delete(collId).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.loadCollections();
      });
    }
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.name = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.name;
      this.currentPageActive = 1;

      this.loadCollections();
    });
  }

  viewCollection = (collection: ICollection) => {
    this.router.navigate(['products'], {
      queryParams: {
        collection: collection.id
      }
    });
  }

  public openRemoveModal(collId: number | undefined) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Collection';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Collection</h4>
					<p class="desc">Would you like to remove this collection?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && collId) {
          this.deleteCollections(collId);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
