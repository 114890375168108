import { Injectable } from '@angular/core';
import { omit, pick, has, result } from 'lodash';
const R = require('ramda');
import { isNullOrUndefined } from 'util';
import { BusinessUnit, UnleashedCustomer } from '../model/unleashed.model';
import { CustomerUserRolesInterface, IRole } from '../model/role.model';
import { ShippingDetails } from '../model/shippingDetail.model';
import { User } from '../model/user.model';
import { SizeChartCell, SizeChartColumn, SizeChartEntry, SizeChartRow } from "../model/sizeChart.model";

@Injectable()
export class ConversionService {
  convertToCustomer(obj: any, userRoles: CustomerUserRolesInterface[] = []): UnleashedCustomer {
    const value = new UnleashedCustomer();

    value.id = obj.id;
    value.AssignedProductsOnly = obj.assignedProductsOnly;
    value.CustomerCode = obj.code;
    value.HandlingFee = obj.handlingFee;
    value.HandlingFeeAmount = obj.handlingFeeAmount || null;
    value.HandlingFeeThreshold = obj.handlingFeeThreshold || null;
    value.HandlingFeeCoPay = obj.handlingFeeCoPay || null;
    value.Guid = obj.guid || null;
    value.imageUrl = obj.imageUrl;
    value.CustomerName = obj.name;
    value.PurchaseOrderPattern = obj.purchaseOrderPattern;
    value.RegisterEmailPattern = obj.registerEmailPattern;
    value.RegisterEnabled = obj.registerEnabled;
    value.RegisterSecret = obj.registerSecret;
    value.RegisterUserRole = !obj.registerUserRoleId || !userRoles.length
      ? null
      : userRoles.find(u => u.id === obj.registerUserRoleId)?.name;
    value.RegisterUserRoleId = obj.registerUserRoleId;
    value.search = obj.search;
    value.reminders = obj.reminders || []
    /* The following properties should match 1-1 */
    value.employeePurchaseMode = obj.employeePurchaseMode;
    value.supportingDocuments = obj.supportingDocuments || [];
    value.disableAddressEdit = obj.disableAddressEdit;
    value.showProductSearchCategories = obj.showProductSearchCategories;
    value.showProductSearchCollections = obj.showProductSearchCollections;
    value.enableBulkOrder = obj.enableBulkOrder;
    value.enableOrdersAllocation = obj.enableOrdersAllocation;
    value.enableOrdersRequireApproval = obj.enableOrdersRequireApproval;
    value.defaultUri = obj.defaultUri;
    value.defaultWarehouse = obj.defaultWarehouse;
    value.redirectEmail = obj.redirectEmail;
    value.ccEmail = obj.ccEmail;
    value.stopCredit = obj.stopCredit;
    value.allowPackOrderNames = obj.allowPackOrderNames;
    value.discountAmount = this.typeSafeNumberOr(obj.discountAmount, 0);
    value.disable2faCheck = obj.disable2faCheck;
    value.obsolete = obj.obsolete;
    value.automaticCollectionSelection = obj.automaticCollectionSelection;
    value.enableUsersRequireApproval = obj.enableUsersRequireApproval;
    value.requiredField = obj.requiredField;
    return value;
  }

  convertFromCustomer(model: UnleashedCustomer): object {
    const obj: { [key: string]: any } = {};

    obj.guid = model.Guid;
    obj.assignedProductsOnly = model.AssignedProductsOnly;
    obj.code = model.CustomerCode;
    obj.handlingFee = model.HandlingFee;
    obj.handlingFeeAmount = model.HandlingFeeAmount ? Number(model.HandlingFeeAmount) : null;
    obj.handlingFeeThreshold = model.HandlingFeeThreshold ? Number(model.HandlingFeeThreshold) : null;
    obj.handlingFeeCoPay = model.HandlingFeeCoPay ? Number(model.HandlingFeeCoPay) : null;
    obj.imageUrl = model.imageUrl;
    obj.name = model.CustomerName;
    obj.purchaseOrderPattern = model.PurchaseOrderPattern || null;
    obj.registerEmailPattern = model.RegisterEmailPattern;
    obj.registerEnabled = model.RegisterEnabled;
    obj.registerSecret = model.RegisterSecret;
    obj.registerUserRoleId = model.RegisterUserRoleId;
    obj.search = model.search;
    obj.reminders = model.reminders || []
    /* The following properties should match 1-1 */
    obj.employeePurchaseMode = model.employeePurchaseMode;
    obj.supportingDocuments = model.supportingDocuments || [];
    obj.disableAddressEdit = model.disableAddressEdit;
    obj.showProductSearchCategories = model.showProductSearchCategories;
    obj.showProductSearchCollections = model.showProductSearchCollections;
    obj.enableBulkOrder = model.enableBulkOrder;
    obj.enableOrdersRequireApproval = model.enableOrdersRequireApproval;
    obj.stopCredit = model.stopCredit;
    obj.allowPackOrderNames = model.allowPackOrderNames;
    obj.enableOrdersAllocation = model.enableOrdersAllocation;
    obj.disable2faCheck = model.disable2faCheck;
    obj.defaultUri = model.defaultUri;
    obj.redirectEmail = model.redirectEmail;
    obj.ccEmail = model.ccEmail;
    obj.discountAmount = this.typeSafeNumberOr(model.discountAmount, 0);
    obj.defaultWarehouse = model.defaultWarehouse;
    obj.obsolete = model.obsolete;
    obj.automaticCollectionSelection = model.automaticCollectionSelection;
    return obj;
  }

  private typeSafeNumberOr<T = number | null | undefined>(val: any, defaultVal: T): number | T {
    if (!isNullOrUndefined(val) && !isNaN(Number(val)))
      return Number(val);

    return defaultVal;
  }

  convertToShippingDetails(obj: any): ShippingDetails {
    const value = new ShippingDetails();

    value.Guid = obj.id;
    value.AddressName = obj.addressName;
    value.StreetAddress = obj.streetAddress;
    value.StreetAddress2 = obj.streetAddress2;
    value.Suburb = obj.suburb;
    value.City = obj.city;
    value.Region = obj.region;
    value.PostalCode = obj.postalCode;
    value.Country = obj.country;

    return value;
  }

  convertFromShippingDetails(obj: ShippingDetails, isNestedElement = false): any {
    const value: { [key: string]: any } = {};

    if (isNestedElement && obj.Guid) {
      value.id = obj.Guid;
    }

    value.addressName = obj.AddressName;
    value.streetAddress = obj.StreetAddress;
    value.streetAddress2 = obj.StreetAddress2;
    value.suburb = obj.Suburb;
    value.city = obj.City;
    value.region = obj.Region;
    value.postalCode = obj.PostalCode;
    value.country = obj.Country;

    if (Object.keys(value).every((key) =>
      isNullOrUndefined(value[key]) || (typeof value[key] === 'string' && value[key].length === 0)
    )) {
      return null;
    }

    return value;
  }

  convertToCustomerUser(obj: any, userRoles: IRole[] = [], businessUnits: BusinessUnit[] = []): User {
    const value = new User();

    value.UserID = obj.id;
    value.CustomerID = obj.customerId;
    value.firstName = obj.user.firstName;
    value.lastName = obj.user.lastName;
    value.email = obj.user.email;
    value.ContactNumber = obj.user.contactNumber;
    value.jobTitle = obj.user.jobTitle;
    value.enableOrdersRequireApproval = obj.enableOrdersRequireApproval;
    value.startDate = obj.user.startDate;

    if (obj.user && obj.user.shippingDetails) {
      value.ShippingDetails = this.convertToShippingDetails(obj.user.shippingDetails);
    }

    value.CostCenter = obj.costCenter;
    value.ApprovalLimit = obj.approvalLimit;
    let userRole: IRole | null | undefined = obj.userRoleId ? userRoles.find(u => u.id === obj.userRoleId) : null;
    if (!userRole && obj.userRole) {
      userRole = obj.userRole;
    }
    value.Role = !obj.userRoleId || !userRoles.length
      ? null
      : userRole && userRole.name || null;
    value.IsAdmin = obj.isAdmin;
    value.IsGuest = obj.isGuest;
    value.BusinessUnit = !obj.businessUnitId || !businessUnits.length
      ? null
      : result(businessUnits.find(u => u.Guid === obj.businessUnitId), 'Name', null);

    if (obj.lastLoginAt) {
      value.lastLoginAt = obj.lastLoginAt ? new Date(obj.lastLoginAt) : null;
    } else {
      value.lastLoginAt = obj.user.lastLoginAt ? new Date(obj.user.lastLoginAt) : null;
    }

    if (obj.costAccount)
      value.CostAccount = obj.costAccount;

    if (obj.employeeNumber)
      value.EmployeeNumber = obj.employeeNumber;

    // This has become a dangerous property already. ActualUserId refers to the integer ID of the user model
    // When actualUser is only not-null when the user calling the application is spoofing another user.
    // Despite their similar names they do two different things and this needs to be fixed
    value.actualUserId = obj.userId;
    value.actual = obj.actual;

    /** 1:1 Mappings until eventually these two objects are the same and this shit can be removed */
    value.approverId = obj.approverId;
    value.businessUnitId = obj.businessUnitId;
    value.roleId = obj.userRoleId;
    value.startDate = obj.startDate;

    if (has(obj, 'userAllocations')) {
      value.userAllocations = obj.userAllocations;
    } else {
      value.userAllocations = [];
    }

    return value;
  }

  convertFromCustomerUser(model: User): object {
    const obj: { [key: string]: any } = {};
    obj.customerId = Number(model.CustomerID);
    obj.user = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      contactNumber: model.ContactNumber,
      jobTitle: model.jobTitle
    }

    obj.shippingDetails = this.convertFromShippingDetails(model.ShippingDetails, true);
    obj.approverId = model.approverId;

    const approvalLimit = Number(model.ApprovalLimit);
    const hasValidApproval = !isNaN(approvalLimit) && approvalLimit > 0;
    obj.approvalLimit = hasValidApproval ? approvalLimit : null;
    obj.enableOrdersRequireApproval = model.enableOrdersRequireApproval

    obj.userRoleId = model.roleId;
    obj.businessUnitId = model.businessUnitId || null;
    obj.isGuest = model.IsGuest;

    if (model.CostAccount)
      obj.costAccount = model.CostAccount;

    if (model.startDate)
      obj.startDate = model.startDate;

    if (model.EmployeeNumber)
      obj.employeeNumber = model.EmployeeNumber;

    return obj;
  }

  convertToAdmin(obj: any): User {
    const value = new User();

    value.UserID = obj.id;
    value.firstName = obj.firstName;
    value.lastName = obj.lastName;
    value.email = obj.email;
    value.ContactNumber = obj.contactNumber;
    value.jobTitle = obj.jobTitle;
    value.startDate = obj.startDate;
    value.IsAdmin = obj.isAdmin;
    value.lastLoginAt = obj.lastLoginAt ? new Date(obj.lastLoginAt) : null;
    value.actual = obj.actual;
    value.actualUserId = obj.id;
    value.permissions = obj.permissions;
    value.adminRoleId = obj.adminRoleId;
    value.adminRole = obj.adminRole
    value.SecureLoginType = obj.secureLoginType;

    return value;
  }

  convertFromAdmin(model: User): object {
    const obj: { [key: string]: any } = {};

    obj.firstName = model.firstName;
    obj.lastName = model.lastName;
    obj.email = model.email;
    obj.contactNumber = model.ContactNumber;
    obj.jobTitle = model.jobTitle;
    obj.startDate = model.startDate;
    obj.permissions = model.permissions;
    obj.adminRoleId = model.adminRoleId;
    obj.secureLoginType = model.SecureLoginType;
    return obj;
  }

  convertFromCustomerUserGuest(model: User): object {
    const obj: { [key: string]: any } = {};

    if (model.email) {
      obj.email = model.email;
    }

    obj.customerId = Number(model.CustomerID);
    obj.approvalLimit = Number(model.ApprovalLimit);
    obj.userRoleId = model.roleId;
    obj.businessUnitId = model.businessUnitId || null;

    if (model.CostAccount)
      obj.costAccount = model.CostAccount;

    if (model.EmployeeNumber)
      obj.employeeNumber = model.EmployeeNumber;

    /** 1:1 Mapping until eventually these are the same and can be removed */
    obj.approverId = model.approverId;

    return obj;
  }

  convertToBusinessUnit(obj: any): BusinessUnit {
    const value = new BusinessUnit();

    value.Guid = obj.id;
    value.name = obj.name;
    value.code = obj.code;
    value.Contact = obj.contactId;
    value.CustomerId = obj.customerId;
    value.ShippingDetailsId = obj.shippingDetailsId;

    if (obj.shippingDetails) {
      value.ShippingDetails = this.convertToShippingDetails(obj.shippingDetails);
    }

    /** 1:1 property mapping until this class can be deprecated */
    value.contactId = obj.contactId;

    return value;
  }

  convertFromBusinessUnit(model: BusinessUnit): object {
    const obj: { [key: string]: any } = {};

    obj.name = model.Name;
    obj.code = model.code;
    obj.customerId = model.CustomerId ? Number(model.CustomerId) : null;
    obj.shippingDetails = this.convertFromShippingDetails(model.ShippingDetails, true);

    /** 1:1 property mapping until this class can be deprecated */
    obj.contactId = model.contactId;

    return obj;
  }

  convertFromSizeChart(model: SizeChartEntry): any {
    const modelProcess = omit(model, 'id', 'columnsOrdered', 'rowsOrdered');
    modelProcess.cells = modelProcess.cells.map(R.curryN(2, pick)(R.__, ['isNew', 'value', 'isUpdated', 'columnId', 'rowId', 'id']));
    modelProcess.rows = modelProcess.rows.map(R.curryN(2, pick)(R.__, ['isNew', 'name', 'isUpdated', 'isNew', 'orderNumber', 'id']));
    modelProcess.columns = modelProcess.columns.map(R.curryN(2, pick)(R.__, ['isNew', 'isUpdated', 'subtitle', 'title', 'isNew', 'id']));

    return modelProcess;
  }

  convertToSizeChart(model: any): SizeChartEntry {
    const obj = new SizeChartEntry();
    obj.name = result(model, 'name', '');
    const rows = result(model, 'sizeChartRows', [{
      name: '',
      orderNumber: 1,
      id: undefined
    }]);
    const cells: any = result(model, 'sizeChartCells', []);
    const columns = result(model, 'sizeChartColumns', [{
      title: '',
      subtitle: '',
      id: undefined
    }]);
    const cellsGenerated = cells.map(c => new SizeChartCell(
      c.columnId,
      c.rowId,
      c.value,
      c.id
    ));
    const rowsGenerated = rows.map(r => new SizeChartRow(
      r.name,
      r.orderNumber,
      r.id
    ));
    const columnsGenerated = columns.map(c => new SizeChartColumn(
      c.title,
      c.subtitle,
      c.id,
    ));

    obj.setData(columnsGenerated, rowsGenerated, cellsGenerated);

    obj.id = model.id;

    return obj;
  }
}
