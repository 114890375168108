import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand } from '../model/ddb.model';

// Convenience Type
type T = Brand;

@Injectable()
export class BrandApi extends GenericApi<T> {
  public path = "brand";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
