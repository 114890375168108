export function legacyFeatures(features: string) {
	try {
		return JSON.parse(features);
	} catch(e) {
		// Leacy format features
		return features
			.trim()
			.replace(/<\/?((li)|(ul))[^>]*>/g, '\n|\n')
			.split('\n|\n')
			.map(line => line.replace(/<[^>]+>/g, '').trim())
			.filter(line => line !== '');
	}
}

export function generateRandomId(length: number): string {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let id = '';
	for (let i = 0; i < length; i++) {
			id += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return id;
}
