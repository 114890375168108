import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { CustomerService } from "src/app/main/services/customer.service";

@Component({
  selector: 'customer-user-field',
  styles: [`
      .uploads-container input[type=file] {
        display: none;
      }
    `],
  template: `
  <div class="edit-card card">
  <div class="card-head">
    <h4 class="head-title">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
            stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </span>
      Add Custom Field
    </h4>
  </div>
  <div class="card-body">
    <div class="row" *ngFor="let field of userField; let i = index">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="Name" class="form-label">Name</label>
          <input type="text" name="fieldName" class="form-control" id="Name" placeholder="" [value]="field.fieldName"
            Disabled />
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="form-group">
          <label for="Type" class="form-label">Type {{field.fieldType}}
          </label>

            <select class="form-select" Disabled>
            <option [selected]="field.fieldType == 'number'" value="number">Number</option>
            <option [selected]="field.fieldType == 'text'" value="text">Text</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-1 align-self-end">
        <div class="form-group">
          <button (click)="deleteInputSet(field.id)" class="btn btn-secondary-icon" type="button">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
                  stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group form-check">
           <label for="req" class="form-check-label">Field is Required</label>
          <input type="checkbox" [checked]="field.fieldRequired" name="req" class="form-check-input" id="req" placeholder="" [value]="field.fieldRequired"
            Disabled />
        </div>
      </div>
    </div>

    <div class="row" *ngFor="let inputSet of inputSets; let i = index">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="Name" class="form-label">Name</label>
          <input type="text" class="form-control" id="Name" placeholder="" [(ngModel)]="inputSet.input1" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="Type" class="form-label">Type
          </label>
          <select class="form-select" [(ngModel)]="inputSet.input2">
            <option value="number">Number</option>
            <option value="text">Text</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group form-check">
          <label for="require" class="form-check-label">Field is Required</label>
          <input type="checkbox" class="form-check-input" id="require" placeholder="" [(ngModel)]="inputSet.fieldRequired" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group gap-3 d-flex align-items-center justify-content-end">
          <button *ngIf="i === inputSets.length - 1" class="btn btn-secondary" type="button" (click)="saveInputSets()">
            Save
          </button>
          <button class="btn btn-primary" type="button" (click)="addInputSet()" *ngIf="i === inputSets.length - 1">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  `
})
export class CustomerUserField implements OnInit {
  public userField = null;
  @Input() customer: any;
  constructor(
    private readonly customerService: CustomerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.getUserField();
  }

  getUserField() {
    this.customerService.getUserField(this.customer).subscribe(res => {
      this.userField = res;
      this.changeDetectorRef.detectChanges();
    }, (err: Error) => console.log(err)
    );
  }

  inputSets = [{ input1: '', input2: '', fieldRequired: false }];

  addInputSet() {
    this.inputSets.push({ input1: '', input2: '', fieldRequired: false });
  }

  saveInputSets() {
    let collectedValues = this.inputSets
      .map(inputSet => {
        return {
          fieldName: inputSet.input1,
          fieldType: inputSet.input2,
          customerId: this.customer,
          fieldRequired: inputSet.fieldRequired
        };
      })
      // Remove blanks
      .filter(item => !!item.fieldName && !!item.fieldType);

    if (!collectedValues.length) {
      return;
    }

    this.customerService.userField(collectedValues).subscribe({
      next: res => {
        this.inputSets = [{ input1: '', input2: '', fieldRequired: false }];
        this.getUserField();
      },
      error: (err: Error) => console.log(err)
    });
  }

  deleteInputSet(id: number) {
    this.customerService.deleteUserField(id).subscribe({
      next: res => {
        this.getUserField();
      },
      error: err => console.log(err)
    });
  }
}
