import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, debounce, filter, interval, switchMap, tap } from 'rxjs';
import { apiCallWrapper, queryToParams } from '../api/api.util';
import { CartApi } from '../api/cart.api';
import { IQueryFilter } from '../model/query.filter.class';
import { CartAttrs, NewCartItem, ServerCartItem } from '../model/cart.model';
import { HasId } from '../model/generics';
import { UnleashedCustomerExtended } from '../model/unleashed.model';
import { SecurityService } from './security.service';
import { Cart } from '../model/cart.model';
import * as exactMath from "exact-math";
import { SessionApi } from '../api/session.api';
import { IOrderCardPaymentPrepared } from '../model/order.model';
import { CollectionService } from './collection.service';
import { LoadingService } from './core/loading.service';

@Injectable()
export class CartService {
  public items: ServerCartItem[] = [];
  public cart: Cart = new Cart();
  private ccUpdateRequest$ = new BehaviorSubject<null>(null);
  public cardPaymentDetails$ = new BehaviorSubject<IOrderCardPaymentPrepared | null>(null);

  constructor(
    private notifications: NotificationsService,
    private cartApi: CartApi,
    private securityService: SecurityService,
    private session: SessionApi,
    private collectionService: CollectionService,
    private loaderService: LoadingService
  ) {
    this.handleCCUpdateRequests();
  }

  public getOrderAttrsList(query: IQueryFilter): any {
    let params = queryToParams(query);

    return this.loaderService.blockWithLoadingOverlayRx(this.cartApi.getOrderAttrsList(params));
  }

  public get(id: number): Observable<CartAttrs & HasId> {
    return apiCallWrapper(
      this.cartApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Specific Order"
      }
    )
  }

  public sendOrderEmail(id: number) {
    return apiCallWrapper(
      this.loaderService.blockWithLoadingOverlayRx(this.cartApi.sendOrderEmail(id)),
      {
        notificationsService: this.notifications,
        action: "Re-Sending Order Confirmation Email"
      }
    )
  }

  public sendOrderApprovalEmail(id: number) {
    return apiCallWrapper(
      this.loaderService.blockWithLoadingOverlayRx(this.cartApi.sendOrderApprovalEmail(id)),
      {
        notificationsService: this.notifications,
        action: "Re-Sending Order Approval Email"
      }
    )
  }

  public approveCart(id: number, secret?: string) {
    return apiCallWrapper(
      this.loaderService.blockWithLoadingOverlayRx(this.cartApi.approveCart(id, secret)),
      {
        notificationsService: this.notifications,
        action: "Your order has been sent for Approval"
      }
    )
  }

  public cancel(type: string, cartId: number, secret?: string, cancelReason?: string) {
    return apiCallWrapper(
      this.loaderService.blockWithLoadingOverlayRx(this.cartApi.cancel(type, cartId, secret, cancelReason)),
      {
        notificationsService: this.notifications,
        action: `Order ${type == 'reject' ? 'Rejected' : 'Deleted'}`
      }
    )
  }

  public getCartItems(customerId: number) {
    return this.cartApi.getCurrentCartItems(customerId)
      .pipe(
        tap(items => this.items = items)
      );
  }

  /**
 * @description Gets the current cart within the given customer for the current user.
 * Protected against multiple calls with the same arguments
 *
 * @returns {Promise<Cart>}
 */
  public getUserCart(customer?: UnleashedCustomerExtended, dieSilently: boolean = false): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      const hasAccess = this.securityService.hasCartAccessRightNow();

      if (!hasAccess) {
        resolve();
        return;
      }

      const customer = this.session.$customerData.getValue();

      if (!customer) {
        if (!dieSilently) {
          const msg = "Expected to find customer for current user in getUserCart";
          return reject(msg);
        }
      }

      if (customer) {
        this.cartApi.getCurrentCartForUser(customer.id)
          .subscribe(cart => {
            this.cart = cart;

            resolve();
          });
      }
    });
  }

  /**
 * @description Appends a cartItem to the current user cart
 *
 * @param {NewCartItem} cartItem
 */
  public addItemToCart(cartItem: NewCartItem) {
    const customer = this.session.$customerData.getValue();
    if (!customer) {
      return;
    }

    return this.cartApi.addItemToCurrentCart(customer.id, cartItem)
      .pipe(result => {
        this.cart.itemCount++;
        return result;
      });
  };

  /**
 * @description Updates a server-side cart item
 *
 * @param {NewCartItem} cartItem
 */
  public updateCartItem(customerId: number, cartItem: NewCartItem) {
    return this.cartApi.updateCartItem(customerId, cartItem);
  }

  /**
 * @description removes a cart item from the users current cart
 *
 * @param cartItemId
 */
  public deleteCartItem(customerId: number, cartItemId: number) {
    return this.loaderService.blockWithLoadingOverlayRx(this.cartApi.deleteCartItem(customerId, cartItemId))
      .pipe(
        tap(() => this.removeCartItemById(cartItemId))
      );
  }

  /**
 * @description Deletes the passed item from the list of cart items
 *
 * @param itemId
 */
  public removeCartItemById = (itemId: number) => {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id === itemId) {
        this.items.splice(i, 1);
        this.cart.itemCount--;
        this.collectionService.loadCollections();
        break;
      }
    }
  };

  /**
 * @description Instantly calculates the total for the cart for display instead of waiting on serverside
 *
 * @returns {number}
 */
  public getSubtotal = (ignoreAllocation: boolean = false) => this.items.reduce((accumulator, val) =>
    exactMath.add(accumulator, val.asNewCartItem().getTotalPrice(ignoreAllocation)), 0);

  /**
   * @description Instantly calculates the total for the user for display instead of waiting on serverside
   *
   * @returns {number}
   */
  public getUserSubtotal = () => this.items.reduce((accumulator, val) =>
    exactMath.add(accumulator, val.asNewCartItem().getUserPrice()), 0);

  /**
   * @description Instantly calculates the total for the user for display instead of waiting on serverside
   *
   * @returns {number}
   */
  public getAccountSubtotal = () => this.items.reduce((accumulator, val) =>
    exactMath.add(accumulator, val.asNewCartItem().getAccountPrice()), 0);

  /**
   * @description provides a consistent calculation of the  Small Order Fee on the current cart
   * @returns {number}
   */
  public getHandlingFee = () => {
    if (this.cart.attrs.shippingDetails && this.cart.attrs.shippingDetails.isFreeHandling) {
      return 0;
    }

    const customerData = this.session.$customerData.getValue();

    // Customer is not known
    if (!customerData) {
      return 20;
    }

    // Customer has  Small Order Fee disabled
    if (typeof customerData.handlingFee === 'boolean' && !customerData.handlingFee) {
      return 0;
    }

    // Handle custom  Small Order Fee
    const handlingFeeAmount = customerData.handlingFeeAmount || 20;

    // Do not compute further if there is no fee to calculate
    if (handlingFeeAmount === 0) {
      return handlingFeeAmount;
    }

    // Handle custom  Small Order Fee threshold
    const handlingFeeThreshold = (!customerData.handlingFeeThreshold || (customerData.handlingFeeThreshold <= 0)) ? 250 : customerData.handlingFeeThreshold;

    // Handle carts higher than the threshold
    if (this.getSubtotal(true) > handlingFeeThreshold) {
      return 0;
    }

    // Handle CoPay by the customer
    const handlingFeeCoPay = (!customerData.handlingFeeCoPay || (customerData.handlingFeeCoPay <= 0)) ? 0 : customerData.handlingFeeCoPay;

    if (handlingFeeCoPay > handlingFeeAmount) {
      return 0;
    }

    // Handle Every item having an Allocation
    if (this.isFullyAllocated()) {
      return 0;
    }

    return exactMath.sub(handlingFeeAmount, handlingFeeCoPay);
  };

  public getTax = () => exactMath.mul(exactMath.add(this.getSubtotal(), this.getHandlingFee()), 0.1);

  public getGrandTotal = () => exactMath.mul(exactMath.add(this.getSubtotal(), this.getHandlingFee()), 1.1);
  public getUserTotal = () => exactMath.mul(exactMath.add(this.getUserSubtotal(), this.getHandlingFee()), 1.1);
  public getAccountTotal = () => exactMath.mul(this.getAccountSubtotal(), 1.1);
  public isFullyAllocated = () => !this.items.find(item => !item.asNewCartItem().isFullyAllocated());

  /**
   * @description allows for the partial save of cart attributes
   *
   * @param props
   */
  public saveCartAttrs(customerId: number, props?: (keyof CartAttrs)[]) {
    let update: Partial<CartAttrs>;

    if (!props)
      update = this.cart.attrs;
    else {
      update = props.reduce((accumulator, value) => {
        accumulator[value] = this.cart.attrs[value];

        return accumulator;
      }, {});
    }

    return this.cartApi.saveCartAttrs(customerId, update);
  }

  public requestCCUpdate = () => {
    this.ccUpdateRequest$.next(null);
  }

  public handleCCUpdateRequests = () => {
    // Every time a ccUpdateRequest is recieved
    this.ccUpdateRequest$.pipe(
      // Prevent duplicate calls for 500ms
      debounce(() => interval(500)),
      // Do nothing if there is no cartId
      filter(() => !!this.cart.id),
      // Prepare the transaction
      switchMap(() => this.cartApi.prepareCCTransaction(this.cart.id)),
    ).subscribe(data => {
      // Notify downstream handlers of the updated payment details
      this.cardPaymentDetails$.next(data);
    });
  }

  /**
 * @description Orders the current cart
 */
  public placeOrder = (notifyRequestUser: boolean, customerId: number) => {
    return this.cartApi.placeOrder(notifyRequestUser, customerId);
  };

  /**
* @description Reset the cart and cart items stored in the service
*
* @return void
*/
  public clearCart() {
    this.cart = new Cart();
    this.items = [];
  }

  public completeCCTransaction(transactionResponse: { [key: string]: any; }): Observable<CartAttrs & HasId> {
    return apiCallWrapper(
      this.cartApi.completeCCTransaction(transactionResponse),
      {
        notificationsService: this.notifications,
        action: "Complete CC TRANSACTION"
      }
    )
  }

  public fetchChartData(query: IQueryFilter) {
    let params = queryToParams(query);
    return this.cartApi.fetchChartData(params);
  }

  public getLastAddress() {
    return this.cartApi.getLastAddress()
  }

  public updateOrderShippingDetails(shipmentID: string | number, trackingDetail: string | null, trackingLink: string | null) {
    return this.cartApi.updateOrderShippingDetails(shipmentID, trackingDetail, trackingLink);
  }


  public getCart(id: number): Observable<Cart | undefined> {
    return apiCallWrapper(
      this.cartApi.getCart(id),
      {
        notificationsService: this.notifications,
        action: "Loading Order from DB"
      }
    )
  }

  public reOrder(id: number): Observable<Cart | undefined> {
    return apiCallWrapper(
      this.loaderService.blockWithLoadingOverlayRx(this.cartApi.reOrder(id)),
      {
        notificationsService: this.notifications,
        action: "Processing Order"
      }
    )
  }

  public postOrderFieldValues(model: any, id: number | string) {
    return this.cartApi.postOrderFieldValues(model, id);
  }

  public getFields(customerId: number | string) {
    return this.cartApi.getFields(customerId);
  }
}
