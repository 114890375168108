import { Component, ViewChild } from '@angular/core';
import { CustomerUserService } from '../services/customerUser.service';
import { UserListComponent } from '../users/customer-users/user-list/user-list.component';
import { SessionApi } from '../api/session.api';

@Component({
  selector: 'app-manage-employes',
  templateUrl: './manage-employes.component.html',
  styleUrls: []
})
export class ManageEmployesComponent {
  @ViewChild(UserListComponent, { static: false })
  private userListComponent!: UserListComponent;

  public customerId: number;
  constructor(
    private customerUserService: CustomerUserService,
    private sesssion: SessionApi
  ) {
    if (this.sesssion.$customerData.value?.id) {
      this.customerId = this.sesssion.$customerData.value?.id;
    }
  }

  downloadImportTemplate() {
    const csvColumns = [
      [
        'First Name',
        'Last Name',
        'Email',
        'Employee Number',
        'Cost Centre',
        'Business Unit',
        'Role (Purchasing Manager/Purchaser/Employee/View Only)',
        'Purchasing Approval Manager Email (If Applicable)'
      ]
    ];
    const csvString = csvColumns.map(row => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "import_template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  async fileUploadChange(evt: Event) {
    const element = evt.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (!fileList) {
      return;
    }

    const file = fileList[0];

    if (file.type !== "text/csv") {
      alert("Please select a user Import CSV")
      return;
    }

    // @ts-ignore
    const fileText = await file.text();

    if (fileText.length === 0) {
      alert("Please select a Valid user Import CSV")
      return;
    }

    this.customerUserService.importUser({ customerId: this.customerId, fileContent: fileText }).subscribe({
      next: resp => {
        const messages: string[] = [];
        if (resp.results && resp.results.length) {
          messages.push(`Created ${resp.results.length} users`);
        } else {
          messages.push(`Created 0 users`);
        }
        if (resp.messages && resp.messages.length > 0) {
          messages.push('Warning: Not all users could be imported');
          messages.push(...resp.messages);
        }
        alert(messages.join("\n"));
        this.userListComponent.loadCustomerUsers();
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
        element.value = '';
      }
    });
  }
}
