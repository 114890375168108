import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { AdminRoleApi } from '../api/adminRole.api';
import { IRole } from '../model/role.model';
import { LoadingService } from './core/loading.service';

@Injectable()
export class AdminRoleService {
  constructor(
    private notifications: NotificationsService,
    private adminRoleApi: AdminRoleApi,
    private loadingService: LoadingService
  ) {
  }

  public list(query: IQueryFilter): Observable<QueryResult<IRole & HasId<string>>> {
    return apiCallWrapper(
      this.adminRoleApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Admin Roles"
      }
    )
  }

  public get(id: number): Observable<IRole & HasId> {
    return apiCallWrapper(
      this.adminRoleApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Specific Admin Role"
      }
    )
  }

  public create(data: IRole): Observable<IRole & HasId> {
    return apiCallWrapper(
      this.adminRoleApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Creating Admin Role"
      }
    )
  }

  public update(id: number, data: IRole): Observable<IRole & HasId> {
    return apiCallWrapper(
      this.adminRoleApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Updating Admin Role"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.loadingService.blockWithLoadingOverlayRx(this.adminRoleApi.delete(id)),
      {
        notificationsService: this.notifications,
        action: "Delete Admin Role"
      }
    )
  }
}
