import { Component, Input } from '@angular/core';
import { NewCartItem, ServerCartItem } from '../../model/cart.model';
import { Utils } from '../../util/utils';

@Component({
  selector: 'app-cart-checkout-items',
  templateUrl: './cart-checkout-items.component.html',
  styleUrls: []
})
export class CartCheckoutItemsComponent {
  cartItem: NewCartItem = new NewCartItem();
  private _item: ServerCartItem;

  @Input()
  showPrice: ServerCartItem | undefined;

  @Input()
  set item(item: ServerCartItem) {
    let productImage = item.product ? item.product.images.find(image => image.name === item.variation.colour) : undefined;
    item.product.imageUrl = productImage ? productImage.url : item.product ? item.product.imageUrl : '';
    this._item = item;

    this.cartItem = item.asNewCartItem();
  }
  get item() {
    return this._item;
  }

  constructor(
    public utils: Utils
  ) { }

}
