import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { DecorationCustomer } from '../model/decoration.model';
import { CustomerDecorationApi } from '../api/customerDecoration.api';

@Injectable()
export class CustomerDecorationService {
  constructor(
    private notifications: NotificationsService,
    private customerDecorationApi: CustomerDecorationApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<DecorationCustomer & HasId<string>>> {
    return apiCallWrapper(
      this.customerDecorationApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching customer decoration"
      }
    )
  }

  public update(id: string, data: any): Observable<any> {
    return apiCallWrapper(
      this.customerDecorationApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Updating Customer Decoration"
      }
    )
  }

  public create(data: DecorationCustomer) {
    return apiCallWrapper(
      this.customerDecorationApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create customer decoration"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.customerDecorationApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Customer Decoration"
      }
    )
  }

  public getAllDecorationCustomersByDecorationId(id: number | string): Observable<DecorationCustomer[]> {
    return apiCallWrapper(
      this.customerDecorationApi.getAllDecorationCustomersByDecorationId(id),
      {
        notificationsService: this.notifications,
        action: "Fetching customer decoration"
      }
    )
  }
}
