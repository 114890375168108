<div class="overview-list">
  <div class="overview-content">
    <!-- first ul start here... -->
    <ul>
      <li>
        <p>
          <span>Group name</span>
          {{group.name}}
        </p>
      </li>
      <li>
        <p>
          <span>Customers</span>
        </p>
        <p *ngFor="let data of group.customers">
          {{data.customer?.name}}
        </p>
      </li>
    </ul>
    <!-- first ul end here... -->

    <!-- Second ul start here... -->
    <ul>
      <li>
        <p>
          <span>Allocations</span>
          {{group.allocations.length}}
        </p>
      </li>
    </ul>
    <!-- Second ul end here... -->
    <div class="customer-overview">
      <div>
        <span class="badge" [class.bg-green-25]="group.accessMode === 1" [class.bg-red-25]="group.accessMode === 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0003 15.8332C13.222 15.8332 15.8337 13.2215 15.8337 9.99984C15.8337 6.77818 13.222 4.1665 10.0003 4.1665C6.77866 4.1665 4.16699 6.77818 4.16699 9.99984C4.16699 13.2215 6.77866 15.8332 10.0003 15.8332ZM11.0418 12.9945L11.1349 12.6137C11.0867 12.6364 11.009 12.6622 10.9024 12.6917C10.7955 12.7212 10.6993 12.7362 10.6148 12.7362C10.4347 12.7362 10.3079 12.7066 10.2343 12.6473C10.1612 12.588 10.1247 12.4764 10.1247 12.3129C10.1247 12.2481 10.1357 12.1516 10.1587 12.0252C10.181 11.898 10.2067 11.7849 10.2352 11.6861L10.5829 10.4549C10.617 10.3419 10.6403 10.2177 10.6529 10.0821C10.6657 9.94687 10.6717 9.85223 10.6717 9.79851C10.6717 9.5389 10.5807 9.32822 10.3986 9.1657C10.2166 9.00331 9.95733 8.92211 9.62136 8.92211C9.4344 8.92211 9.23675 8.95533 9.02757 9.02171C8.81838 9.08791 8.5997 9.16768 8.37092 9.26083L8.27753 9.64191C8.34571 9.61669 8.42691 9.58957 8.52191 9.56149C8.61649 9.53352 8.70928 9.51906 8.79956 9.51906C8.98389 9.51906 9.10805 9.55049 9.17312 9.61239C9.23818 9.67447 9.27087 9.78489 9.27087 9.94275C9.27087 10.03 9.26053 10.127 9.23908 10.2324C9.21793 10.3385 9.19152 10.4506 9.16045 10.569L8.81121 11.8051C8.78014 11.935 8.75744 12.0512 8.74316 12.1544C8.729 12.2574 8.72219 12.3586 8.72219 12.4569C8.72219 12.711 8.81605 12.9204 9.00373 13.0857C9.1914 13.2503 9.45454 13.3332 9.79284 13.3332C10.0131 13.3332 10.2065 13.3044 10.3729 13.2465C10.5391 13.1888 10.7623 13.1048 11.0418 12.9945ZM10.9798 7.99336C11.1423 7.84273 11.2231 7.65954 11.2231 7.44504C11.2231 7.23102 11.1424 7.04747 10.9798 6.89493C10.8178 6.7428 10.6225 6.6665 10.3941 6.6665C10.165 6.6665 9.96892 6.74262 9.80533 6.89493C9.64173 7.04747 9.55975 7.23096 9.55975 7.44504C9.55975 7.65954 9.64173 7.84267 9.80533 7.99336C9.96922 8.14453 10.165 8.22017 10.3941 8.22017C10.6225 8.22017 10.8178 8.14453 10.9798 7.99336Z"
              fill="transparent" />
          </svg>
          {{group.accessMode? 'Enabled' : 'Disabled'}}</span>
        <p>This group is available to all users</p>
      </div>
      <div>
        <span class="badge" [class]="{'bg-green-25': !group.accessMode, 'bg-red-25': group.accessMode}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0003 15.8332C13.222 15.8332 15.8337 13.2215 15.8337 9.99984C15.8337 6.77818 13.222 4.1665 10.0003 4.1665C6.77866 4.1665 4.16699 6.77818 4.16699 9.99984C4.16699 13.2215 6.77866 15.8332 10.0003 15.8332ZM11.0418 12.9945L11.1349 12.6137C11.0867 12.6364 11.009 12.6622 10.9024 12.6917C10.7955 12.7212 10.6993 12.7362 10.6148 12.7362C10.4347 12.7362 10.3079 12.7066 10.2343 12.6473C10.1612 12.588 10.1247 12.4764 10.1247 12.3129C10.1247 12.2481 10.1357 12.1516 10.1587 12.0252C10.181 11.898 10.2067 11.7849 10.2352 11.6861L10.5829 10.4549C10.617 10.3419 10.6403 10.2177 10.6529 10.0821C10.6657 9.94687 10.6717 9.85223 10.6717 9.79851C10.6717 9.5389 10.5807 9.32822 10.3986 9.1657C10.2166 9.00331 9.95733 8.92211 9.62136 8.92211C9.4344 8.92211 9.23675 8.95533 9.02757 9.02171C8.81838 9.08791 8.5997 9.16768 8.37092 9.26083L8.27753 9.64191C8.34571 9.61669 8.42691 9.58957 8.52191 9.56149C8.61649 9.53352 8.70928 9.51906 8.79956 9.51906C8.98389 9.51906 9.10805 9.55049 9.17312 9.61239C9.23818 9.67447 9.27087 9.78489 9.27087 9.94275C9.27087 10.03 9.26053 10.127 9.23908 10.2324C9.21793 10.3385 9.19152 10.4506 9.16045 10.569L8.81121 11.8051C8.78014 11.935 8.75744 12.0512 8.74316 12.1544C8.729 12.2574 8.72219 12.3586 8.72219 12.4569C8.72219 12.711 8.81605 12.9204 9.00373 13.0857C9.1914 13.2503 9.45454 13.3332 9.79284 13.3332C10.0131 13.3332 10.2065 13.3044 10.3729 13.2465C10.5391 13.1888 10.7623 13.1048 11.0418 12.9945ZM10.9798 7.99336C11.1423 7.84273 11.2231 7.65954 11.2231 7.44504C11.2231 7.23102 11.1424 7.04747 10.9798 6.89493C10.8178 6.7428 10.6225 6.6665 10.3941 6.6665C10.165 6.6665 9.96892 6.74262 9.80533 6.89493C9.64173 7.04747 9.55975 7.23096 9.55975 7.44504C9.55975 7.65954 9.64173 7.84267 9.80533 7.99336C9.96922 8.14453 10.165 8.22017 10.3941 8.22017C10.6225 8.22017 10.8178 8.14453 10.9798 7.99336Z"
              fill="transparent" />
          </svg>
          {{!group.accessMode? 'Enabled' : 'Disabled'}}</span>
        <p>
          This group is only available to selected customers
        </p>
      </div>
    </div>

    <div class="customer-overview">
      <div>
        <span class="badge" [class]="{'bg-green-25': group.isRestrictedGroup, 'bg-red-25': !group.isRestrictedGroup}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0003 15.8332C13.222 15.8332 15.8337 13.2215 15.8337 9.99984C15.8337 6.77818 13.222 4.1665 10.0003 4.1665C6.77866 4.1665 4.16699 6.77818 4.16699 9.99984C4.16699 13.2215 6.77866 15.8332 10.0003 15.8332ZM11.0418 12.9945L11.1349 12.6137C11.0867 12.6364 11.009 12.6622 10.9024 12.6917C10.7955 12.7212 10.6993 12.7362 10.6148 12.7362C10.4347 12.7362 10.3079 12.7066 10.2343 12.6473C10.1612 12.588 10.1247 12.4764 10.1247 12.3129C10.1247 12.2481 10.1357 12.1516 10.1587 12.0252C10.181 11.898 10.2067 11.7849 10.2352 11.6861L10.5829 10.4549C10.617 10.3419 10.6403 10.2177 10.6529 10.0821C10.6657 9.94687 10.6717 9.85223 10.6717 9.79851C10.6717 9.5389 10.5807 9.32822 10.3986 9.1657C10.2166 9.00331 9.95733 8.92211 9.62136 8.92211C9.4344 8.92211 9.23675 8.95533 9.02757 9.02171C8.81838 9.08791 8.5997 9.16768 8.37092 9.26083L8.27753 9.64191C8.34571 9.61669 8.42691 9.58957 8.52191 9.56149C8.61649 9.53352 8.70928 9.51906 8.79956 9.51906C8.98389 9.51906 9.10805 9.55049 9.17312 9.61239C9.23818 9.67447 9.27087 9.78489 9.27087 9.94275C9.27087 10.03 9.26053 10.127 9.23908 10.2324C9.21793 10.3385 9.19152 10.4506 9.16045 10.569L8.81121 11.8051C8.78014 11.935 8.75744 12.0512 8.74316 12.1544C8.729 12.2574 8.72219 12.3586 8.72219 12.4569C8.72219 12.711 8.81605 12.9204 9.00373 13.0857C9.1914 13.2503 9.45454 13.3332 9.79284 13.3332C10.0131 13.3332 10.2065 13.3044 10.3729 13.2465C10.5391 13.1888 10.7623 13.1048 11.0418 12.9945ZM10.9798 7.99336C11.1423 7.84273 11.2231 7.65954 11.2231 7.44504C11.2231 7.23102 11.1424 7.04747 10.9798 6.89493C10.8178 6.7428 10.6225 6.6665 10.3941 6.6665C10.165 6.6665 9.96892 6.74262 9.80533 6.89493C9.64173 7.04747 9.55975 7.23096 9.55975 7.44504C9.55975 7.65954 9.64173 7.84267 9.80533 7.99336C9.96922 8.14453 10.165 8.22017 10.3941 8.22017C10.6225 8.22017 10.8178 8.14453 10.9798 7.99336Z"
              fill="transparent" />
          </svg>
          {{group.isRestrictedGroup? 'Enabled' : 'Disabled'}}</span>
        <p>Restrict Product Access to Relevant* Collections*</p>
        <span class="small-content">Each collection has its own security which must also
          be considered</span>
      </div>
      <div>
        <span class="badge"
          [class]="{'bg-green-25': group.isRestrictedPricing, 'bg-red-25': !group.isRestrictedPricing}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0003 15.8332C13.222 15.8332 15.8337 13.2215 15.8337 9.99984C15.8337 6.77818 13.222 4.1665 10.0003 4.1665C6.77866 4.1665 4.16699 6.77818 4.16699 9.99984C4.16699 13.2215 6.77866 15.8332 10.0003 15.8332ZM11.0418 12.9945L11.1349 12.6137C11.0867 12.6364 11.009 12.6622 10.9024 12.6917C10.7955 12.7212 10.6993 12.7362 10.6148 12.7362C10.4347 12.7362 10.3079 12.7066 10.2343 12.6473C10.1612 12.588 10.1247 12.4764 10.1247 12.3129C10.1247 12.2481 10.1357 12.1516 10.1587 12.0252C10.181 11.898 10.2067 11.7849 10.2352 11.6861L10.5829 10.4549C10.617 10.3419 10.6403 10.2177 10.6529 10.0821C10.6657 9.94687 10.6717 9.85223 10.6717 9.79851C10.6717 9.5389 10.5807 9.32822 10.3986 9.1657C10.2166 9.00331 9.95733 8.92211 9.62136 8.92211C9.4344 8.92211 9.23675 8.95533 9.02757 9.02171C8.81838 9.08791 8.5997 9.16768 8.37092 9.26083L8.27753 9.64191C8.34571 9.61669 8.42691 9.58957 8.52191 9.56149C8.61649 9.53352 8.70928 9.51906 8.79956 9.51906C8.98389 9.51906 9.10805 9.55049 9.17312 9.61239C9.23818 9.67447 9.27087 9.78489 9.27087 9.94275C9.27087 10.03 9.26053 10.127 9.23908 10.2324C9.21793 10.3385 9.19152 10.4506 9.16045 10.569L8.81121 11.8051C8.78014 11.935 8.75744 12.0512 8.74316 12.1544C8.729 12.2574 8.72219 12.3586 8.72219 12.4569C8.72219 12.711 8.81605 12.9204 9.00373 13.0857C9.1914 13.2503 9.45454 13.3332 9.79284 13.3332C10.0131 13.3332 10.2065 13.3044 10.3729 13.2465C10.5391 13.1888 10.7623 13.1048 11.0418 12.9945ZM10.9798 7.99336C11.1423 7.84273 11.2231 7.65954 11.2231 7.44504C11.2231 7.23102 11.1424 7.04747 10.9798 6.89493C10.8178 6.7428 10.6225 6.6665 10.3941 6.6665C10.165 6.6665 9.96892 6.74262 9.80533 6.89493C9.64173 7.04747 9.55975 7.23096 9.55975 7.44504C9.55975 7.65954 9.64173 7.84267 9.80533 7.99336C9.96922 8.14453 10.165 8.22017 10.3941 8.22017C10.6225 8.22017 10.8178 8.14453 10.9798 7.99336Z"
              fill="transparent" />
          </svg>
          {{group.isRestrictedPricing ? 'Enabled' : 'Disabled'}}</span>
        <p>Remove Prices (Requires Collection Restrictions)</p>
        <span class="small-content">Prevents Payment Gateway Access</span>
      </div>
    </div>
  </div>
</div>