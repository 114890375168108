<div [class.main-right-content]="!isEmploye">
	<!-- manage-user main start here... -->
	<div [class.manageUser]="!isEmploye" [class.employee-tab]="isEmploye">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between">
			<h1 class="title">Orders</h1>
		</div>

		<app-tab-set #tabSet [activeTabIdx]="activeTabIdx" [tabs]="getTabs()"
			(click)="onTabChange(tabSet.activeTabIdx)"></app-tab-set>
		<div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
				<app-order-list *ngIf="activeTabIdx == 0"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
				<app-order-list *ngIf="activeTabIdx == 1" [orderStatus]="'Processing'"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }">
				<app-order-list *ngIf="activeTabIdx == 2" [orderStatus]="'COMPLETED'"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 3 }">
				<app-order-list *ngIf="activeTabIdx == 3" [orderStatus]="'BACKORDERED'"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 4 }">
				<app-order-list *ngIf="activeTabIdx == 4" [orderStatus]="'APPROVAL'"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 5 }">
				<app-order-list *ngIf="activeTabIdx == 5" [orderStatus]="'DELETED'"></app-order-list>
			</div>
			<div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 6 }">
				<app-order-list *ngIf="activeTabIdx == 6" [orderStatus]="'REJECTED'"></app-order-list>
			</div>
		</div>
	</div>
</div>