<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/sizeChart']">Size charts</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ sizeChartId ? "Edit" : "Add" }} size chart
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{ sizeChartId ? "Edit" : "Add" }} size chart</h1>
		</div>
		<div class="manage-user-list">
			<div class="edit-users">
				<div *ngIf="!isLoading">
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M16.75 12.9999V6.99993C16.7497 6.73688 16.6803 6.47853 16.5487 6.2508C16.417 6.02306 16.2278 5.83395 16 5.70243L10.75 2.70243C10.522 2.57077 10.2633 2.50146 10 2.50146C9.7367 2.50146 9.47803 2.57077 9.25 2.70243L4 5.70243C3.7722 5.83395 3.58299 6.02306 3.45135 6.2508C3.31971 6.47853 3.25027 6.73688 3.25 6.99993V12.9999C3.25027 13.263 3.31971 13.5213 3.45135 13.7491C3.58299 13.9768 3.7722 14.1659 4 14.2974L9.25 17.2974C9.47803 17.4291 9.7367 17.4984 10 17.4984C10.2633 17.4984 10.522 17.4291 10.75 17.2974L16 14.2974C16.2278 14.1659 16.417 13.9768 16.5487 13.7491C16.6803 13.5213 16.7497 13.263 16.75 12.9999Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M3.45215 6.21997L9.99965 10.0075L16.5471 6.21997" stroke="#666D80" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10 17.56V10" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</span>
								General information
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="chartName" class="form-label">Chart name</label>
										<input type="text" class="form-control" id="chartName" name="sizechart" placeholder="Chart name..."
											[(ngModel)]="sizeChart.name" appInputMaxLength="50"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<app-size-table [sizeChart]="sizeChart"></app-size-table>
					<div class="mt-3">
						<button (click)="saveChart()" class="btn btn-primary w-sm-100" type="button">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>