import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { apiCallWrapper, queryToParams } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { CollectionApi } from '../api/colllection.api';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ICollection } from '../model/collection.model';
import { AuthService } from './auth.service';

@Injectable()
export class CollectionService {
  private collectionsSubject = new BehaviorSubject<ICollection[]>([]);
  public collections$ = this.collectionsSubject.asObservable();

  constructor(
    private notifications: NotificationsService,
    private collectionApi: CollectionApi,
    private authService: AuthService
  ) {
    if (authService.isAuthenticated()) {
      this.loadCollections();
    }
  }

  public loadCollections() {
    const query: IQueryFilter = new IQueryFilter({ sortBy: 'name' });
    this.collectionApi.list(query).pipe(
      tap(queryResult => {
        this.collectionsSubject.next(queryResult.rows);
      })
    ).subscribe();
  }

  public list(query: IQueryFilter): Observable<QueryResult<ICollection>> {
    return this.collectionApi.list(query);
  }

  public getCollections(): Observable<ICollection[]> {
    return this.collections$;
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.collectionApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Collection"
      }
    )
  }

  public get(id: number): Observable<Partial<ICollection>> {
    return apiCallWrapper(
      this.collectionApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Collection By Id"
      }
    )
  }

  public update(id: number, data: ICollection) {
    return apiCallWrapper(
      this.collectionApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Update Collection"
      }
    )
  }

  public create(data: ICollection) {
    return apiCallWrapper(
      this.collectionApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create Collection"
      }
    )
  }

  public getCollectionAllocationsForProduct(productId: number, customerId?: number): Observable<QueryResult<ICollection>> {
    let query: IQueryFilter = new IQueryFilter({
      sortBy: 'name',
      skip: 0,
      limit: 100
    });

    const params = {
      availableAllocations: true,
      productId
    };

    if (customerId) {
      params['customerId'] = customerId;
    }
    let paramsNew = queryToParams(query);

    if (params) {
      for (let prop in params) {
        paramsNew = paramsNew.set(prop, params[prop]);
      }
    }
    return this.collectionApi.collectionList(paramsNew);
  };

}
