import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, map, of, pipe } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { SizeChartApi } from '../api/sizeChart.api';
import { omit, pick, result } from 'lodash';
import { SizeChartCell, SizeChartColumn, SizeChartEntry, SizeChartRow } from '../model/sizeChart.model';
import * as R from 'ramda';

type T = SizeChartEntry;

@Injectable()
export class SizeChartService {
  protected readonly _items = new BehaviorSubject<T[]>([]);

  constructor(
    private notifications: NotificationsService,
    private sizeChartApi: SizeChartApi
  ) {
  }

  protected readonly itemComparisonKeySelector = (item: T) => {
    return result<number | string>(item, 'id');
  };

  public list(query: IQueryFilter) {
    return apiCallWrapper(
      this.sizeChartApi.list(query)
        .pipe(
          map(res => {
            res.rows = res.rows.map(chartData => this.convertToSizeChart(chartData)) as typeof res.rows
            return res;
          })
        ),
      {
        notificationsService: this.notifications,
        action: "Fetching Size Chart"
      }
    )
  }

  public readonly getById = (id: number | string): Observable<SizeChartEntry> => {
    /**
     * Check whether it exists
     */
    const item = this.getItemById(id);

    if (item) {
      return of(item);
    }
    return this.sizeChartApi.get(id)
      .pipe(map(this.convertToSizeChart));
  };

  protected readonly getItemById = (id: number | string): T | null => {
    const findedItem = this._items.getValue()
      .find((item) => {
        return '' + this.itemComparisonKeySelector(item) === '' + id;
      });

    if (!findedItem) {
      return null;
    }

    return findedItem;
  };

  convertFromSizeChart(model: SizeChartEntry): any {
    const modelProcess = omit(model, 'id', 'columnsOrdered', 'rowsOrdered');
    modelProcess.cells = modelProcess.cells.map(R.curryN(2, pick)(R.__, ['isNew', 'value', 'isUpdated', 'columnId', 'rowId', 'id']));
    modelProcess.rows = modelProcess.rows.map(R.curryN(2, pick)(R.__, ['isNew', 'name', 'isUpdated', 'isNew', 'orderNumber', 'id']));
    modelProcess.columns = modelProcess.columns.map(R.curryN(2, pick)(R.__, ['isNew', 'isUpdated', 'subtitle', 'title', 'isNew', 'id']));

    return modelProcess;
  }

  convertToSizeChart(model: any): SizeChartEntry {
    const obj = new SizeChartEntry();
    obj.name = result(model, 'name', '');
    const rows = result(model, 'rows', [{
      name: '',
      orderNumber: 1,
      id: undefined
    }]);
    const cells = result(model, 'cells', []);
    const columns = result(model, 'columns', [{
      title: '',
      subtitle: '',
      id: undefined
    }]);
    const cellsGenerated = cells.map((c: any) => new SizeChartCell(
      c.columnId,
      c.rowId,
      c.value,
      c.id
    ));
    const rowsGenerated = rows.map(r => new SizeChartRow(
      r.name,
      r.orderNumber,
      r.id
    ));
    const columnsGenerated = columns.map(c => new SizeChartColumn(
      c.title,
      c.subtitle,
      c.id,
    ));

    obj.setData(columnsGenerated, rowsGenerated, cellsGenerated);

    obj.id = model.id;

    return obj;
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.sizeChartApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Size Chart"
      }
    )
  }

  public readonly create = (model: SizeChartEntry) => {
    return apiCallWrapper(this.sizeChartApi.create(
      this.convertFromSizeChart(model)
    )
      .pipe(this.processReplaceOrAdd()),
      {
        notificationsService: this.notifications,
        action: "Create Size Chart"
      }
    );
  };

  private readonly processReplaceOrAdd = () => pipe(
    map(this.convertToSizeChart)
  );

  public readonly update = (model: SizeChartEntry) => {
    model.id = model.id || 0
    return apiCallWrapper(this.sizeChartApi.update(
      model.id.toString(),
      this.convertFromSizeChart(model)
    )
      .pipe(this.processReplaceOrAdd()),
      {
        notificationsService: this.notifications,
        action: "Update Size Chart"
      }
    );
  };
}
