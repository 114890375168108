import { Component, Input } from "@angular/core";
import { isNullOrUndefined } from "util";
import * as exactMath from "exact-math";
import { UnleashedCustomer } from "src/app/main/model/unleashed.model";
import { UnleashedService } from "src/app/main/services/unleashed.service";

@Component({
  selector: 'price-discount-edit',
  styles: [],
  template: `
  <div class="edit-card card">
  <div class="card-head">
    <h4 class="head-title">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.25 4.75L4.75 15.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M14.125 16C15.1605 16 16 15.1605 16 14.125C16 13.0895 15.1605 12.25 14.125 12.25C13.0895 12.25 12.25 13.0895 12.25 14.125C12.25 15.1605 13.0895 16 14.125 16Z"
            stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M5.875 7.75C6.91053 7.75 7.75 6.91053 7.75 5.875C7.75 4.83947 6.91053 4 5.875 4C4.83947 4 4 4.83947 4 5.875C4 6.91053 4.83947 7.75 5.875 7.75Z"
            stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      Discount Rate
    </h4>
  </div>
  <div class="card-body">
    <form action="">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <div class="form-group">
            <label for="Discount" class="form-label">Discount Amount (%)</label>
            <input type="email" class="form-control" id="Discount" placeholder="0" name="email" [(ngModel)]="customer.discountAmount" />
          </div>
        </div>
        <div class="col-12 col-xxl-4 align-self-end">
          <div class="form-group">
            <button class="btn btn-primary w-100" type="button" (click)="loadFromUnleashed()" [disabled]="!customer.Guid">
              Reload from Unleashed
            </button>
          </div>
        </div>
        <div class="col-12">
          <ul class="list-view">
            <li>
              <p class="form-text">
                Any value above 0 will reduce the
                purchase cost of all products by this
                %.
              </p>
            </li>
            <li>
              <p class="form-text">
                A Blank value or a value below 0 will
                be ignored and the base price will be
                used.
              </p>
            </li>
            <li>
              <p class="form-text">
                EG. A Product with a base cost of $50
                will cost $40 when the discount amount
                is 20%.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>
	`
})
export class PriceDiscountEditComponent {
  @Input()
  customer: UnleashedCustomer;

  constructor(
    private readonly unleashedService: UnleashedService,
  ) { }

  /**
   * Loads the unleashed customer from the unleashed API, then applies the discountRate value to the model (and field)
   */
  loadFromUnleashed() {
    if (this.customer.Guid) {
      this.unleashedService.getCustomerById(this.customer.Guid).subscribe(unleashedCustomer => {

        if (unleashedCustomer && !isNullOrUndefined(unleashedCustomer.DiscountRate)) {
          const discountRate = Number(unleashedCustomer.DiscountRate);
          if (!isNaN(discountRate)) {
            const discountRatePct = exactMath.mul(discountRate, 100);
            this.customer.discountAmount = discountRatePct;
          }
        }
      }, () => {
      })
    }
  }
}
