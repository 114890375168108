import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { result } from 'lodash';

export abstract class ItemsGenericService<T> {
  readonly isReady = new BehaviorSubject(false);
  protected readonly _items = new BehaviorSubject<T[]>([]);

  get items(): Observable<T[]> {
    if (!this.isReady.getValue()) {
      this.isReady.next(true);

      return this.fetchData();
    }

    return this._items.asObservable();
  }

  protected abstract dataFetcher(): Observable<T[]>

  private readonly fetchData = () => {
    return this.dataFetcher()
      .pipe(
        tap(data => {
          this._items.next(data);
        }),
        switchMap(() => {
          return this._items.asObservable();
        })
      );
  };
}
